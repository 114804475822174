import {
  faAngleDown,
  faBars,
  faCopy,
  faMapMarker,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import "bootstrap/dist/css/bootstrap.css";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import Image from "react-bootstrap/Image";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import "../assets/css/home.css";
import icon5 from "../assets/images/icon-5.jpg";
import icon6 from "../assets/images/icon-6.jpg";
import icon from "../assets/images/icon.jpg";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import {
  getAllJobs,
  getJobSeeker,
  getJobsByJobId,
  getJobsJobId,
  searchJobs,
} from "../helpers/backendHelper";
import "../styles/Main.css";
import { isObjectEmpty } from "../helpers/utility";
import { BsCaretDownFill } from "react-icons/bs";
import { IoLocationOutline } from "react-icons/io5";
import SearchJobs from "../helpers/JobSeekers/viewJobsHelper";

const ViewJobs = ({ props }) => {
  const { user } = useSelector((state) => ({
    user: state.Login.user,
  }));

  const navigate = useNavigate();
  const params = useParams();

  const jobId = params.jobId;
  const [jobs, setJobs] = useState([]);
  const [isNavigateFromHome, setIsNavigateFromHome] = useState(
    params.isNavigateFromHome
  );
  const [isSearchFromHome, setIsSearchFromHome] = useState(params.isSearchFromHome)
  const [jobTitle, setJobTitle] = useState();
  const [location, setLocation] = useState();
  const [educationLevel, setEducationLevel] = useState("");
  const [jobType, setJobType] = useState("");

  const [newest, setNewest] = useState("");
  const [dateposted, setDateposted] = useState("");
  const [remote, setRemote] = useState("");
  const [within15km, setWithin15km] = useState("");
  const [pay, setPay] = useState("");

  const [showFilters, setShowFilters] = useState(false);
  const [company, setCompanyName] = useState("");

  const isLoggedIn = localStorage.getItem("authUser");
  const isProfileUpdated = localStorage.getItem("isProfileUpdated");
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    employerCity: "",
    employerCountry: "",
    employerArea: "",
    employerPincode: "",
    employerStreet: "",
    isTwoFAEnabled: "",
    isTwoFASetup: "",
    isProfileUpdate: false,
  });

  const [mark, setMark] = useState([]);

  const UnsaveMark = () => {
    setMark([]);
  }

  const SaveMark = (jobs) => {
    setMark(jobs);
  }

  useEffect(() => {
    fetchJobs();
  }, [isNavigateFromHome]);

  useEffect(() => {
    if (isSearchFromHome) {
      setJobTitle(params.jobTitle || "");
      setLocation(params.location || "");
      setIsSearchFromHome(false);
    }
  }, [isSearchFromHome, params.jobTitle, params.location]);

  useEffect(() => {
    handleSearch();
  }, [jobTitle, location, educationLevel, jobType]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (!isObjectEmpty(user)) {
        fetchUserData();
        if (isProfileUpdated) {
          fetchUserData();
        }
      }
    }, 2000);
    return () => clearTimeout(timerId);
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await getJobSeeker();
      if (response && response.statusCode === 200) {
        const data = response.jobseeker;
        setUserData(data);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  const [filters, setFilters] = useState({});

  const handleSelect = (name, value) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters,[name]:value };
      console.log( 'filter',updatedFilters);
      return updatedFilters;
    });
    console.log("filterupdate",filters);
  };
 
  const handleSearch = async (searchData) => {
    try {
      console.log('searchDtata',searchData)
      const response = await searchJobs(searchData);
      if (response && response.statusCode === 200) {
        setJobs(response.jobs);
        console.log("response.jobs",response.jobs);
      }
    } catch (error) {
      console.error("Error searching jobs:", error);
    }
  };
  const fetchJobs = async () => {
    try {
      let response = {};

      if (isNavigateFromHome) {
        const data = {
          jobId: jobId,
        };
        response = await getJobsByJobId(data);
      } else {
        response = await getAllJobs();
      }
      if (response && response.statusCode === 200) {
        if (response.jobs.length > 0) {
          setJobs(response.jobs);
        }
      }
    } catch (e) {
      console.log("error", e);
    }
  };
  const handleApplyJob = (jobId) => {
    if (isLoggedIn == null) {
      Swal.fire({
        title: "You need to signUp or signIn!",
        text: "To apply for this job, you need to signUp or singnIn first.",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "SignUp/SignIn",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/signup");
        }
      });
    } else if (userData && userData.isProfileUpdate === false) {
      Swal.fire({
        title: "You need to update your profile!",
        text: "To apply for this job, you need to update your profile first.",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Update",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/jobSeeker/profile");
        }
      });
    } else {
      navigate(`/applyjob/${jobId}`);
    }
  };

  const clearFilter = (filterName) => {
    if (filterName === "jobTitle") setJobTitle("");
    if (filterName === "location") setLocation("");
    if (filterName === "educationLevel") setEducationLevel("");
    if (filterName === "jobType") setJobType("");
    if (filterName === "newest") setNewest("");
    if (filterName === "dateposted") setDateposted("");
    if (filterName === "remote") setRemote("");
    if (filterName === "within15km") setWithin15km("");
    if (filterName === "pay") setPay("");

  };

  console.log("response.jobs", jobs);


  const handleFilterToggle = () => {
    setShowFilters(!showFilters);
  };

  const handleMoreJob = () => {
    setIsNavigateFromHome(false);
  };
  const handleClick=(val)=>{
     console.log(val)
  }
  return (
    <div style={{ backgroundColor: "#F3F9FE" }}>
      <Navbar />
      {!isNavigateFromHome ? (
        <>
          <Container>
            <SearchJobs onComplete={handleSearch} onSelect={handleSelect} />
          </Container>
          {/* <Container>
            <Row className="p-3">
              <Col
                lg={8}
                md={8}
                sm={12}
                className="bg-jobs-search rounded p-1 mb-2"
              >
                <Row>
                  <Col md={6}>
                    <InputGroup>
                      <InputGroup.Text
                        id="basic-addon1"
                        style={{ background: "transparent", border: "none" }}
                      >
                        <FontAwesomeIcon icon={faSearch} />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="Job Title"
                        aria-label="jobTitle"
                        aria-describedby="basic-addon1"
                        className="placeholder-color"
                        style={{ background: "transparent", border: "none" }}
                        name="jobTitle"
                        value={jobTitle}
                        onChange={(e) => setJobTitle(e.target.value)}
                      />

                    </InputGroup>
                  </Col>
                  <Col md={6}>
                    <InputGroup>
                      <InputGroup.Text
                        id="basic-addon1"
                        style={{ background: "transparent", border: "none" }}
                      >
                        <FontAwesomeIcon icon={faMapMarker} />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="Location"
                        aria-label="Location"
                        aria-describedby="basic-addon1"
                        className="placeholder-color"
                        style={{ background: "transparent", border: "none" }}
                        name="location"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                      />
                      {location && (
                        <Button
                          variant="outline-secondary"
                          onClick={() => clearFilter("location")}
                        >
                          X
                        </Button>
                      )}
                    </InputGroup>
                  </Col>
                </Row>
              </Col>
              <Col lg={2} md={2} sm={12} className="mobile-view-padding">
                <button
                  className="btn btn-color-two w-100 p-2 mb-2 mobile-view-padding-one"
                  onClick={handleSearch}
                >
                  Search
                </button>
              </Col>
              <Col lg={2} md={2} sm={12} className="mobile-view-padding">
                <button className="btn btn-color-two w-100 p-2 mb-2 mobile-view-padding-one" onClick={handleFilterToggle}>
                  Filter
                </button>
              </Col>
            </Row>
          </Container>
          {showFilters && (
            <Container className="mb-2 d-none d-sm-block">
              <Row>
                <Col xl={2} lg={3} md={4} sm={6} className="mt-lg-3">
                  <Dropdown>
                    <Dropdown.Toggle
                      className="btn-color-one w-100 mb-2 fw-bold"
                      id="job-type-dropdown"
                    >
                      {jobType ? jobType : (<span>Job Type <BsCaretDownFill className="ms-1" /></span>)}
                      {jobType && (
                        <FontAwesomeIcon
                          icon={faTimes}
                          onClick={() => clearFilter("jobType")}
                          className="me-1"
                        />
                      )}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="w-100 text-center" id="job-type">
                      <Dropdown.Item className="dropdown-colors" onClick={() => setJobType("Permanent")}>
                        Permanent
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-colors" onClick={() => setJobType("Temporary")}>
                        Temporary
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                </Col>

                <Col xl={2} lg={3} md={4} sm={6} className="mt-lg-3">
                  <Dropdown>
                    <Dropdown.Toggle
                      className="btn-color-one w-100 mb-2 fw-bold"
                      id="education-level-dropdown"
                    >
                      {educationLevel ? educationLevel : (<span>Education Level <BsCaretDownFill className="ms-1" /></span>)}
                      {educationLevel && (
                        <FontAwesomeIcon
                          icon={faTimes}
                          onClick={() => clearFilter("educationLevel")}
                          className="me-1"
                        />
                      )}
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      className="w-100 text-center"
                      id="education-level"
                    >
                      <Dropdown.Item className="dropdown-colors" onClick={() => setEducationLevel("BTech")}>
                        BTech
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-colors" onClick={() => setEducationLevel("MTech")}>
                        MTech
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                </Col>

                <Col xl={2} lg={3} md={4} sm={6} className="mt-lg-3">
                  <Dropdown>
                    <Dropdown.Toggle
                      className="btn-color-one w-100 mb-2 fw-bold"
                      id="newest-dropdown"
                    >
                      {newest ? newest : (<span>Newest <BsCaretDownFill className="ms-1" /></span>)}
                      {newest && (
                        <FontAwesomeIcon
                          icon={faTimes}
                          onClick={() => clearFilter("newest")}
                          className="me-1"
                        />
                      )}
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      className="w-100 text-center"
                      id="newest"
                    >
                      <Dropdown.Item className="dropdown-colors" onClick={() => setNewest("Ascending")}>
                        Ascending
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-colors" onClick={() => setNewest("Descending")}>
                        Descending
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                </Col>

                <Col xl={2} lg={3} md={4} sm={6} className="mt-lg-3">
                  <Dropdown>
                    <Dropdown.Toggle
                      className="btn-color-one w-100 mb-2 fw-bold"
                      id="date-posted-dropdown"
                    >
                      {dateposted ? dateposted : (<span>Date Posted<BsCaretDownFill className="ms-1" /></span>)}
                      {dateposted && (
                        <FontAwesomeIcon
                          icon={faTimes}
                          onClick={() => clearFilter("dateposted")}
                          className="me-1"
                        />
                      )}
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      className="w-100 text-center"
                      id="date-posted"
                    >
                      <Dropdown.Item className="dropdown-colors" onClick={() => setDateposted("This Week")}>
                        This Week
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-colors" onClick={() => setDateposted("This Month")}>
                        This Month
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-colors" onClick={() => setDateposted("This Year")}>
                        This Year
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                </Col>

                <Col xl={2} lg={3} md={4} sm={6} className="mt-lg-3">
                  <Dropdown>
                    <Dropdown.Toggle
                      className="btn-color-one w-100 mb-2 fw-bold"
                      id="remote-dropdown"
                    >
                      {remote ? remote : (<span>Remote <BsCaretDownFill className="ms-1" /></span>)}
                      {remote && (
                        <FontAwesomeIcon
                          icon={faTimes}
                          onClick={() => clearFilter("remote")}
                          className="me-1"
                        />
                      )}
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      className="w-100 text-center"
                      id="remote"
                    >
                      <Dropdown.Item className="dropdown-colors" onClick={() => setRemote("Remote")}>
                        Remote
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-colors" onClick={() => setRemote("Onsite")}>
                        Onsite
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-colors" onClick={() => setRemote("Hybrid")}>
                        Hybrid
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                </Col>

                <Col xl={2} lg={3} md={4} sm={6} className="mt-lg-3">
                  <Dropdown>
                    <Dropdown.Toggle
                      className="btn-color-one w-100 mb-2 fw-bold"
                      id="within15km-dropdown"
                    >
                      {within15km ? within15km : (<span>Within 15 km <BsCaretDownFill className="ms-1" /></span>)}
                      {within15km && (
                        <FontAwesomeIcon
                          icon={faTimes}
                          onClick={() => clearFilter("within15km")}
                          className="me-1"
                        />
                      )}
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      className="w-100 text-center"
                      id="within15km"
                    >
                      <Dropdown.Item className="dropdown-colors" onClick={() => setWithin15km("Within 15 km")}>
                        Within 15 km
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-colors" onClick={() => setWithin15km("Within 25 km")}>
                        Within 25 km
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-colors" onClick={() => setWithin15km("Within 50 km")}>
                        Within 50 km
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-colors" onClick={() => setWithin15km("50+ km")}>
                        50+ km
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                </Col>

                <Col xl={2} lg={3} md={4} sm={6} className="mt-lg-3">
                  <Dropdown>
                    <Dropdown.Toggle
                      className="btn-color-one w-100 mb-2 fw-bold"
                      id="pay-dropdown"
                    >
                      {pay ? pay : (<span>Pay <BsCaretDownFill className="ms-1" /></span>)}
                      {pay && (
                        <FontAwesomeIcon
                          icon={faTimes}
                          onClick={() => clearFilter("pay")}
                          className="me-1"
                        />
                      )}
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      className="w-100 text-center"
                      id="pay"
                    >
                      <Dropdown.Item className="dropdown-colors" onClick={() => setPay("10k - 20k")}>
                        10k - 20k
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-colors" onClick={() => setPay("20k - 30k")}>
                        20k - 30k
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-colors" onClick={() => setPay("30k - 50k")}>
                        30k - 50k
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-colors" onClick={() => setPay("50k +")}>
                        50k +
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                </Col>

              </Row>
            </Container>
          )} */}


          {/* Mobile View start */}
          <Container className="mb-2">
            <Row className="d-sm-none d-block">
              <Col className="text-end">
                {/* <button
                  className="btn btn-white border me-3"
                  type="button"
                  data-bs-toggleE="collapse"
                  data-bs-target="#collapsewithbutton"
                  aria-expanded="false"
                  aria-controls="collapsewithbutton"
                >
                  <FontAwesomeIcon icon={faBars} />
                </button> */}

                <div className="collapse mt-1" id="collapsewithbutton">
                  <div className="card card-body border-0">
                    <Row>
                      <Col lg={2} md={2} sm={6} xs={6}>
                        <Dropdown>
                          <Dropdown.Toggle
                            className="btn-color-one w-100 mb-2 fw-bold mobile-view-text-size"
                            id="dropdown-basic"
                          >
                            Date Posted{" "}
                            <span>
                              <FontAwesomeIcon
                                icon={faAngleDown}
                                className="mt-2 ms-1"
                              />
                            </span>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">
                              Action
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-2">
                              Another action
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-3">
                              Something else
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                      <Col lg={2} md={2} sm={6} xs={6}>
                        <Dropdown>
                          <Dropdown.Toggle
                            className="btn-color-one w-100 mb-2 fw-bold mobile-view-text-size"
                            id="dropdown-basic"
                          >
                            Remote{" "}
                            <span>
                              <FontAwesomeIcon
                                icon={faAngleDown}
                                className="mt-2 ms-1"
                              />
                            </span>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">
                              Action
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-2">
                              Another action
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-3">
                              Something else
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                      <Col lg={2} md={2} sm={6} xs={6}>
                        <Dropdown>
                          <Dropdown.Toggle
                            className="btn-color-one w-100 mb-2 fw-bold mobile-view-text-size"
                            id="dropdown-basic"
                          >
                            Within 25 km{" "}
                            <span>
                              <FontAwesomeIcon
                                icon={faAngleDown}
                                className="mt-2 ms-1"
                              />
                            </span>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">
                              Action
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-2">
                              Another action
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-3">
                              Something else
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>

                      <Col lg={2} md={2} sm={6} xs={6}>
                        <Dropdown>
                          <Dropdown.Toggle
                            className="btn-color-one w-100 mb-2 fw-bold mobile-view-text-size"
                            id="dropdown-basic"
                          >
                            Pay{" "}
                            <span>
                              <FontAwesomeIcon
                                icon={faAngleDown}
                                className="mt-2 ms-1"
                              />
                            </span>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">
                              Action
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-2">
                              Another action
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-3">
                              Something else
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>

                      <Col lg={2} md={2} sm={6} xs={6} className="mt-lg-3">
                        <Dropdown>
                          <Dropdown.Toggle
                            className="btn-color-one w-100 mb-2 fw-bold mobile-view-text-size"
                            id="dropdown-basic"
                          >
                            Education <br />
                            Level{" "}
                            <span>
                              <FontAwesomeIcon
                                icon={faAngleDown}
                                className="mt-2 ms-1"
                              />
                            </span>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">
                              Action
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-2">
                              Another action
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-3">
                              Something else
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>

                      <Col lg={2} md={2} sm={6} xs={6} className="mt-lg-3">
                        <Dropdown>
                          <Dropdown.Toggle
                            className="btn-color-one w-100 mb-2 fw-bold mobile-view-text-size"
                            id="dropdown-basic"
                          >
                            Job <br /> Language{" "}
                            <span>
                              <FontAwesomeIcon
                                icon={faAngleDown}
                                className="mt-2 ms-1"
                              />
                            </span>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">
                              Action
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-2">
                              Another action
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-3">
                              Something else
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <></>
      )}
      {/* Mobile View end */}
      <hr />

      {jobs.length > 0 ? (
        <>
          <Container className="mt-3 mt-lg-5">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row>
                <Col sm={6} className="sidebar-scroll">
                  <Nav className="flex-column">
                    {jobs.map((job, index) => (
                      <Nav.Item key={index}>
                        <Nav.Link
                          eventKey={index === 0 ? "first" : `second-${index}`}
                        >
                          <Card className="bg-white">
                            <CardBody>
                              <Row>
                                <Col xs={2}>
                                  <Image src={icon} className="img-fluid-icon" />
                                </Col>
                                <Col xs={10} className="text-start">
                                  <CardTitle>
                                    <span className="text-titles">
                                      {job.jobBasics.jobTitle}
                                    </span>
                                    <br />
                                    <span className="text-muted text-titles">
                                      {job.jobBasics.jobLocation.city},{" "}
                                      {job.jobBasics.jobLocation.area}
                                    </span>
                                  </CardTitle>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={2}></Col>
                                <Col xs={10}>
                                  <Row className="mt-3">
                                    <Col xs={12}>
                                      <p>
                                        <span>
                                          <Image src={icon6} className="me-1" />
                                        </span>
                                        Pay
                                      </p>
                                    </Col>
                                    <Col xs={12} md={8} lg={7}>
                                      <button className="w-100 btn btn-color-three">
                                        {job.salary.minimum}
                                        {"-"}
                                        {job.salary.maximum}
                                      </button>
                                    </Col>
                                  </Row>

                                  <Row className="my-4">
                                    <Col xs={12}>
                                      <p>
                                        <span>
                                          <Image src={icon5} className="me-1" />
                                        </span>
                                        Job Type
                                      </p>
                                    </Col>

                                    <Col xs={12} md={8} lg={7} className="d-flex justify-content-between">
                                      <button className="w-100 btn btn-color-three">
                                        {job.jobDetails.jobType}
                                      </button>
                                    </Col>
                                  </Row>

                                  {/* <h4>Job Description</h4>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: job.jobDescription,
                                    }}
                                  /> */}
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                  {isNavigateFromHome ? (
                    <div className="mt-3">
                      {" "}
                      <hr />
                      <button
                        className="btn btn-color-primary w-50 mobile-view-padding-one"
                        onClick={(e) => handleMoreJob(e)}
                      >
                        More Jobs
                      </button>
                    </div>
                  ) : null}
                </Col>
                <Col sm={6} className="mt-2 mt-sm-0 sticky sidebar-scroll">
                  <Tab.Content className="sticky">
                    {jobs.map((job, index) => (
                      <Tab.Pane
                        eventKey={index === 0 ? "first" : `second-${index}`}
                        key={index}
                      >
                        <Card className="bg-white border">
                          <CardBody>
                            <Row>
                              <Col xs={12} className="text-start">
                                <Row>
                                  <Col xs={10} lg={11}>
                                    <CardTitle>
                                      <span className="text-titles">
                                      {job.jobBasics.jobTitle} in{" "}
                                      {job.jobBasics.jobLocation.city}{" "}
                                      </span>
                                    </CardTitle>
                                    <p className="text-muted">
                                      {job.jobBasics.jobLocation.city},{" "}
                                      {job.jobBasics.jobLocation.area}
                                    </p>
                                    <p className="fw-bold">
                                      {job.salary.minimum} - {job.salary.maximum}
                                    </p>
                                  </Col>
                                  <Col xs={2} lg={1}>
                                    <div>
                                      {mark === job._id ? <i className="bi bi-bookmark-check-fill cursor-pointer" onClick={UnsaveMark}></i> : <i className="bi bi-bookmark cursor-pointer" onClick={()=>SaveMark(job._id)}></i>}
                                    </div>
                                  </Col>
                                </Row>

                                <hr />

                                <div>
                                  <h4 className="text-titles">Job Detail</h4>
                                  <Row>
                                    <Col xs={11}>
                                      <p className="mt-4">
                                        <span>
                                          <Image src={icon6} className="me-1" />
                                        </span>
                                        Pay
                                      </p>
                                    </Col>
                                    <Col xs={9} sm={8} md={8} lg={6}>
                                      <button className="w-100 btn btn-color-three">
                                        {job.salary.minimum}
                                        {"-"}
                                        {job.salary.maximum}
                                      </button>
                                    </Col>
                                  </Row>

                                  <Row className="mt-4">
                                    <Col xs={12}>
                                      <p>
                                        <span>
                                          <Image src={icon5} className="me-1" />
                                        </span>
                                        Job Type
                                      </p>
                                    </Col>

                                    <Col xs={9} sm={8} md={8} lg={6} className="mb-2 d-flex justify-content-between">
                                      <button className="btn btn-color-three w-100 mt-2">
                                        {job.jobDetails.jobType}
                                      </button>
                                    </Col>
                                  </Row>

                                  <Row className="my-4">
                                    <Col xs={12}>
                                      <p>
                                        <span>
                                          <Image src={icon5} className="me-1" />
                                        </span>
                                        Shift and Schedule
                                      </p>
                                    </Col>

                                    <Col xs={9} sm={8} md={8} lg={6} className="mb-2 d-flex justify-content-between">
                                      <button className="btn btn-color-three w-100 mt-2">
                                        Morning Shift
                                      </button>
                                    </Col>
                                  </Row>

                                </div>

                                <hr />

                                <h4 className="text-titles">Job Description</h4>
                                <div
                                  // className="jobdescription-scroll"
                                  dangerouslySetInnerHTML={{
                                    __html: job.jobDescription,
                                  }}
                                />
                                <div>
                                  {/* <button className="btn site-btn-color" onClick={() => handleApplyJob(job._id)}>Apply Now</button> */}
                                  <Button
                                    className="btn btn-color-two d-block mt-3"
                                    type="button"
                                    onClick={(e) => handleApplyJob(job._id)}
                                  >
                                    Apply Now
                                  </Button>
                                </div>
                                <hr />
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Container>
        </>
      ) : (
        <Container>
          <h1>No jobs available</h1>
        </Container>
      )}
      <Footer />
    </div>
  );
};

export default ViewJobs;
