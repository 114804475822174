import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaLinkedin, FaTwitter, FaFacebook, FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";

const SalaryGuideFooter = () => {
  return (
    <footer className="bg-dark text-light mt-2">
      <Container>
        <Row>
          <Col md={3} className="mt-5">
            <Link to="/home" className="navbar-brand d-flex text-white p-2">
              <h1 style={{ color: "white" }}>Search</h1>
              <span style={{ color: "yellow" }}>
                {" "}
                <h2>Jobs</h2>{" "}
              </span>
            </Link>
          </Col>
          {/* Column 1 */}
          <Col md={3} className="mt-3">
            <h5>Explore ABC Company</h5>
            <Link
              to="#"
              className="text-light d-block mb-1 "
              style={{ textDecoration: "none" }}
            >
              Career Explorer
            </Link>
            <Link
              to="#"
              className="text-light d-block"
              style={{ textDecoration: "none" }}
            >
              Career Advice
            </Link>
            <Link
              to="/jobs"
              className="text-light d-block"
              style={{ textDecoration: "none" }}
            >
              Browse Jobs
            </Link>{" "}
            <Link
              to="/sg"
              className="text-light d-block"
              style={{ textDecoration: "none" }}
            >
              Browse Companies
            </Link>
            <Link
              to="/sg"
              className="text-light d-block"
              style={{ textDecoration: "none" }}
            >
              Salaries
            </Link>{" "}
            <Link
              to="/sg"
              className="text-light d-block"
              style={{ textDecoration: "none" }}
            >
              Countries
            </Link>
            <Link
              to="#"
              className="text-light d-block"
              style={{ textDecoration: "none" }}
            >
              Guidelines for safe job search
            </Link>
          </Col>

          {/* Column 2 */}
          <Col md={3} className="mt-3">
            <h5>Help Center</h5>
            <Link
              to="/about"
              className="text-light d-block mb-1"
              style={{ textDecoration: "none" }}
            >
              About
            </Link>
            <Link
              to="#"
              className="text-light d-block"
              style={{ textDecoration: "none" }}
            >
              Help Center
            </Link>
            {/* Add other items as needed */}
          </Col>

          {/* Column 3 */}
          <Col md={3} className="mt-3">
            <h5>Follow Us</h5>
            <div>
              <a href="https://www.linkedin.com/" className="text-light me-3">
                <FaLinkedin size={30} />
              </a>
              <a href="https://twitter.com/" className="text-light me-3">
                <FaTwitter size={30} />
              </a>
              <a href="https://www.facebook.com/" className="text-light me-3">
                <FaFacebook size={30} />
              </a>
              <a href="https://www.instagram.com/" className="text-light me-3">
                <FaInstagram size={30} />
              </a>
            </div>
          </Col>
        </Row>
      </Container>

      <div className=" p-3 bg-secondary">
        <Row className="py-2">
          <Col md={12} className="text-center">
            © {new Date().getFullYear()} Your Company Name -{" "}
            <Link
              to="/home"
              className="text-light mx-3"
              style={{ textDecoration: "none" }}
            >
              Accessibility at Your Company Name.
            </Link>
            <Link
              to="/home"
              className="text-light mx-3"
              style={{ textDecoration: "none" }}
            >
              Privacy Centre
            </Link>
            <Link
              to="/home"
              className="text-light mx-3"
              style={{ textDecoration: "none" }}
            >
              Cookies
            </Link>
            <Link
              to="/home"
              className="text-light mx-3"
              style={{ textDecoration: "none" }}
            >
              Privacy
            </Link>
            <Link
              to="/home"
              className="text-light mx-3"
              style={{ textDecoration: "none" }}
            >
              Terms
            </Link>
          </Col>
        </Row>
      </div>
    </footer>
  );
};

export default SalaryGuideFooter;
