// import { Card, CardContent } from "@mui/material";
import React from 'react';
import { Card, Col, Container, Image, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import PinVerify from '../../components/common/PinVerify';
import withRouter from '../../components/common/withRouter';
import Navbar from "../../components/Navbar";
// import Footer from "../../components/footerAccessibilityPageFooter";
import LoginImage from "../../assets/images/on-removebg-preview.svg";
import Footer from "../../components/footerAccessibilityPageFooter";
 
const SignWithOtp = () => {
    
    document.title = "Authentication";

    const { existingUser } = useSelector(state => ({
        existingUser: state.Login.existingUser,
        error: state.Login.error,
        loading: state.Login.loading,
    }) || {});
    const userType = existingUser && existingUser.userType ? existingUser.userType : null

    const navigate = useNavigate()
    const location = useLocation();
    console.log('state location',location)

    // useEffect(() => {
    //     // Check if state exists
    //     if (location.state && location.state.singnInConfirmation) {
    //         const { singnInConfirmation } = location.state;
    //         console.log('singnInConfirmation:', singnInConfirmation); // Should log 'electronics'
    //     } else {
    //         console.log('No state received');
    //     }
    // }, [location]);


    let emailOrMobileNumber = {}
    if (location.state) {
        const { email, mobileNumber } = location.state;
        if (email) {
            emailOrMobileNumber.email = email;
        } else if (mobileNumber) {
            emailOrMobileNumber.mobileNumber = mobileNumber;
        } else {
            return null;
        }
    }

    const verifyFirebaseOtp = () =>{


    }
    const onComplete = () => {

        let path = ''
        if (userType == 'JobSeeker') {
            path = '/auth/home'
        } else if (userType == 'Employer') {
            const isTwoFaEnable = existingUser && existingUser.user.isTwoFAEnabled
            console.log('existingUser', existingUser)

            console.log('isTwoFaEnable', isTwoFaEnable)
            if (isTwoFaEnable) {
                path = '/auth/verify/2FA'
            }
            else {
                path = '/employers/jobpostdashbord'
            }

        } else {
            path = '/'
        }
        navigate(path);
    }
    return (
        <>
            <Navbar />
            <div class="d-flex align-items-center signup-bg">
                <Container className='mt-4'>
                    <Row className='reverse-col d-flex justify-content-between align-items-center'>
                        <Col md={6} lg={5} >
                            <Card className='signup-card text-dark'>
                                <h4><strong>Sign In With Login Code</strong></h4>
                                <p class="mt-2">We've sent your one-time passcode to <a href="www.gmail.com" className='text-color-dark'>{location.state.email ? location.state.email:""}</a>. This passcode will expire after 5 minutes.</p>
                                <p class="mt-2"><strong>Enter 6-digit code*</strong></p>
                                <PinVerify
                                    className="border border-info"
                                    emailorMobileNumber={emailOrMobileNumber}
                                    type="oneTimeOtp"
                                    userType={userType}
                                    onComplete={onComplete}
                                />
                            </Card>
                           
                        </Col>
                        
                        <Col md={6} lg={5} className='d-none d-md-block'>
                            <Image className="login-img" src={LoginImage} fluid/>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </>
    )
}
export default withRouter(SignWithOtp);