import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

const Questions = () => {
  return (
    <Container>
      <Row className="justify-content-center">
        <Col className="text-center">
          <Image
            src="https://img.freepik.com/free-vector/paper-with-coffee-phone-pen-cartoon-icon-illustration-business-object-icon-concept-isolated-flat-cartoon-style_138676-2131.jpg"
            rounded
            fluid
            alt="..."
            style={{ width: "200px" }}
          />
        </Col>
      </Row>
      <Row className="justify-content-center mt-3">
        <Col className="text-center">
          <p className="text-secondary">Total Questions will be shown here</p>
        </Col>
      </Row>
    </Container>
  );
};

export default Questions;
