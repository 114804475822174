import React, { useRef, useState } from "react";
import { Button, Alert } from "react-bootstrap";

const FIXED_SIZE = 150;

const CameraCapture = ({ updateAvatar, closeModal }) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [stream, setStream] = useState(null);
  const [error, setError] = useState("");

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoRef.current.srcObject = stream;
      setStream(stream);
      setError("");
    } catch (err) {
      setError("Permission denied. Please enable camera access to take a photo.");
    }
  };

  const takePhoto = () => {
    const context = canvasRef.current.getContext("2d");
    context.drawImage(videoRef.current, 0, 0, FIXED_SIZE, FIXED_SIZE);
    const dataUrl = canvasRef.current.toDataURL();
    updateAvatar(dataUrl);
    closeModal();
    stopCamera();
  };

  const stopCamera = () => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      setStream(null);
    }
  };

  React.useEffect(() => {
    startCamera();
    return () => {
      stopCamera();
    };
  }, []);

  return (
    <div className="d-flex flex-column align-items-center">
      {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
      <video ref={videoRef} autoPlay style={{ width: "100%", maxHeight: "70vh" }} />
      <canvas ref={canvasRef} style={{ display: "none" }} width={FIXED_SIZE} height={FIXED_SIZE} />
      <Button onClick={takePhoto} className="mt-3">
        Take Photo
      </Button>
      <Button onClick={closeModal} className="mt-3">
        Cancel
      </Button>
    </div>
  );
};

export default CameraCapture;
