
// import { signInWithGoogle } from "../../helpers/Config/firebase";


export const googleSignInHelper = async () => {
    try {
      // const result = await signInWithGoogle();
      const result = ""
      console.log('result',result.user)
      const token = await result.user.getIdToken();
      console.log('google token',token)
      const response = await fetch('http://localhost:5000/auth/google', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token })
      });
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };
