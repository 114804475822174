import React from "react";
import "../../assets/css/Feedback.css";
import cilent2 from '../../assets/images/cilent2.webp';
import cilent3 from '../../assets/images/cilent3.jpg';
import cilent4 from '../../assets/images/cilent4.png';
import Feedbackimage from "../../assets/images/Feedbackimage.png";
import { Card, CardBody, Col, Container, Row } from "react-bootstrap";
const FeedbackContent = () => {
  return (
    <div>
      <div className="container-fuild feedback-Main">
        <div className="feedback-content">
          <div className="feedback-content1">
            <h2>WE WANT YOUR </h2>
            <span>FEEDBACK</span>
            <p>Let us know how we can improve our services.</p>
          </div>
          <div className="feedback-content2">
            <img
              src={Feedbackimage}
              alt="Feedbackimage"
              style={{ width: "500px" }}
            />
          </div>
        </div>
      </div>
      <Container>
        <h2 className="text-center mt-3 mb-5">Read What our Customers Say!!</h2>
        <Row xs={1} lg={2} className="mt-4">
          <Col>
            <Card>
                <div className="img-div">
                <Card.Img src={cilent2} className="c-img"/>
                </div>
             
              <CardBody className="text-center">
                <Card.Text>
                  This is a longer card with supporting text below as a natural
                  lead-in to additional content. This content is a little bit
                  longer.
                </Card.Text>
                <Card.Title>
                  <h2>Jonh Almeda </h2>
                  <p>CEO Company</p>
                </Card.Title>
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card>
            <div className="img-div">
                <Card.Img src={cilent2} className="c-img"/>
                </div>
              <CardBody className="text-center">
                <Card.Text>
                  This is a longer card with supporting text below as a natural
                  lead-in to additional content. This content is a little bit
                  longer.
                </Card.Text>
                <Card.Title>
                  <h2>Setall Larson
                  </h2>
                  <p>Secretary</p>
                </Card.Title>
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card>
            <div className="img-div">
                <Card.Img src={cilent3} className="c-img"/>
                </div>
              <CardBody className="text-center">
                <Card.Text>
                  This is a longer card with supporting text below as a natural
                  lead-in to additional content. This content is a little bit
                  longer.
                </Card.Text>
                <Card.Title>
                  <h2>Frank Kinney</h2>
                  <p>Financal Director</p>
                </Card.Title>
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card>
            <div className="img-div">
                <Card.Img src={cilent4} className="c-img"/>
                </div>
              <CardBody className="text-center">
                <Card.Text>
                  This is a longer card with supporting text below as a natural
                  lead-in to additional content. This content is a little bit
                  longer.
                </Card.Text>
                <Card.Title>
                  <h2>Mila Loo</h2>
                  <p>CEO Company</p>
                </Card.Title>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default FeedbackContent;
