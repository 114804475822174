import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import AccessibilityPageFooter from "../../components/footerAccessibilityPageFooter";
import { Container, Card, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const options = [
  {
    name: "Enable both scrolling & backdrop",
    scroll: true,
    backdrop: true,
  },
];

function Collapsepageview({ name, ...props }) {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow} className="me-2">
        <FontAwesomeIcon icon={faPlus} />
      </Button>
      <Offcanvas
        show={show}
        onHide={handleClose}
        {...props}
        backdrop={options[0].backdrop ? "static" : true}
        scroll={options[0].scroll}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{name}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Link to={"/empformone"}>
            <Button variant="light" className="btn btn-lg m-3">
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Post Job
            </Button>
          </Link>
        </Offcanvas.Body>
      </Offcanvas>
      <Container className="mt-5 collapse-view-bg-color p-3 rounded">
        <Row>
          <Col xs={12} md={6} lg={6} className="mb-4">
            <Card className="h-100">
              <Card.Img
                variant="top"
                src="https://d341ezm4iqaae0.cloudfront.net/assets/2021/10/16235635/SizeXL-1.png"
                fluid
              />
              <Card.Body className="text-center">
                <Card.Text>
                  {" "}
                  <h3>Looking For a job?</h3>
                </Card.Text>
                <Button>find jobs</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} className="mb-4">
            <Card className="h-100">
              <Card.Img
                variant="top"
                src="https://d341ezm4iqaae0.cloudfront.net/assets/2021/10/16235635/SizeXL-1.png"
                fluid
              />
              <Card.Body className="text-center">
                <Card.Text>
                  <h3>Hiring an employee?</h3>
                </Card.Text>
                <Link to={"/empformone"}>
                  {" "}
                  <Button>post a job</Button>
                </Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <div className="container">
        <AccessibilityPageFooter />
      </div>
    </>
  );
}

export default Collapsepageview;
