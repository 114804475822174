import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Dropdown,
  Button,
  FormControl,
} from "react-bootstrap";
import Navbar from "../components/Navbar";
import SalaryGuideFooter from "../components/SalaryGuideFooter";
import TopCompanyCard from "../helpers/TopCompanyCard";
import FrequentlySearchedCareersContent from "../helpers/FrequentlySearchedCareersContent";
import { FaHome, FaSearch } from "react-icons/fa";
import TopPayingCompanies from "../pages/TopPayingCompanies";
// import accenture from "../assets/companylogos/accenture.jpg";
// import amazon from "../assets/companylogos/amazon.png";
// import cts from "../assets/companylogos/cts.png";
// import facebook from "../assets/companylogos/facebook.jpg";
// import flipkart from "../assets/companylogos/flipkart.jpg";
// import google from "../assets/companylogos/google.jpg";
// import microsoft from "../assets/companylogos/microsoft.png";
// import tcs from "../assets/companylogos/tcs.png";
// import wipro from "../assets/companylogos/wipro.jpg";
// import zoho from "../assets/companylogos/zoho.png";

const SalaryGuidePage = () => {
  const companies = [
    {
      name: "Company A",
      salary: "$80,000 - $90,000",
      logo: "zoho.png",
    },
    {
      name: "Company B",
      salary: "$90,000 - $100,000",
      logo: "tcs.png",
    },
    {
      name: "Company C",
      salary: "$85,000 - $90,000",
      logo: "wipro.jpg",
    },
    {
      name: "Company D",
      salary: "$80,000 - $90,000",
      logo: "microsoft.png",
    },
    {
      name: "Company E",
      salary: "$90,000 - $100,000",
      logo: "zoho.png",
    },
    {
      name: "Company F",
      salary: "$85,000 - $90,000",
      logo: "google.jpg",
    },
    {
      name: "Company F",
      salary: "$85,000 - $90,000",
      logo: "flipkart.jpg",
    },
    {
      name: "Company F",
      salary: "$85,000 - $90,000",
      logo: "amazon.png",
    },
    {
      name: "Company F",
      salary: "$85,000 - $90,000",
      logo: "facebook.jpg",
    },
    // Add more companies as needed
  ];

  const [selectedIndustry, setSelectedIndustry] = useState("All Industries");

  const handleIndustryChange = (industry) => {
    setSelectedIndustry(industry);
  };

  return (
    <div className="main">
      <Navbar />
      <Container className="mt-lg-3 mt-3">
        <div className="container">
          <h2>Find a career you'll love</h2>
          <p className="text-justify">
            Explore which careers have the highest job satisfaction, best
            salaries, and more
          </p>
        </div>

        {/* Search Form */}

        <Container className="mt-3">
          <form className="text-center  bg-white rounded  mx-auto p-2">
            <Row className="align-items-center justify-content-center">
              <Col xs={12} md={5} className="mt-1">
                <div className="d-flex align-items-center">
                  <FaSearch size={25} />
                  <FormControl
                    type="text"
                    name="search"
                    placeholder="Job title, keywords, or company"
                    className="input-lg border-0 border-bottom rounded bg-transparent w-100"
                  />
                </div>
              </Col>
              <Col xs={12} md={5} className="mt-1">
                <div className="d-flex align-items-center">
                  <FaHome size={25} />
                  <FormControl
                    type="text"
                    name="company"
                    placeholder="Search by company City, state, zip code, or 'remote'"
                    className="input-lg border-0 border-bottom rounded bg-transparent w-100"
                  />
                </div>
              </Col>
              <Col xs={12} md={2}>
                <Button className="btn site-btn-color  mt-1 form-control" type="submit">
                  Search
                </Button>
              </Col>
            </Row>
          </form>
        </Container>

        {/* Top Paying JOBS Section */}
        <Container className="mt-3">
          <h2>Browse top-paying jobs by industry </h2>
          <div className="mt-3">
            <Dropdown>
              <Dropdown.Toggle
              // style={{
              //   background: "rgb(22, 76, 134)",
              //   color: "white",
              //   height: 45,
              //   width: "auto",
              // }}
              // id="industryDropdown"
              >
                {selectedIndustry}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => handleIndustryChange("All Industries")}
                >
                  All Industries
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleIndustryChange("Technology")}
                >
                  Technology
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleIndustryChange("Finance")}>
                  Finance
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <Row xs={1} md={2} lg={3} className="g-3 mt-1">
            {companies.map((company, index) => (
              <Col key={index}>
                <TopCompanyCard company={company} />
              </Col>
            ))}
          </Row>
        </Container>
        {/* Browse top-paying companies by industry Section */}
        <div>
          <TopPayingCompanies companies={companies} />
        </div>
        <div className="d-flux justify-content-center mt-3">
          <FrequentlySearchedCareersContent />
        </div>
      </Container>

      <SalaryGuideFooter />
    </div>
  );
};

export default SalaryGuidePage;
