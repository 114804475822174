import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import "../assets/css/ViewJobs.css";
import Navbar from "../components/Navbar";
import { getJobsByJobId, getJobSeeker } from '../helpers/backendHelper';
import { isObjectEmpty } from "../helpers/utility";

const JobDetail = () => {

    document.title = "Job Detail";

    const { user } = useSelector((state) => ({
        user: state.Login.user,
    }));

    const { jobId } = useParams();
    const [jobDetail, setJobDetail] = useState(null);

    const isLoggedIn = localStorage.getItem("authUser");
    const isProfileUpdated = localStorage.getItem("isProfileUpdated");
    const [userData, setUserData] = useState({
        name: "",
        email: "",
        mobileNumber: "",
        employerCity: "",
        employerCountry: "",
        employerArea: "",
        employerPincode: "",
        employerStreet: "",
        isTwoFAEnabled: "",
        isTwoFASetup: "",
        isProfileUpdate: false,
    });

    const navigate = useNavigate();

    const fetchJobDetail = async (jobId) => {
        try{
            const response = await getJobsByJobId(jobId);
            setJobDetail(response.jobs);
        }
        catch(error){
            console.log("error Job Detail cannot be fetched",error);
        }
    }

    useEffect(()=>{
        if(jobId){
            // console.log("jobId from params:", jobId);
            fetchJobDetail(jobId);
        }
    },[jobId])

    useEffect(() => {
        const timerId = setTimeout(() => {
          if (!isObjectEmpty(user)) {
            fetchUserData();
            if (isProfileUpdated) {
              fetchUserData();
            }
          }
        }, 2000);
        return () => clearTimeout(timerId);
    }, []);
    
    const fetchUserData = async () => {
    try {
        const response = await getJobSeeker();
        if (response && response.statusCode === 200) {
        const data = response.jobseeker;
        setUserData(data);
        }
    } catch (error) {
        console.error("Error fetching user data:", error);
    }
    };

    const handleApply = (jobId) => {
        if (isLoggedIn == null) {
            Swal.fire({
              title: "You need to signUp or signIn!",
              text: "To apply for this job, you need to signUp or singnIn first.",
              icon: "info",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "SignUp/SignIn",
              cancelButtonText: "Cancel",
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/signup");
              }
            });
        } else if (userData && userData.isProfileUpdate === false) {
            Swal.fire({
              title: "You need to update your profile!",
              text: "To apply for this job, you need to update your profile first.",
              icon: "info",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Update",
              cancelButtonText: "Cancel",
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/jobSeeker/profile");
              }
            });
        } else {
            navigate(`/applyjob/${jobId}`);
        } 
    }

    return(
        <Container fluid className="job-detail-main">
            <Navbar />

             <div className="job-listing p-1 pt-5 p-sm-5 jobdetail d-flex justify-content-center align-items-center">
                <div>
                    {jobDetail && Array.isArray(jobDetail) && jobDetail.length > 0 && (
                        jobDetail.map((job) => (
                            <h2 className="occupation-title" key={job._id}>{job.jobBasics.jobTitle}</h2>
                        ))
                    )}
                    {/* <div><p className="mt-3 fs-5 text-center"><Link className="alpha-link" to=''>Alpha</Link><IoIosArrowForward/>Hotel Manager with PHD</p></div> */}
                </div>
            </div>

            <Container >
                <Row>
                    <Col xl={8} className='mt-5'>
                        <div className='social-media'>
                            {/* <Row className="mb-3">
                                <Col>
                                    <small className="text-muted fs-5">16 Sep 2024 by <Link className="alpha-link fs-5" to=''>Alpha</Link></small>
                                </Col>
                            </Row> */}
                            <Row>
                                <Col>
                                    {jobDetail && Array.isArray(jobDetail) && jobDetail.length > 0 && (
                                        jobDetail.map((job) => (
                                            <h2 className="job-heading" key={job._id}>{job.jobBasics.jobTitle}</h2>
                                        ))
                                    )}
                                </Col>
                            </Row>
                            {/* <Row className="social-buttons-row mt-sm-2">
                                <Col xs={6} sm={3} className='mt-2'>
                                    <Link className='social-btn'><FaTwitter className="me-2" />twitter</Link>
                                </Col>
                                <Col xs={6} sm={3} className='mt-2'>
                                    <Link className='social-btn'><FaFacebookF className="me-2" />facebook</Link>
                                </Col>
                                <Col xs={6} sm={3} className='mt-2'>
                                    <Link className='social-btn'><FaLinkedinIn className="me-2" /> linkedin</Link>
                                </Col>
                                <Col xs={6} sm={3} className='mt-2'>
                                    <Link className='social-btn'><FaInstagram className="me-2" /> instagram</Link>
                                </Col>
                            </Row> */}
                        </div>

                        {/* <div className='mt-4 p-2 p-sm-4 div-border'>
                            <div className='d-flex align-items-center'>
                                <p className='job-topics-number'><span className='m-auto'>1</span></p>
                                <h3 className='ms-2'>Overview</h3>
                            </div>
                            <p className='para-content'>When team members told us they needed more flexibility around where and how they worked, we acted, creating two options to accommodate two different styles of work. One non-negotiable principle along the way? We had to retain our deep culture of collaboration, both among ourselves and with our clients. Introducing Work From Near and Work From Anywhere at WillowTree. Learn more here. Please indicate which location(s) you're interested.</p>
                            <div className='d-flex align-items-center'>
                                <RiLinkM className='fs-5 me-2' />
                                <p className='fs-5 para-content'>Attachment</p>
                            </div>
                        </div> */}

                        {jobDetail && Array.isArray(jobDetail) && jobDetail.length > 0 && (
                            jobDetail.map((job) => (
                                <div className='mt-4 p-2 p-sm-4 div-border'>
                                    {/* <div className='d-flex align-items-center'>
                                        <p className='job-topics-number'><span className='m-auto'>2</span></p>
                                        <h3 className='ms-2'>Job Description</h3>
                                    </div> */}
                                    <h3 className='mt-2 mb-3'>Job Description</h3>
                                    <p className='para-content'>As a <span className='span-product'>Product Designer</span> at WillowTree, you’ll give form to ideas by being the voice and owner of product decisions. You’ll drive the design direction, and then make it happen!</p>
                                    <p className='mt-4 para-content'>We understand our responsibility to create a diverse, equitable, and inclusive place within the tech industry, while pushing to make our industry more representative.</p>
                                </div>
                            ))
                        )}

                        

                        {/* <div className='mt-4 p-2 p-sm-4 div-border'>
                            <div className='d-flex align-items-center'>
                                <p className='job-topics-number'><span className='m-auto'>3</span></p>
                                <h3 className='ms-2'>Required Skills:</h3>
                            </div>
                            <ul className='skill-list'>
                                <li><span className='para-content'>Full stack</span></li>
                                <li><span className='para-content'>Developer</span></li>
                                <li><span className='para-content'>React</span></li>
                            </ul>
                        </div> */}

                    </Col>

                    <Col xl={4} className='mt-5 d-flex justify-content-center'>

                        <div>
                            { jobDetail && Array.isArray(jobDetail) && jobDetail.length > 0 ? (
                                jobDetail.map((job) => (
                                    <Card className="profile-card text-center" key={job._id}>
                                        <Card.Body>
                                            <div className="avatar-detail">
                                                <span className="initials">A</span>
                                            </div>
                                            <Card.Title><Link className="alpha-link2" to=''>{job.companyName}</Link></Card.Title>
                                            <Button className="visit-website-btn">
                                                Visit website
                                            </Button>
                                            <hr className='hr-slash' />
                                            <Row className="text-left">
                                                <Col sm={6} md={4} xl={6} className='p-0 text-start mb-4'>
                                                    <p className='job-head'>Salary</p>
                                                    <span className='job-value'>{job.salary?.range?.minimum} - {job.salary?.range?.maximum}</span>
                                                </Col>
                                                <Col sm={6} md={4} xl={6} className='p-0 text-start mb-4'>
                                                    <p>Currency</p>
                                                    <span>INR</span>
                                                </Col>
                                                <Col sm={6} md={4} xl={6} className='p-0 text-start mb-4'>
                                                    <p>Duration</p>
                                                    <span>Monthly</span>
                                                </Col>
                                                <Col sm={6} md={4} xl={6} className='p-0 text-start mb-4'>
                                                    <p>Expertise</p>
                                                    <span>Expert</span>
                                                </Col>
                                                <Col sm={6} md={4} xl={6} className='p-0 text-start mb-4'>
                                                    <p>Location</p>
                                                    <span>{job.jobBasics.jobLocation.city}</span>
                                                </Col>
                                                <Col sm={6} md={4} xl={6} className='p-0 text-start mb-4'>
                                                    <p>Job Type</p>
                                                    <span>{job.jobDetails.jobType}</span>
                                                </Col>
                                                <Col sm={6} md={4} xl={6} className='p-0 text-start mb-4'>
                                                    <p>Date</p>
                                                    <span>16 Sep, 2024</span>
                                                </Col>
                                                <Col sm={6} md={4} xl={6} className='p-0 text-start mb-4'>
                                                    <p>Experience</p>
                                                    <span>2+ Year</span>
                                                </Col>
                                                <Col sm={6} md={4} xl={6} className='p-0 text-start mb-4'>
                                                    <p>Category</p>
                                                    <span>{job.jobBasics.jobTitle}</span>
                                                </Col>
                                                <Col sm={6} md={4} xl={6} className='p-0 text-start mb-4'>
                                                    <p>Deadline</p>
                                                    <span>16 Sep, 2024</span>
                                                </Col>
                                            </Row>

                                            {/* <Row>
                                                <Col xs={12} className='badge-section'>
                                                    <Badge bg="light" text="dark" className='badge-content'>Full Stack</Badge>
                                                    <Badge bg="light" text="dark" className='badge-content'>Developer</Badge>
                                                    <Badge bg="light" text="dark" className='badge-content'>React</Badge>
                                                </Col>
                                            </Row> */}
                                            
                                            <Button className="detail-login-btn" onClick={()=>{handleApply(job._id)}}>
                                                Apply
                                            </Button>
                                        </Card.Body>
                                    </Card>
                                ))
                            ) : (
                                <p>No job details available</p>
                            )}
                        </div>

                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default JobDetail;