import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Card, InputGroup } from "react-bootstrap";
import $, { data } from 'jquery';
import MultiRangeSlider from '../components/MultiRangeSlider';
import "../assets/css/ViewJobs.css";
import { FiMinus } from "react-icons/fi";
import { FaPlus } from "react-icons/fa6";
import { IoIosArrowDown } from "react-icons/io";


const ViewJobsFilters = ({sentDataToParent, searchApi}) =>{

    const [service, setService] = useState('');
    const [category, setCategory] = useState('');
    // const [country, setCountry] = useState('');
    // const [states, setStates] = useState({});
    const [dropdown, setDropdown] = useState({});
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // const [isOpenSearch, setIsOpenSearch] = useState(true);
    const [isOpenSite, setIsOpenSite] = useState(true);
    const [isOpenStates, setIsOpenStates] = useState(true);
    const [isOpenJobtype, setIsOpenJobtype] = useState(true);
    const [isOpenExperience, setIsOpenExperience] = useState(true);
    const [isOpenSalary, setIsOpenSalary] = useState(true);
    const [isOpenCategory, setIsOpenCategory] = useState(true);

    const [checkboxSite, setCheckboxSite] = useState({
        remote: false,
        onsite: false,
        hybrid: false
    });

    const [checkboxJobType, setCheckboxJobType] = useState({
        internship: false,
        fulltime: false,
        parttime: false
    });

    const [checkboxExperience, setCheckboxExperience] = useState({
        twoyear: false,
        fouryear: false
    });

    const handleCheckboxSite = async(event) => {
        const { id, checked } = event.target;
        setCheckboxSite((prevState) => ({
            ...prevState,
            [id]: checked,
        }));

        // await searchApi(checkboxSite);
    };

    const handleCheckboxJobType = async(event) => {
        const { id, checked } = event.target;
        setCheckboxJobType((prevState) => ({
            ...prevState,
            [id]: checked,
        }));

        // await searchApi(checkboxJobType);
    };

    const handleCheckboxExperience = async(event) => {
        const { id, checked } = event.target;
        setCheckboxExperience((prevState) => ({
            ...prevState,
            [id]: checked,
        }));

        // await searchApi(checkboxExperience);
    };

    // const searchRef = useRef(null);
    const SiteRef = useRef(null);
    const StatesRef = useRef(null);
    const jobtypeRef = useRef(null);
    const experienceRef = useRef(null);
    const salaryRef = useRef(null);
    const categoryRef = useRef(null);

    const elements = [
        // { ref: searchRef, isOpen: isOpenSearch },
        { ref: SiteRef, isOpen: isOpenSite },
        { ref: StatesRef, isOpen: isOpenStates },
        { ref: jobtypeRef, isOpen: isOpenJobtype },
        { ref: experienceRef, isOpen: isOpenExperience },
        { ref: salaryRef, isOpen: isOpenSalary },
        { ref: categoryRef, isOpen: isOpenCategory },
    ];

    useEffect(() => {
        elements.forEach(({ ref, isOpen }) => {
            if (ref.current) {
                if (isOpen) {
                    $(ref.current).slideDown();
                } else {
                    $(ref.current).slideUp();
                }
            }
        });
    }, [isOpenSite, isOpenStates, isOpenJobtype, isOpenExperience, isOpenSalary, isOpenCategory]);


    const [filtersData, setFiltersData] = useState({
        ...checkboxSite,
        ...checkboxJobType,
        ...checkboxExperience
    });

    let dropDownfield = {}
    
    const handleStatesChange = async(e) => {
        dropDownfield["states"]=e.target.value;
        console.log("dropDownfield",dropDownfield);
        // setDropdown({...dropdown,...dropDownfield});
        setFiltersData({...filtersData,...dropDownfield});

        // await searchApi(dropDownfield);
    };

    const handleServiceChange = async(e) => {
        dropDownfield["category"]=e.target.value;
        // setDropdown({...dropdown,...dropDownfield});
        setFiltersData({...filtersData,...dropDownfield});

        // await searchApi(dropDownfield);
    };

    // console.log("dropdown",dropdown);

    const handleDataChange = (data) => {
        setFiltersData({...filtersData,...data});
    };
    
    const toggleSite = () => {
        setIsOpenSite(!isOpenSite);  // Toggle the site state
    };
    

    const toggleStates = () => {
        setIsOpenStates(!isOpenStates);
    };

    const toggleJobtype = () => {
        setIsOpenJobtype(!isOpenJobtype);
    };

    const toggleExperience = () => {
        setIsOpenExperience(!isOpenExperience);
    };

    const toggleSalary = () => {
        setIsOpenSalary(!isOpenSalary);
    };

    const toggleCategory = () => {
        setIsOpenCategory(!isOpenCategory);
    };

    // const [selectedOption, setSelectedOption] = useState('');
    // const [selectedOptionExperience, setSelectedOptionExperience] = useState('');

    // const handleCheckboxChange = (event) => {
    //     const { name } = event.target;
    //     setSelectedOption(name);
    // };

    // const handleCheckboxexperience = (event) => {
    //     const { name } = event.target;
    //     setSelectedOptionExperience(name);
    // };

    return(
        <>
            <Card className='mt-3 filter-card'>
                {/* Location Section */}
                <div>
                    <div className='d-flex justify-content-between'>
                        <p className='fs-5' style={{ fontWeight: '500' }}>Site</p>
                        <div onClick={toggleSite}>
                            {isOpenSite ? <FiMinus className='fs-5 plus-minus' /> : <FaPlus className='fs-5 plus-minus' />}
                        </div>
                    </div>
                    <div className='mb-2 pb-1' ref={SiteRef} style={{ display: 'block' }}>
                        <Form.Check
                            type="checkbox"
                            id="remote"
                            label="Remote"
                            className="filter-checkbox mt-2 mb-3"
                            checked={checkboxSite.remote}
                            onChange={handleCheckboxSite}
                        />
                        <Form.Check
                            type="checkbox"
                            id="onsite"
                            label="Onsite"
                            className="filter-checkbox mb-3"
                            checked={checkboxSite.onsite}
                            onChange={handleCheckboxSite}
                        />
                        <Form.Check
                            type="checkbox"
                            id="hybrid"
                            label="Hybrid"
                            className="filter-checkbox"
                            checked={checkboxSite.hybrid}
                            onChange={handleCheckboxSite}
                        />
                    </div>
                </div>

                <hr />

                {/* States Section */}
                <div>
                    <div className='d-flex justify-content-between'>
                        <p className='fs-5' style={{ fontWeight: '500' }}>States</p>
                        <div onClick={toggleStates}>
                            {isOpenStates ? <FiMinus className='fs-5 plus-minus' /> : <FaPlus className='fs-5 plus-minus' />}
                        </div>
                    </div>
                    <div className='mb-2' ref={StatesRef} style={{ display: 'block' }}>
                        <InputGroup className='my-2 dropdown-border'>
                            <Form.Control
                                className='forms-control'
                                as="select"
                                // value={dropdown}
                                onChange={handleStatesChange}
                            >
                                <option value="">Select States</option>
                                <option value="Tamilnadu">Tamilnadu</option>
                                <option value="Andra pradesh">Andra pradesh</option>
                                <option value="Maharashtra">Maharashtra</option>
                                <option value="Kerala">Kerala</option>
                                <option value="Karnataka">Karnataka</option>
                                <option value="Gujarat">Gujarat</option>
                            </Form.Control>
                            <InputGroup.Text className='arrow-icon2'>
                                <IoIosArrowDown />
                            </InputGroup.Text>
                        </InputGroup>
                    </div>
                </div>

                <hr />

                {/* Job type section */}
                <div>
                    <div className='d-flex justify-content-between'>
                        <p className='fs-5' style={{ fontWeight: '500' }}>Job Type</p>
                        <div onClick={toggleJobtype}>
                            {isOpenJobtype ? <FiMinus className='fs-5 plus-minus' /> : <FaPlus className='fs-5 plus-minus' />}
                        </div>
                    </div>
                    <div
                        ref={jobtypeRef}
                        className={`collapse-content ${isOpenJobtype ? 'open' : 'closed'}`}  // Conditional class based on state
                    >
                        <Form>
                            <div className='mt-3 d-flex justify-content-between'>
                                <Form.Check
                                    type="checkbox"
                                    id="fulltime"
                                    label="Full time"
                                    className="filter-checkbox"
                                    checked={checkboxJobType.fulltime}
                                    onChange={handleCheckboxJobType}
                                />
                                {/* <div className='jobtype-number d-flex'>
                                    <p className='m-auto'>1</p>
                                </div> */}
                            </div>

                            <div className='mt-2 d-flex justify-content-between'>
                                <Form.Check
                                    type="checkbox"
                                    id="parttime"
                                    label="Part time"
                                    className="filter-checkbox"
                                    checked={checkboxJobType.parttime}
                                    onChange={handleCheckboxJobType}
                                />
                                {/* <div className='jobtype-number d-flex'>
                                    <p className='m-auto'>10</p>
                                </div> */}
                            </div>

                            <div className='mt-2 d-flex justify-content-between'>
                                <Form.Check
                                    type="checkbox"
                                    id="internship"
                                    label="Internship"
                                    className="filter-checkbox"
                                    checked={checkboxJobType.remote}
                                    onChange={handleCheckboxJobType}
                                />
                                {/* <div className='jobtype-number d-flex'>
                                    <p className='m-auto'>1</p>
                                </div> */}
                            </div>
                        </Form>
                    </div>
                </div>

                <hr />

                {/* Experience section */}
                <div>
                    <div className='d-flex justify-content-between'>
                        <p className='fs-5' style={{ fontWeight: '500' }}>Experience</p>
                        <div onClick={toggleExperience}>
                            {isOpenExperience ? <FiMinus className='fs-5 plus-minus' /> : <FaPlus className='fs-5 plus-minus' />}
                        </div>
                    </div>
                    <div
                        ref={experienceRef}
                        className={`collapse-content ${isOpenExperience ? 'open' : 'closed'}`}  // Conditional class based on state
                    >
                        <Form>
                            <div className='mt-3 d-flex justify-content-between'>
                                <Form.Check
                                    type="checkbox"
                                    id="twoyear"
                                    label="2+ year"
                                    className="filter-checkbox"
                                    checked={checkboxExperience.two}
                                    onChange={handleCheckboxExperience}
                                />
                                {/* <div className='jobtype-number d-flex'>
                                    <p className='m-auto'>1</p>
                                </div> */}
                            </div>

                            <div className='my-2 d-flex justify-content-between'>
                                <Form.Check
                                    type="checkbox"
                                    id="fouryear"
                                    label="4+ year"
                                    className="filter-checkbox"
                                    checked={checkboxExperience.fouryear}
                                    onChange={handleCheckboxExperience}
                                />
                                {/* <div className='jobtype-number d-flex'>
                                    <p className='m-auto'>10</p>
                                </div> */}
                            </div>
                        </Form>
                    </div>
                </div>

                <hr />

                {/* Salary section */}
                <div>
                    <div className='d-flex justify-content-between'>
                        <p className='fs-5' style={{ fontWeight: '500' }}>Salary</p>
                        <div onClick={toggleSalary}>
                            {isOpenSalary ? <FiMinus className='fs-5 plus-minus' /> : <FaPlus className='fs-5 plus-minus' />}
                        </div>
                    </div>
                    <div
                        ref={salaryRef}
                        className={`collapse-content ${isOpenSalary ? 'open' : 'closed'}`}  // Conditional class based on state
                    >
                       
                        <MultiRangeSlider
                            min={0}
                            max={5000000}
                            onChange={({ min, max }) => console.log(`min = ${min}, max = ${max}`)}
                            multiRangevalues={handleDataChange}
                            // searchApi = {searchApi()}
                        />

                    </div>
                </div>

                <hr />

                {/* Category section */}
                <div>
                    <div className='d-flex justify-content-between'>
                        <p className='fs-5' style={{ fontWeight: '500' }}>Category</p>
                        <div onClick={toggleCategory}>
                            {isOpenCategory ? <FiMinus className='fs-5 plus-minus' /> : <FaPlus className='fs-5 plus-minus' />}
                        </div>
                    </div>
                    <div
                        ref={categoryRef}
                        className={`collapse-content ${isOpenCategory ? 'open' : 'closed'}`}  // Conditional class based on state
                    >
                        <InputGroup className='my-2 dropdown-border'>
                            <Form.Control
                                className='forms-control'
                                as="select"
                                // value={dropdown}
                                onChange={handleServiceChange}
                            >
                                <option value="">Select Category</option>
                                <option value="python">Python</option>
                                <option value="node">Node</option>
                                <option value="java">Java</option>
                                <option value="C">C</option>
                                <option value="C++">C++</option>
                                <option value="javascript">Javascript</option>
                            </Form.Control>
                            <InputGroup.Text className='arrow-icon2'>
                                <IoIosArrowDown />
                            </InputGroup.Text>
                        </InputGroup>
                    </div>
                </div>

                {/* <hr />

                <div>
                    <Button className='viewjobs-apply-btn' size="lg">Apply Filter</Button>
                    <Button className='clearfilter-btn bg-transparent text-dark mt-2' size="lg">Clear Filter</Button>
                </div> */}
            </Card>
        </>
    )
}

export default ViewJobsFilters;