import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Table, Button, Form } from 'react-bootstrap';
import { FaFilePdf } from 'react-icons/fa';
import '../../../assets/css/employer/Candidate.css';
import EmployerSidebar from '../../../components/EmployerSidebar';
import EmployerNavBar from '../../../components/Employer/EmployerNavbar';
import { getJobApplicationByEmployerId, scheduleInterView, updateApplicationByStatusByEmployer } from '../../../helpers/backendHelper';
import InterestedButtons from '../../../components/common/InterestedButtonGroup';
import InterviewScheduler from '../../../components/InterviewScheduler';
import Swal from 'sweetalert2';
import { IsNullOrEmpty, isObjectEmpty } from '../../../helpers/utility';

const CandidateDashboard = () => {
  const [activeTab, setActiveTab] = useState('awaitingReview');
  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [candidateId, setCandidateId] = useState();
  const [appliedJobId, setAppliedJobId] = useState();
  const [candidatesData, setCandidateData] = useState({
    active: [],
    shortlist: [],
    awaitingReview: [],
    reviewed: [],
    contacting: [],
    rejected: [],
    hired: [],
  });

  // Loading state to prevent multiple API calls
  const [loading, setLoading] = useState(true);

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    if (loading) {
      fetchCandidates();  
    }
  }, [loading]); 

  const handleScheduleInterview = async (candidateDetails) => {
    const candidatePayload = {
      ...candidateDetails,
      jobSeeker_id:candidateId,
      jobId:appliedJobId,
      status:'upcoming'
    }
    const interviewResponse = await scheduleInterView(candidatePayload)
    console.log('interviewResponse',interviewResponse)
    if(!IsNullOrEmpty(interviewResponse)){
      console.log('interviewResponse')
 
      if(interviewResponse.statusCode ===200){
        Swal.fire({
          title: 'Success',
          text: 'interview schedule successfully!',
          icon: 'success',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        })
      }
      else{
        Swal.fire({
          title: 'error',
          text: interviewResponse.message,
          icon: 'error',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        })
      }
    }
    else{
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "",
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: "Close",
      });
    }
    
  };

  

  const fetchCandidates = async () => {
    try {
      const response = await getJobApplicationByEmployerId();
      if (response && response.statusCode === 200) {
        splitCandidatesByStatus(response.appliedJobsData);
      } else {
        console.error('Failed to fetch candidates');
      }
    } catch (e) {
      console.error('Error during fetch', e);
    } finally {
      setLoading(false); // Set loading to false once fetch is complete
    }
  };

  const splitCandidatesByStatus = (appliedJobsData) => {
    const statusGroups = {
      active: [],
      shortlist: [],
      awaitingReview: [],
      reviewed: [],
      contacting: [],
      rejected: [],
      hired: [],
    };

    appliedJobsData.forEach((job) => {
      const { applicationStatus, subApplicationStatus } = job.appliedJobs;

      if (applicationStatus === 'active') {
        statusGroups.active.push(job);
        subApplicationStatus.forEach((status) => {
          const normalizedStatus = status.toLowerCase().replace(/\s/g, '');
          if (normalizedStatus.includes('awaitingreview')) {
            statusGroups.awaitingReview.push(job);
          } else if (normalizedStatus.includes('reviewed')) {
            statusGroups.reviewed.push(job);
          } else if (normalizedStatus.includes('shortlist')) {
            statusGroups.shortlist.push(job);
          } else if (normalizedStatus.includes('contacting')) {
            statusGroups.contacting.push(job);
          }
        });
      }
      if (applicationStatus === 'rejected') {
        statusGroups.rejected.push(job);
      }
      if (applicationStatus === 'hired') {
        statusGroups.hired.push(job);
      }
    });

    setCandidateData(statusGroups);
  };

  const handleInterestedClick = async (candidateId, newStatus) => {
    try {
      let data = {};
      let newSubStatuses = [];

      switch (newStatus) {
        case 'shortlist':
          newSubStatuses = ['shortlist','reviewed'];;
          data = { id: candidateId, payload: { status: 'active', subStatuses: newSubStatuses } };
          break;
        case 'reviewed':
          newSubStatuses = ['reviewed'];
          data = { id: candidateId, payload: { status: 'active', subStatuses: newSubStatuses } };
          break;
        case 'awaitingReview':
          data = { id: candidateId, payload: { status: 'active', subStatuses: ['awaitingReview'] } };
          break;
        case 'hired':
          data = { id: candidateId, payload: { status: 'hired' } };
          break;
        case 'remove':
          data = { id: candidateId, payload: { status: 'rejected' } };
          break;
        default:
          console.error('Unknown status');
          return;
      }

      const response = await updateApplicationByStatusByEmployer(data);

      if (response && response.statusCode === 200) {
        fetchCandidates();
        setCandidateData((prevData) => {
          const updatedCandidates = { ...prevData };
          for (let category in updatedCandidates) {
            updatedCandidates[category] = updatedCandidates[category].map((candidate) => {
              if (candidate.appliedJobs._id === candidateId) {
                return {
                  ...candidate,
                  appliedJobs: {
                    ...candidate.appliedJobs,
                    applicationStatus: newStatus,
                    subApplicationStatus: newSubStatuses,
                  },
                };
              }
              return candidate;
            });
          }
          
          return updatedCandidates;
        });
      } else {
        console.error('Failed to update status');
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleOpenModal = (candidate) => {
    if (!isObjectEmpty(candidate)) {
      setCandidateId(candidate.appliedJobs.applicantId);
      setAppliedJobId(candidate.appliedJobs.jobId);
    }
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div id="wrapper">
        <EmployerSidebar sidebarExpanded={sidebarExpanded} />
        <div id="navbar-wrapper">
          <EmployerNavBar />
        </div>
        <div className="candidate-dashboard">
          <div className="header">
            <h4>Candidates</h4>
            <Button variant="primary" className='btn-color-primary'>Post a job</Button>
          </div>
          <Tabs activeKey={activeTab} onSelect={handleTabSelect} className="mb-3">
            <Tab eventKey="active" title={`Active (${candidatesData.active.length})`} />
            <Tab eventKey="awaitingReview" title={`Awaiting Review (${candidatesData.awaitingReview.length})`} />
            <Tab eventKey="shortlist" title={`Shortlist (${candidatesData.shortlist.length})`} />
            <Tab eventKey="reviewed" title={`Reviewed (${candidatesData.reviewed.length})`} />
            <Tab eventKey="contacting" title={`Contacting (${candidatesData.contacting.length})`} />
            <Tab eventKey="rejected" title={`Rejected (${candidatesData.rejected.length})`} />
            <Tab eventKey="hired" title={`Hired (${candidatesData.hired.length})`} />
          </Tabs>

          <div className="candidate-filters">
            <Form.Select className="sort-dropdown">
              <option>Sort by: Apply date (newest first)</option>
              <option>Sort by: Apply date (oldest first)</option>
              <option>Sort by: Name (A-Z)</option>
            </Form.Select>
            <Button variant="link" className="clear-btn">Clear all</Button>
          </div>

          {candidatesData[activeTab]?.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th><Form.Check type="checkbox" /></th>
                  <th>Candidate</th>
                  <th>Job applied to</th>
                  <th>Matches to job post</th>
                  <th>Activity</th>
                  <th>Resume</th>
                  <th>Interested?</th>
                </tr>
              </thead>
              <tbody>
                {candidatesData[activeTab].map((candidate) => (
                  <tr key={candidate.appliedJobs._id}>
                    <td><Form.Check type="checkbox" /></td>
                    <td>
                      <div className="candidate-name">
                        <span className="name">{candidate.candidate?.firstName || candidate.candidate?.email}</span>
                        <span className="status">{candidate.appliedJobs.applicationStatus}</span>
                        <span className="date">Applied: {new Date(candidate.appliedJobs.applicationDate).toLocaleDateString()}</span>
                      </div>
                    </td>
                    <td>{candidate.jobDetails.jobTitle}</td>
                    <td>We didn't find matching qualifications.</td>
                    <td>New applicant</td>
                    <td>
                      <Button className="btn-color-primary" onClick={() => downloadFile(`http://localhost:3030${candidate.appliedJobs.resumePath}`)}>
                        <FaFilePdf />
                      </Button>
                    </td>
                    <td>
                      <InterestedButtons
                        key={candidate.appliedJobs._id}  
                        onSuccess={(newStatus) => handleInterestedClick(candidate.appliedJobs._id, newStatus)}
                        onMaybe={(newStatus) => handleInterestedClick(candidate.appliedJobs._id, newStatus)}
                        onRemove={() => handleInterestedClick(candidate.appliedJobs._id, 'remove')}
                        onHire={() => handleInterestedClick(candidate.appliedJobs._id, 'hired')}
                        onScheduleInterview={() => handleOpenModal(candidate)}
                        candidate={candidate}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No candidates available for this stage.</p>
          )}
        </div>
      </div>
      <InterviewScheduler
        showModal={showModal}
        handleScheduleInterview={handleScheduleInterview}
        handleCloseModal={handleCloseModal}
      />

     </>
  );
};

const downloadFile = (url) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'resume.pdf');
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export default CandidateDashboard;
