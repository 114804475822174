import React from "react";
import {
  Container,
  Row,
  Col,
  ProgressBar,
  Card,
  Button,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import { BsPencil, BsTrash } from "react-icons/bs";
import BuildInResumePageFooter from "../../../components/BuildInResumePageFooter";
import "../../../styles/ThemeColors.css";

function ReviewEducation({ onEditClick, onContinue }) {

  const navigate = useNavigate()
  const handleContinue = () => {
    navigate('/resume/resumeOverview')
  };
  const handleEditClick = () => {
    onEditClick();
  };
  return (
    <Container className="mt-5">
      {/* Center the form using Bootstrap grid */}
      <Row className="justify-content-center">
        <Col xs={12} sm={12} md={12} lg={6}>
          {/* Card-like styling */}
          <div className="card p-4">
            {/* Back Arrow and Save & Exit Link */}
            <Row>
              <Col xs="auto">
                <a href="/" className="btn btn-link text-colors">
                  <i className="bi bi-arrow-left"></i> Back
                </a>
              </Col>
              <Col className="text-end">
                <a href="/" className="btn btn-link text-colors">
                  Save & Exit
                </a>
              </Col>
            </Row>

            {/* Progress Bar */}
            <Row className="mt-3">
              <Col>
                <ProgressBar animated now={75} label="75%" />
              </Col>
            </Row>

            {/* Title */}
            <Row className="mt-4">
              <Col>
                <h2>Review education</h2>
              </Col>
            </Row>

            {/* Card view */}
            <Row className="mt-4">
              <Col>
                <Card>
                  <Card.Body className="position-relative">
                    {/* Edit and Delete Icons */}
                    <div
                      className="position-absolute top-0 end-0 m-2"
                      onClick={handleEditClick}
                    >
                      <BsPencil size={20} className="me-2" />
                      <BsTrash size={20} />
                    </div>
                    <Card.Title>
                      Bachelor's degree in Computer Science
                    </Card.Title>
                    <Card.Text>
                      Government College Of Engineering - Villupuram, Tamil Nadu
                      <br />
                      April 2006 to November 2024
                    </Card.Text>
                   
                  </Card.Body>
                </Card>
                <div>
                <Button
                      variant="primary"
                      as={Link}
                      to="/resume/educationDetails"
                      type="submit"
                      className="mt-3 btn-colors"
                       
                    >
                      Add another education
                 </Button>
                </div>                
                 <Button
                      variant="primary"
                      type="submit"
                      className="mt-3 btn-colors"
                      onClick={handleContinue}
                    >
                      Continue
                    </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <div>
        <BuildInResumePageFooter />
      </div>
    </Container>
  );
}

export default ReviewEducation;
