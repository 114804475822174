import { useRef, useState, useEffect } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Button, Form } from "react-bootstrap";
import setCanvasPreview from "./setCanvasPreview";

const ImageCropper = ({ file, updateAvatar, closeModal, editableImage }) => {
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [imgSrc, setImgSrc] = useState("");
  const [crop, setCrop] = useState({
    unit: "px",
    aspect: editableImage === 'coverImage' ? 556 / 266 : 1,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [scale, setScale] = useState(1);

  useEffect(() => {
    const reader = new FileReader();
    reader.addEventListener("load", () => setImgSrc(reader.result.toString()));
    reader.readAsDataURL(file);
  }, [file]);

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    const aspect = editableImage === 'coverImage' ? 556 / 266 : 1;
    const crop = centerCrop(
      makeAspectCrop(
        {
          unit: "px",
          width: editableImage === 'coverImage' ? 556 : 150,
        },
        aspect,
        width,
        height
      ),
      width,
      height
    );
    setCrop(crop);
    setCompletedCrop(crop);
  };

  const saveCroppedImage = () => {
    if (!completedCrop || !imgRef.current || !previewCanvasRef.current) return;
    setCanvasPreview(
      imgRef.current,
      previewCanvasRef.current,
      completedCrop,
      scale
    );
    const dataUrl = previewCanvasRef.current.toDataURL();
    updateAvatar(dataUrl);
    closeModal();
  };

  return (
    <>
      <div className="d-flex flex-column align-items-center">
        <ReactCrop
          crop={crop}
          onChange={(newCrop) => setCrop(newCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={editableImage === 'coverImage' ? 556 / 266 : 1}
        >
          <img
            ref={imgRef}
            src={imgSrc}
            alt="Crop"
            onLoad={onImageLoad}
            style={{ maxHeight: "100vh", transform: `scale(${scale})` }}
          />
        </ReactCrop>
        <Form.Label className="mt-3">Zoom</Form.Label>
        <Form.Range
          value={scale}
          onChange={(e) => setScale(e.target.value)}
          min="1"
          max="3"
          step="0.1"
        />
        <Button
          className="font-mono py-2 px-4 rounded-2xl mt-4 bg-sky-500 hover:bg-sky-600"
          onClick={saveCroppedImage}
        >
          Set Image
        </Button>
      </div>
      {completedCrop && (
        <canvas
          ref={previewCanvasRef}
          style={{
            display: "none",
            border: "1px solid black",
            objectFit: "contain",
            width: completedCrop.width,
            height: completedCrop.height,
          }}
        />
      )}
    </>
  );
};

export default ImageCropper;
