
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { getTokenValue } from "../../helpers/utility";
import { getUser } from "../../slice/auth/login/thunk";
import { useProfile } from "../Hooks/UseProfile";

const SessionCheck = (props) => {

    const { token } = useProfile();
    const { user } = useSelector(state => ({
        user: state.Login.user,
      }));
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isSessionExpired, setSessionExpired] = useState(false);

    useEffect(() => {
        const checkTokenExpiration = () => {
            if (!token) {
                return;
            }
           
            var loggedInUser = getTokenValue(token);
            if (loggedInUser.exp && Date.now() > loggedInUser.exp * 1000) {
                setSessionExpired(true)
            }
            else {
                setSessionExpired(false)
            }
        }
        checkTokenExpiration();
        const intervalId = setInterval(checkTokenExpiration, 1000);
        return () => clearInterval(intervalId);
    }, [token])


    useEffect(() => {

        if (!token) {
            return;
        }
        const userCheck = () => {
             if (token) {                
                if (Object.keys(user).length === 0) {
                     dispatch(getUser());
                }
                else
                {
                    return;
                }
            }
        }
        userCheck();
        // const intervalId = setInterval(userCheck, 100);
        // return () => clearInterval(intervalId);
    }, [user]);

    const handleButtonClick = () => {
        navigate('/signUp');
    }

    return (
        <>
            {isSessionExpired ? (
                <div>
                    <p>Session has expired. Please log in again.</p>
                    <Button onClick={handleButtonClick}>Login</Button>
                </div>
            ) : (
                <>{props.children}</>
            )}
        </>
    )
}
export default SessionCheck;