import React, { useState } from 'react';
import { FaArrowRight } from "react-icons/fa";
import OTPInput from "react-otp-input";
import { useDispatch } from "react-redux";

import { Button, Col, Container, Row } from 'reactstrap';
import Swal from 'sweetalert2';

import { jobSeekerVerifyOtp, verify2FA } from '../../helpers/backendHelper';
import { empolyerLoginWithOtp, jobSeekerLoginWithOtp } from '../../slice/auth/login/thunk';
import "../../styles/Main.css";

const PinVerify = (props) => {

    const { emailorMobileNumber, type, onComplete, userType, firebaseSignInValue } = props;
    const [otp, setOtp] = useState('');
    const dispatch = useDispatch();
    let query = {}
    if (emailorMobileNumber.email) {
        query = { email: emailorMobileNumber.email, otp: otp, userType: userType }
    } else if (emailorMobileNumber.mobileNumber) {
        query = { mobileNumber: emailorMobileNumber.mobileNumber, otp: otp, userType: userType }
    }

     document.title = props.text;
    console.log('userType',userType,'type',type)
    const verifyOtpButton = async (event) => {

        if (otp.length === 6) {
            // verifu otp
            let confirmationResult = window.confirmationResult;
            await confirmationResult.confirm(otp).then((result) => {
                let user = result.user;
                console.log(user);
            }).catch((error) => {

             throw error;
                //alert('User couldn\'t sign in (bad verification code?)');
            });
        }
    }


    const handleConfirm = async (e) => {

        e.preventDefault();
        if (type === "firebase" && userType === "JobSeeker") {
            console.log('inside the oneTimeOtp', firebaseSignInValue);
            verifyOtpButton()
                .then(() => {
                     const data = {
                        mobileNumber: firebaseSignInValue, 
                    };
                    dispatch(jobSeekerLoginWithOtp(data))
                        .then((x) => {
                            onComplete(); 
                        })
                        .catch((e) => {
                            console.log('error', e);
                            Swal.fire({
                                icon: "error",
                                title: "Invalid PIN",
                                text: "Please enter the correct PIN",
                                showCancelButton: true,
                                showConfirmButton: false,
                                cancelButtonText: "Close"
                            }).then((x) => {
                                if (x.isDismissed) {
                                    
                                }
                            });
                        });
                })
                .catch((error) => {
                    console.error('Error verifying OTP:', error);
                    Swal.fire({
                        icon: "error",
                        title: "Verification Failed",
                        text: "There was an issue verifying your OTP. Please try again.",
                        showCancelButton: true,
                        showConfirmButton: false,
                        cancelButtonText: "Close"
                    });
                })
                .finally(() => {
                   
                });
        }
        else if (type === "oneTimeOtp" && userType === "JobSeeker") {
            dispatch(jobSeekerLoginWithOtp(query)).then((x) => {
                if (x && x.statusCode === 404) {
                    Swal.fire({
                        icon: "error",
                        title: "OTP Error",
                        text: x.message ? x.message : null
                    }).then(() => {

                    });

                } else {
                    onComplete();
                }
            }).catch((e) => {
                console.log('error', e)
                Swal.fire({
                    icon: "error",
                    title: "Invalid PIN",
                    text: "Please enter the correct PINs",
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonText: "Close"
                }).then((x) => {
                    if (x.isDismissed) {
                        // onClose();
                    }
                })
            }).finally(() => {
                // setLoading(false);
            })
        }
        else if (type === "oneTimeOtp" && userType === "Employer") {
            dispatch(empolyerLoginWithOtp(query)).then((x) => {
                if (x && x.statusCode === 404) {
                    Swal.fire({
                        icon: "error",
                        title: "OTP Error",
                        text: x.message ? x.message : null
                    }).then(() => {

                    });

                } else {
                    onComplete();
                }
            }).catch((e) => {
                console.log('error', e)
                Swal.fire({
                    icon: "error",
                    title: "Invalid PIN",
                    text: "Please enter the correct PINs",
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonText: "Close"
                }).then((x) => {
                    if (x.isDismissed) {
                        // onClose();
                    }
                })
            }).finally(() => {
                // setLoading(false);
            })
        }
        else if (type == "twoFa" && userType == "Employer") {
            verify2FA({ code: otp }).then((x) => {
                Swal.fire('success!', '2fa verified successfully', 'success');
                onComplete();
            }).catch((e) => {
                Swal.fire({
                    icon: "error",
                    title: "Invalid PIN",
                    text: "Please enter the correct PINs",
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonText: "Close"
                })
            })
        }
        else {
            jobSeekerVerifyOtp({ code: otp })
                .then((x) => {
                    Swal.fire('verified!', 'otp verified successfully', 'success');
                }).catch((e) => {
                    console.log('error', e)
                    Swal.fire({
                        icon: "error",
                        title: "Invalid PIN",
                        text: "Please enter the correct PINs",
                        showCancelButton: true,
                        showConfirmButton: false,
                        cancelButtonText: "Close"
                    }).then((x) => {
                        if (x.isDismissed) {
                            // onClose();
                        }
                    })
                }).finally(() => {
                    // setLoading(false);
                })
        }
    }

    return (
        <>
            <div className="auth-page-wrapper">
                <div className="auth-page-content">
                    <Container className='otp-position'>
                        <Row className="mt-4 d-flex justify-content-start align-items-center">
                            <Col sm={12}>
                                <div className="d-flex justify-content-start otp-position-content">
                                    <OTPInput
                                        value={otp}
                                        inputStyle="otp-input"
                                        onChange={setOtp}
                                        inputType='password'
                                        numInputs={6}
                                        renderInput={(props) => <input {...props} className='otp-input' />}
                                    />
                                </div>
                            </Col>
                            <Col sm={12}>
                                <div className="d-flex justify-content-start otp-position-btn w-100">
                                    {/* <a href="#" className="btn btn-primary ml-3" onClick={handleConfirm}><strong>{type == 'twoFa'?'verify':'SignIn'}<FaArrowRight /></strong></a> */}

                                    {/* <a href="#" className="btn text-white ml-3" style={{backgroundColor:"#3A95CD"}} onClick={(e) => handleConfirm(e)}>
                                    <strong>{type == 'twoFa ' ? 'verify ' : 'SignIn '}<FaArrowRight /></strong>
                                </a> */}

                                    <Button
                                        className="text-white border border-transparent otp-button"
                                        size='lg'
                                        style={{ backgroundColor: "#3A95CD" }}
                                        onClick={(e) => handleConfirm(e)}
                                    >
                                        <strong>{type === 'twoFa' ? 'Verify ' : 'SignIn '}<FaArrowRight /></strong>
                                    </Button>

                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
};

export default PinVerify;