import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { Container, Card, Button, Row, Col } from "react-bootstrap";
import "../../styles/Myprofile.css";

const Myprofile = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  return (
    <div>
      <Navbar />
      <Container>
        <Row className="justify-content-center mt-lg-5 mt-3">
          <Col md={8} lg={5} xs={12}>
            <Card className="p-4 border-0 border-info site-bg-color border rounded">
              <Card.Body className="text-center">
                <p className="h2 font-weight-bold mb-4 mt-3 text-white">
                  Add a Resume to Searchjobs
                </p>
                <div className="d-flex flex-column mt-3">
                  <label
                    htmlFor="resume-upload"
                    className="btn btn-info mb-3"
                    style={{ width: "100%" }}
                  >
                    <strong>Upload resume</strong>
                    <input
                      id="resume-upload"
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </label>
                  <Button
                    as={Link}
                    to="/resume"
                    className="btn btn-info"
                    style={{ width: "100%" }}
                  >
                    <strong>Build a resume</strong>
                  </Button>
                </div>
                {selectedFile && (
                  <p className="card-text text-info mt-3">
                    Selected file: {selectedFile.name}
                  </p>
                )} 
                <p className="card-text text-info mt-3">
                  By continuing, you agree to receive job opportunities from
                  indeed.
                </p>
              </Card.Body>
              <div className="d-flex justify-content-center mb-3">
                <Link to="#" className="text-decoration-none text-warning">
                  <strong>Skip for Now</strong>
                </Link>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Myprofile;
