import React from "react";
import { Link } from "react-router-dom";
import { Button, Container, Row, Col, Image, Card } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa";
import "../../../styles/ThemeColors.css";

const MyJobsApplied = ({ jobs }) => {
  return (
    <Container>
      {jobs.length > 0 ? (
        jobs.map((job) => (
          <Card key={job._id} className="my-3" style={{ border: "none", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}>
            <Card.Body>
              {/* <Card.Title>{job.jobId.jobBasics.jobTitle}</Card.Title> */}
              <Card.Subtitle className="mb-2 text-muted"><p>Test</p></Card.Subtitle>
              <Row className="mb-3">
                <Col xs={6}>
                  <p>Status: {job.status}</p>
                </Col>
                <Col xs={6} className="text-end">
                  <p>Applied: {new Date(job.applicationDate).toLocaleDateString()}</p>
                </Col>
              </Row>
              <Link to={`/job/${job._id}`} className="btn btn-primary">
                View Details
              </Link>
            </Card.Body>
          </Card>
        ))
      ) : (
        <>
          <Row className="justify-content-center">
            <Col className="text-center">
              <Image
                src="https://media.istockphoto.com/id/913685766/vector/clipboard-with-checklist.jpg?s=612x612&w=0&k=20&c=J5NuNqbu4cIz4HwKG8qc_Y_b6mUPwILzazJF1PqqFGM="
                rounded
                fluid
                style={{ width: "200px" }}
              />
            </Col>
          </Row>
          <Row className="justify-content-center mt-3">
            <Col className="text-center">
              <strong>No applications yet</strong>
            </Col>
          </Row>
          <Row className="justify-content-center mt-3">
            <Col className="text-center">
              <p className="text-secondary">
                Keep track of job applications here.
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center mt-3">
            <Col className="text-center">
              <Link to="/" className="text-decoration-none text-colors">
                <strong>Not seeing applications?</strong>
              </Link>
            </Col>
          </Row>
          <Row className="justify-content-center mt-3">
            <Col className="text-center">
              <Button variant="primary btn-colors">
                <strong>Find jobs</strong>
                <FaArrowRight />
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default MyJobsApplied;
