import React from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import { FaEye, FaUserCheck, FaClipboardCheck, FaTasks } from "react-icons/fa";
import FirstImg from "../../assets/images/job-img-1.jpg";
import SecondImg from "../../assets/images/job-img-2.webp";
import { Link } from "react-router-dom";
import '../../styles/Employers.css';
import "../../styles/ThemeColors.css";
function Employerscontentpage() {
  const topics = [
    {
      icon: <FaEye size={60} color="#007BFF" />,
      heading: "Get more visibility",
      description:
        "Sponsor your job to ensure it gets seen by the right people.",
    },
    {
      icon: <FaUserCheck size={60} color="#28A745" />,
      heading: "Find quality applicants",
      description:
        "List your required skills for the job so relevant candidates apply.",
    },
    {
      icon: <FaClipboardCheck size={60} color="#17A2B8" />,
      heading: "Verify their abilities",
      description:
        "Add screener questions and assessments to test applicants’ skills.",
    },
    {
      icon: <FaTasks size={60} color="#FFC107" />,
      heading: "Organize your candidates",
      description:
        "View and sort CVs, send messages and schedule interviews – all on Indeed.",
    },
  ];
  return (
    <div>
      <Container className="mt-lg-5 mt-3">
        <Row>
          <Col xs={12} md={6}>
            <h1>Let's hire your next </h1>
            <h1>great candidate. Fast.</h1>
            <Col className="mt-lg-5 mt-3">
              <Link to={"/collapsepageview"}>
                <Button className="btn site-btn-color btn-colors btn-lg">Post a free job*</Button>
              </Link>
            </Col>
            <Col className="mt-lg-5 mt-3 mb-3">
              <Link to="/termsandconditions">
                *Terms, conditions, quality standards and usage limits apply.
              </Link>
            </Col>
          </Col>
          <Col xs={12} md={6}>
            <img
              src={SecondImg}
              alt="WE ARE HIRING"
              className="img-fluid rounded"
            />
          </Col>
        </Row>
      </Container>
      {/* Our commitments */}
      <Container className="mt-lg-5 mt-3">
        {" "}
        <Row>
          <Col md={4} className="mb-3">
            <Card className="h-100 p-4 text-center border-0">
              <Card.Title>1</Card.Title>
              <h1>Create your </h1>
              <h1>free account</h1>
              <Card.Body>
                <Card.Text>
                  All you need is your email address to create an account and
                  start building your job post.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-3">
            <Card className="h-100 p-4 text-center border-0">
              <Card.Title>2</Card.Title>
              <h1>Build your</h1>
              <h1> job post</h1>
              <Card.Body>
                <Card.Text>
                  Then just add a title, description, and location to your job
                  post, and you're ready to go.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-3">
            <Card className="h-100 p-4 text-center border-0">
              <Card.Title>3</Card.Title>
              <h1>Post your</h1>
              <h1>job</h1>
              <Card.Body>
                <Card.Text>
                  After you post your job, use our state-of-the-art tools to
                  help you find dream talent.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container className="mt-lg-3 shadow p-lg-5 p-3 rounded">
        <Row>
          <Col xs={12} md={12}>
            {" "}
            <h1>Save time and effort in your recruitment journey.</h1>
            <p className="mt-3">
              Finding the best fit for the job shouldn’t be a full-time job.
              Indeed’s simple and powerful tools let you source, screen and hire
              faster.
            </p>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={12} md={6} className="mb-3">
            <Card className="h-100 p-4 text-center border-0">
              <Card.Body>
                {topics[0].icon}
                <Card.Title className="mt-3 mb-3">
                  {topics[0].heading}
                </Card.Title>
                <Card.Text>{topics[0].description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6} className="mb-3">
            <Card className="h-100 p-4 text-center border-0">
              <Card.Body>
                {topics[1].icon}
                <Card.Title className="mt-3 mb-3">
                  {topics[1].heading}
                </Card.Title>
                <Card.Text>{topics[1].description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6} className="mb-3">
            <Card className="h-100 p-4 text-center border-0">
              <Card.Body>
                {topics[2].icon}
                <Card.Title className="mt-3 mb-3">
                  {topics[2].heading}
                </Card.Title>
                <Card.Text>{topics[2].description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6} className="mb-3">
            <Card className="h-100 p-4 text-center border-0">
              <Card.Body>
                {topics[3].icon}
                <Card.Title className="mt-3 mb-3">
                  {topics[3].heading}
                </Card.Title>
                <Card.Text>{topics[3].description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={12} sm={12} md={2} lg={2} className="mb-2">
            {" "}
            <Button className="btn site-btn-color btn-colors btn-lg employers-mv-btn">Get Started</Button>
          </Col>
          <Col xs={12} sm={8} md={8} lg={8}>
            <p className="text-justify">
              You control your posts 24/7 – edit, add, pause or close them
              whenever you want.<Link to="#">Learn more about posting.</Link>
            </p>
          </Col>
        </Row>
      </Container>
      {/* good company */}
      <Container className="mt-lg-2 mt-2">
        <div className="container p-3">
          <h1 className="text-center">You're in good company.</h1>
          <p className="text-justify text-center mt-2">
            Over 1,60,000 companies use Indeed to hire. See why these fantastic
            companies use us as their platform for hiring dream talent.
          </p>
        </div>
        <Row className="justify-content-center">
          {/* Card */}
          <Col md={4} className="mb-3">
            <Card className="p-4 text-center shdow">
              <Card.Img
                variant="top"
                src="https://d34k7i5akwhqbd.cloudfront.net/allspark/static/images/mcdonalds-e98393.svg"
                alt="McDonald's"
              />
              <Card.Text>
                All you need is your email address to create an account and
                start building your job post.
              </Card.Text>
            </Card>
          </Col>

          <Col md={4} className="mb-3">
            <Card className="p-4 text-center shdow">
              <Card.Img
                variant="top"
                src="https://d34k7i5akwhqbd.cloudfront.net/allspark/static/images/university-of-oxford-300af0.svg"
                alt="McDonald's"
              />
              <Card.Text>
                All you need is your email address to create an account and
                start building your job post.
              </Card.Text>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="p-4 text-center shdow">
              <Card.Img
                variant="top"
                src="https://d34k7i5akwhqbd.cloudfront.net/allspark/static/images/nokia-321ac2.svg"
                alt="McDonald's"
              />
              <Card.Text>
                All you need is your email address to create an account and
                start building your job post.
              </Card.Text>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container className="mt-lg-3 mt-3 p-3">
        <Row>
          <Col md={6} className="mb-2">
            <h2 className="text-justify">
              "Quote We have found Indeed very helpful in finding the right
              candidate for our organization... it saves time and energy of both
              the candidates as well as the recruiter."
            </h2>
            <h2 className="text-bold">Kapdec</h2>
          </Col>
          <Col md={6}>
            <img
              src={FirstImg}
              alt="WE ARE HIRING"
              className="img-fluid rounded"
            />
          </Col>
          <div className="mt-lg-3 mt-3 text-center p-10">
            <h1>Get started in minutes!</h1>
            <Link to={"/collapsepageview"}>
              <Button className="btn btn-lg btn-colors employers-mv-btn">Start Posting</Button>
            </Link>
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default Employerscontentpage;
