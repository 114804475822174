import '../../assets/css/Contact.css';
import React from 'react';
const ContactContent=()=>{
   return(
      <div>
        <div className="Container-fuild contact-content">
            <h2>Contact Us</h2>
            <p>Get in touch with the right people at squarespace.Email a member of our customer care team,marketing team,technical team,HR team.</p>
        </div>
        <div className='contact-box'>
            <div className='box'>
                <div className='box-content'>
                    <i class="bi bi-info-square-fill"></i>
                     <p>Help Center</p>
                </div>  
            </div>
            <div className='box'>
                <div className='box-content'>
                <i class="bi bi-chat-left"></i>
                     <p>PR and Communication</p>
                </div>  
            </div>
            <div className='box'>
                <div className='box-content'>
                <i class="bi bi-person"></i>
                     <p>HR Inquiries</p>
                </div>  
            </div>
            <div className='box'>
                <div className='box-content'>
                <i class="bi bi-lock"></i>
                     <p>Security Escalations</p>
                </div>  
            </div>

        </div>
      </div>

   )
}
export default ContactContent;