import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import AccessibilityPageFooter from "../../components/footerAccessibilityPageFooter";
function Privacysettings() {
  return (
    <div>
      <h1>privacy settings</h1>
      <Container>
        {" "}
        <Row>
          <Col
            xs={8}
            className="border box-shadow border-0 border-top border-bottom p-3"
          >
            <h3>Types of data collected</h3>
            <p className="justify-content-center">
              We collect different types of data depending on how you interact
              with us. This includes, for example, when you're on our site,
              responding to our promotional materials, and using our services to
              help you find a job. For example, we may collect your email
              address and CV information when you create your account. As
              another example, we may collect information about your activity on
              our site, such as the searches you conduct and jobs you apply to.
              For more information on the types of data we collect, see{" "}
              <Link>Section 2</Link> of our Privacy Policy.
            </p>
            <h3>How my data is used and disclosed</h3>
            <p className="justify-content-center">
              Indeed uses data to help people get jobs. How we use and disclose
              your data also depends on how you use our site. We go into much
              greater detail in Sections 4, 5 and 9 of our Privacy Policy
              explaining our use and disclosure of your data, but this can
              include to provide our services to you, to protect you when you
              use our site, and to measure, improve, and promote our services.
            </p>
            <h3>Cookies</h3>
            <p className="justify-content-center">
              Our Cookie Policy explains how we use cookies, web beacons and
              similar technologies, including some offered by third-parties, to
              collect data about you. For more information about our use of
              these technologies and your ability to opt out of them, please
              check out our <Link>Cookie Policy.</Link>
            </p>
            <h3>Accessing and deleting my data</h3>
            <p className="justify-content-center">
              Indeed offers all of its users rights to access and delete their
              data, as detailed in <Link>Section 8</Link> of our Privacy Policy.
              To access or delete your data held by Indeed, just fill out this{" "}
              <Link>form.</Link>
            </p>
            <h3>Hide my CV</h3>
            <p className="justify-content-center">
              You can also set your Indeed CV to 'hidden' by visiting your{" "}
              <Link>CV privacy settings.</Link> For more information on what it
              means to have a 'searchable' or 'hidden' Indeed CV, please visit
              <Link>Section 4</Link> our Privacy Policy.
            </p>
          </Col>
          <Col
            xs={8}
            className="border box-shadow border-0 border-top border-bottom p-3"
          >
            <h3>Calls from Employers on Indeed</h3>
            <p className="justify-content-center">
              You are currently unsubscribed from receiving calls from Indeed
              and employers who use Indeed, including using automated call
              technology, forwarded, or recorded calls. Calls from employers on
              Indeed about your applications will not be forwarded to you. You
              will be subscribed again the next time you apply to a job through
              Indeed.
            </p>
          </Col>
        </Row>
        <div>
          <AccessibilityPageFooter />
        </div>
      </Container>
    </div>
  );
}

export default Privacysettings;
