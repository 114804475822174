// NewLoginNext.js

import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FcGoogle } from "react-icons/fc";
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { useSelector } from "react-redux";
import withRouter from '../../components/common/withRouter';
import { auth } from '../../helpers/firebaseConfig';

import { employerSendOtp, jobSeekerSendOtp } from '../../helpers/backendHelper';
import Swal from 'sweetalert2';
import { Card, Col, Container, Row, Image } from 'react-bootstrap';
import Navbar from "../../components/Navbar";
import Footer from "../../components/footerAccessibilityPageFooter";
import LoginImage from "../../assets/images/on-removebg-preview.svg";

const NewLoginNext = () => {

    document.title = "Authentication";

    const { existingUser, error, loading, errorMsg, email, mobileNumber } = useSelector(state => ({
        existingUser: state.Login.existingUser,
        error: state.Login.error,
        loading: state.Login.loading,
        errorMsg: state.Login.errorMsg,
        email: state.Login.email ? state.Login.email : sessionStorage.getItem('email'),
        mobileNumber: state.Login.mobileNumber ? state.Login.mobileNumber : sessionStorage.getItem('mobileNUmber'),
        userType: state.Login.userType
    }));
    const [confirmation, setConfirmation] = useState({ user: 'kaviraj' });

    const navigate = useNavigate();
    const userType = existingUser ? existingUser.userType : null;
    const isMobileNumber = existingUser.isExistingUser ? existingUser.user.isMobileNumberUser : false;

    const verifyOtp = (event) => {
        let otp = "123456";

        if (otp.length === 6) {
            // verifu otp
            let confirmationResult = window.confirmationResult;
            confirmationResult.confirm(otp).then((result) => {
                // User signed in successfully.
                let user = result.user;
                console.log(user);
                alert('User signed in successfully');
                // ...
            }).catch((error) => {
                // User couldn't sign in (bad verification code?)
                // ...
                alert('User couldn\'t sign in (bad verification code?)');
            });
        }
    }

    // const sendOtp = async () => {
    //     try {

    //         console.log('inside the sendOtp nested try')
    //         const confirmValue = { user: 'gkraj' }
    //         setConfirmation(confirmValue);

    //         console.log('inside the sendOtp')
    //         console.log('inside the sendOtp after mob')
    //         window.recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha", {})
    //         const mobileNumber = '+919345487805';
    //         console.log('phone', mobileNumber)

    //         // const confirmation = await signInWithPhoneNumber(auth,mobileNumber,recaptcha)

    //         // signInWithPhoneNumber(auth, mobileNumber, recaptcha)
    //         //     .then(async(confirmationResult)  => {
    //         //         console.log('confirmationResult', confirmationResult)
    //         //         setConfirmation(confirmationResult);
    //         //         console.log("User signed in successfully:");

    //         //           const verificationCode = "123456";  // Use the test verification code
    //         //          sessionStorage.setItem('confirmationResult', JSON.stringify(confirmationResult));
    //         //     })
    //         let recaptcha = window.recaptchaVerifier;

    //         signInWithPhoneNumber(auth, mobileNumber, recaptcha)
    //             .then((confirmationResult) => {

    //                 console.log('confirmationResult',confirmationResult)
    //                 // SMS sent. Prompt user to type the code from the message, then sign the
    //                 // user in with confirmationResult.confirm(code).
    //                 window.confirmationResult = confirmationResult;
    //             }).catch((error) => {
    //                 // Error; SMS not sent
    //                 console.log(error);
    //             });

    //     } catch (e) {
    //         console.error("error is", e)
    //     }

    // }

const sendOtp = async () => {
  try {
    console.log('inside the sendOtp nested try');
    const confirmValue = { user: 'gkraj' };
    setConfirmation(confirmValue);

    // Make sure the recaptcha element is available in the DOM before initializing
    const recaptchaElement = document.getElementById("recaptcha");
    if (!recaptchaElement) {
      throw new Error("reCAPTCHA element is not available in the DOM");
    }

    console.log('inside the sendOtp after mob');
    
    // Initialize reCAPTCHA
    window.recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha", {})
    const phone = '9345487805'
    const recaptcha = window.recaptchaVerifier;
    const phoneNumber = `${'+91' + mobileNumber}`;
    console.log('phone', phoneNumber);

    // Send OTP
    const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, recaptcha);
    console.log('confirmationResult', confirmationResult);
    window.confirmationResult = confirmationResult; // Store the confirmation result
    setConfirmation(confirmationResult);
    navigate('/auth/verifyOtp', { state: { mobileNumber } })

  } catch (error) {
    console.error('Error sending OTP', error);
  }
};

    const isGoogleDomain = (email) => {
        const domain = email.split('@')[1];
        return domain === 'google.com' || domain === 'gmail.com';
    }

    const getLinkTo = () => {
        if (existingUser.isExistingUser) {
            if (mobileNumber) {
                return '/auth/otp/signIn';
            } else if (isGoogleDomain(email)) {
                return '/auth/otp/signIn';
            } else {
                return '/auth/signIn';
            }
        } else {
            return '/auth/userType';
        }
    };

    const getLinkText = (isSignUpWithPasword) => {
        if (isSignUpWithPasword) {
            return 'Sign in with password';
        }
        if (existingUser.isExistingUser) {
            if (mobileNumber) {
                return 'Sign in with login code';
            } else if (isGoogleDomain(email)) {
                return 'Sign in with login code';
            } else {
                return 'Sign in with password';
            }
        } else {
            return 'Sign up with password';
        }
    };

    const getStateObject = () => {
        if (email && !mobileNumber) {
            return { email: email };
        } else if (mobileNumber) {
            return { mobileNumber: mobileNumber };
        } else {
            return null;
        }
    };

    const handleSignInWithLoginCode = async () => {
        try {
            let payload = {};
            if (email) {
                payload.email = email;
                payload.isAccountVerifiedApi = false;
            } else if (mobileNumber) {
                payload.mobileNumber = mobileNumber;
            }
            if (userType === 'JobSeeker') {


                await jobSeekerSendOtp(payload).catch((e) => {
                    showOtpError();
                });
            } else if (userType === 'Employer') {
                await employerSendOtp({ email: email }).catch((e) => {
                    showOtpError();
                });
            }
        } catch (e) {
            console.error(e);
        }
    };

    const showOtpError = () => {
        Swal.fire({
            icon: "error",
            title: "Invalid PIN",
            text: "Please enter the correct PINs",
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: "Close"
        });
    };

    const handleSignInWithMobileOtp = (e) => {
        e.preventDefault();
        sendOtp();
    };

    const verifyOtpButton  = (event) => {
        let otp ="123456";
        if (otp.length === 6) {
          // verifu otp
          let confirmationResult = window.confirmationResult;
          confirmationResult.confirm(otp).then((result) => {
            // User signed in successfully.
            let user = result.user;
            console.log(user);
            alert('User signed in successfully');
            // ...
          }).catch((error) => {
            // User couldn't sign in (bad verification code?)
            // ...
            alert('User couldn\'t sign in (bad verification code?)');
          });
        }
      }

    const handleSignInWithPassword = () => {
        navigate('/auth/signIn');
    };

    return (
        <>
            <Navbar />
            <div className="d-flex align-items-center signup-bg">
                <Container className='mt-4'>
                    <Row className='reverse-col d-flex justify-content-between align-items-center'>
                        <Col md={6}>
                            <Card className='signup-card text-dark'>
                                <h5>{existingUser.isExistingUser ? 'Welcome Back' : 'Create your Account'}</h5>
                                <p>Keep your account Safe</p>
                                <p>
                                    Continue as <a href='www.email.com'>{email ? email : null}</a>
                                    <Link to='/signUp' className='text-color-dark ms-2' style={{ color: "#3A95CD" }}>(Not You?)</Link>
                                </p>
                                <p>
                                    By creating an account or signing in, you understand and agree to SearchJobs's
                                    <Link className='text-color-dark'> Terms.</Link>
                                    You also acknowledge our <Link className='text-color-dark'> Cookie</Link> and
                                    <Link className='text-color-dark'> Privacy</Link> policies.
                                </p>

                                {existingUser.isExistingUser ? (
                                    isMobileNumber ? (
                                        <div className="text-start mt-2">
                                            <button
                                                className="btn btn-link text-email-color fw-boldest px-0"
                                                style={{ color: '#3A95CD' }}
                                                onClick={handleSignInWithMobileOtp}
                                            >
                                                {getLinkText(false)}
                                            </button>
                                        </div>
                                    ) : isGoogleDomain(email) && (
                                        <div className="text-start mt-2">
                                            <Link
                                                className="text-email-color fw-boldest"
                                                style={{ color: '#3A95CD' }}
                                                to={getLinkTo()}
                                                state={getStateObject()}
                                                onClick={handleSignInWithLoginCode}
                                            >
                                                {getLinkText(false)}
                                            </Link>
                                        </div>
                                    )
                                ) : (
                                    <div className="text-start mt-2">
                                        <Link
                                            className="text-email-color fw-boldest"
                                            style={{ color: '#3A95CD' }}
                                            to={getLinkTo()}
                                            state={getStateObject()}
                                        >
                                            {getLinkText(false)}
                                        </Link>
                                    </div>
                                )}

                                {existingUser.isExistingUser && (
                                    <button
                                        className="btn btn-link m-0 mt-2 text-email-color text-start fw-boldest px-0"
                                        style={{ maxWidth: "165px", color: "#3A95CD" }}
                                        onClick={handleSignInWithPassword}
                                    >
                                        {getLinkText(true)}
                                    </button>
                                )}
                            </Card>
                            <div id="recaptcha"></div>
                        </Col>

                        <Col md={5} className='d-none d-md-block'>
                            <Image className='login-img' src={LoginImage} fluid />
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </>
    );
};

export default withRouter(NewLoginNext);
