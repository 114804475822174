import React from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  ProgressBar,
} from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import BuildInResumePageFooter from "../../../components/BuildInResumePageFooter";
import useFormikValidation from "../../../helpers/FormikValidation";
import { nameValidationSchema } from "../../../helpers/Validation/ResumeValidation";
import { jobSeekerResumeUpdate } from "../../../helpers/backendHelper";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import "../../../styles/ThemeColors.css";

function NameForm({ onContinue }) {

      const navigate = useNavigate()
  const { formik, renderFormField } = useFormikValidation(  
    {
      firstName: '',
      lastName: '',
    },
    nameValidationSchema,
     (values) => {
      if (formik.isValid) {
        jobSeekerResumeUpdate({ firstName:values.firstName,lastName:values.lastName }).then((x) => {
          navigate('/resume/phoneNumber')
      }).catch((e) => {
         console.log('error', e)
         Swal.fire({
             icon: "error",
             title: "Error",
             text: e,
             showCancelButton: true,
             showConfirmButton: false,
             cancelButtonText: "Close"
         }).then((x) => {
             if (x.isDismissed) {
                 // onClose();
             }
         })
     }).finally(() => {
         // setLoading(false);
     })
 }
}    
);

  const handleContinue = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    // You can add validation logic here before continuing
    onContinue();
  };
  return (
    <Container className="mt-5">
      {/* Center the form using Bootstrap grid */}
      <Row className="justify-content-center">
        <Col xs={12} sm={12} md={12} lg={6}>
          {/* Card-like styling */}
          <div className="card p-4">
            {/* Back Arrow and Save & Exit Link */}
            <Row>
              <Col xs="auto">
                <a href="/" className="btn btn-link text-colors">
                  <i className="bi bi-arrow-left"></i> Back
                </a>
              </Col>
              <Col className="text-end">
                <a href="/" className="btn btn-link text-colors">
                  Save & Exit
                </a>
              </Col>
            </Row>

            {/* Progress Bar */}
            <Row className="mt-3">
              <Col>
                <ProgressBar animated now={25} label="25%" />
              </Col>
            </Row>

            {/* Title */}
            <Row className="mt-4">
              <Col>
                <h2>What is your name?</h2>
              </Col>
            </Row>

            {/* Form Inputs */}
            <Row className="mt-4">
              <Col>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group controlId="firstName">
                    {renderFormField('firstName', 'First Name', 'Enter your first name')}
                    {renderFormField('lastName', 'Last Name', 'Enter your last name')}
                  </Form.Group>
                    <Button
                    variant="primary"
                    type="submit"
                    className="mt-3 btn-colors"
                   >
                    Continue
                  </Button>
                </Form>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <div>
        <BuildInResumePageFooter />
      </div>
    </Container>
  );
}

export default NameForm;
