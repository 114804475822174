import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  ProgressBar,
} from "react-bootstrap";
import BuildInResumePageFooter from "../../../components/BuildInResumePageFooter";
import "../../../styles/ThemeColors.css";

function AddWorkExperiance() {
  const handleContinue = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    // You can add validation logic here before continuing
    // onContinue();
  };
  const [isValidCheck, setIsValidCheck] = useState(false);

  const navigate = useNavigate();
  //   const { formik, renderFormField } = useFormikValidation(
  //     {
  //       levelOfEducation: "",
  //     },
  //     educationValidation,

  //     (values) => {
  //       console.log("inside the valies", values);
  //       if (formik.isValid) {
  //         let data = {
  //           education: [
  //             {
  //               levelOfEducation: values.levelOfEducation,
  //             },
  //           ],
  //         };
  //         jobSeekerResumeUpdate(data)
  //           .then((x) => {
  //             navigate("/resume/educationReview");
  //           })
  //           .catch((e) => {
  //             console.log("error", e);
  //             Swal.fire({
  //               icon: "error",
  //               title: "Error",
  //               text: e,
  //               showCancelButton: true,
  //               showConfirmButton: false,
  //               cancelButtonText: "Close",
  //             }).then((x) => {
  //               if (x.isDismissed) {
  //                 // onClose();
  //               }
  //             });
  //           })
  //           .finally(() => {
  //             // setLoading(false);
  //           });
  //       }
  //     }
  //   );
  //   if (isValidCheck && !formik.isValid) {
  //     window.scrollTo({ top: 0, behavior: "smooth" });
  //     setIsValidCheck(false);
  //   }

  //   const handleSubmit = (e) => {
  //     e.preventDefault();

  //     setIsValidCheck(true);
  //     formik.handleSubmit(e);
  //   };

  return (
    <Container className="mt-5">
      {/* Center the form using Bootstrap grid */}
      <Row className="justify-content-center">
        <Col xs={12} sm={12} md={12} lg={6}>
          {/* Card-like styling */}
          <div className="card p-4">
            {/* Back Arrow and Save & Exit Link */}
            <Row>
              <Col xs="auto">
                <a href="/" className="btn btn-link">
                  <i className="bi bi-arrow-left"></i> Back
                </a>
              </Col>
              <Col className="text-end">
                <a href="/" className="btn btn-link">
                  Save & Exit
                </a>
              </Col>
            </Row>

            {/* Progress Bar */}
            <Row className="mt-3">
              <Col>
                <ProgressBar animated now={45} label="45%" />
              </Col>
            </Row>

            {/* Title */}
            <Row className="mt-4">
              <Col>
                <h2>Add Work experience</h2>
              </Col>
            </Row>

            {/* Form Inputs */}
            <Row className="mt-4">
              <Col>
                {/* onSubmit={handleSubmit} */}
                <Form>
                  <Form.Group controlId="levelOfEducation">
                    {/* <Form.Label>Level of education*</Form.Label>
                  <Form.Control type="text" placeholder="" /> */}
                    {/* {renderFormField(
                      "levelOfEducation",
                      "Level Of Education *"
                    )} */}
                  </Form.Group>
                  <Form.Group controlId="fieldOfStudy">
                    <Form.Label>Job title*</Form.Label>
                    <Form.Control type="text" placeholder="" />
                  </Form.Group>
                  <Form.Group controlId="schoolName">
                    <Form.Label>Company</Form.Label>
                    <Form.Control type="text" placeholder="" />
                  </Form.Group>
                  {/* Country Dropdown */}
                  <Form.Group controlId="country">
                    <Form.Label>Country</Form.Label>
                    <Form.Select>
                      <option value="">Select Country</option>
                      {/* Add country options as needed */}
                      <option value="In">India</option>
                      <option value="us">United States</option>
                      <option value="ca">Canada</option>
                      {/* Add more countries as needed */}
                    </Form.Select>
                  </Form.Group>
                  {/* City, State Input */}
                  <Form.Group controlId="cityState">
                    <Form.Label>City, State</Form.Label>
                    <Form.Control type="text" placeholder="" />
                  </Form.Group>
                  {/* Time Period Checkboxes */}
                  <Row className="mb-3">
                    <Col>
                      <Form.Label>Time period</Form.Label>
                      <Form.Check
                        type="checkbox"
                        label="I Currently Work Here"
                        id="currentStudy"
                      />
                    </Col>
                  </Row>
                  {/* From and To Input Boxes */}
                  <Row>
                    <Col>
                      <Form.Group controlId="fromMonthYear">
                        <Form.Label>From</Form.Label>
                        <Form.Control type="month" placeholder="" />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="toMonthYear">
                        <Form.Label>To</Form.Label>
                        <Form.Control type="month" placeholder="" />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6>Description</h6>
                      <Form.Control
                        as="textarea"
                        placeholder="Type your message here..."
                        // value={jobDescription}
                        // onChange={(e) => setJobDescription(e.target.value)}
                      />
                    </div>
                  </Row>

                  {/* Continue and Skip Buttons */}
                  <Row className="mt-3">
                    <Col>
                      <Button variant="primary" type="submit" className="btn-colors">
                        Save
                      </Button>{" "}
                      <Link to="/">Skip</Link>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <div>
        <BuildInResumePageFooter />
      </div>
    </Container>
  );
}

export default AddWorkExperiance;
