import React, { useState, useEffect } from 'react';
import { Button, ButtonGroup, Dropdown } from 'react-bootstrap';
import "../../assets/css/InterestedButtons.css";
 
const InterestedButtons = ({
  onSuccess,
  onMaybe,
  onRemove,
  isContacting,
  onHire,
  onScheduleInterview,
  candidate
}) => {
  const { subApplicationStatus } = candidate.appliedJobs;

  const isShortlisted = subApplicationStatus?.includes('shortlist') && subApplicationStatus?.includes('reviewed');
  const isReviewed = subApplicationStatus?.includes('reviewed') && !subApplicationStatus?.includes('shortlist');

  const handleShortlistClick = () => {
    const newStatus = isShortlisted ? 'awaitingReview' : 'shortlist';
    onSuccess(newStatus);
  };

  const handleMaybeClick = () => {
    const newStatus = isReviewed ? 'awaitingReview' : 'reviewed';
    onMaybe(newStatus);
  };

  return (
    <ButtonGroup>
      {isContacting ? (
        <Button variant="primary" size="sm" onClick={onHire}>
          Hire
        </Button>
      ) : (
        <>
          <Button
            variant={isShortlisted ? "success" : "secondary"}
            size="sm"
            onClick={handleShortlistClick}
          >
            {isShortlisted ? "Shortlisted" : "Add to Shortlist"}
          </Button>

          <Button
            variant={isReviewed ? "warning" : "secondary"}
            size="sm"
            onClick={handleMaybeClick}
          >
            {isReviewed ? "Marked as Maybe" : "Maybe"}
          </Button>

          <Button variant="danger" size="sm" onClick={onRemove}>
            ✖ Remove
          </Button>
        </>
      )}
      <Dropdown as={ButtonGroup}>
        <Dropdown.Toggle split as={CustomToggle} id="dropdown-custom-components" />
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => onScheduleInterview(candidate)}>Schedule Interview</Dropdown.Item>
          <Dropdown.Item href="#/action-2">Option 2</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </ButtonGroup>
  );
};
const CustomToggle = React.forwardRef(({ onClick }, ref) => (
  <Button
    variant="light"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className="three-dot-button"
  >
    &#x2022;&#x2022;&#x2022;
  </Button>
));


export default InterestedButtons;


