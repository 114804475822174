import React from "react";
import Route from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";
import ScrollToTop from "./components/ScrollTop";
function App() {

  
  return (
    <React.Fragment>
      <ScrollToTop />

      <Route />
    </React.Fragment>
  );
}

export default App;
