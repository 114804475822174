import SessionCheck from "../components/common/SessionCheck";
import FooterAccessibilityHomePage from "../footer-related-pages/footerAccessibilityHomePage";
import About from "../pages/About";
import Contact from "../pages/Footer/Contact";
import Feedback from "../pages/Footer/Feedback";
import ITJob from "../pages/Footer/ITJob";
import Jobseeker from '../pages/Footer/Jobseeker';
import NonIt from '../pages/Footer/NonIt';
import Editpswd from "../pages/Forgetpassword/Editpswd";
import Codepsw from '../pages/Forgetpassword/Emailotp';
import ForgetPassword from "../pages/Forgetpassword/ForgetPassword";
import Mobileotp from "../pages/Forgetpassword/Mobileotp";
import Mobilereg from "../pages/Forgetpassword/Mobilereg";
import Home from "../pages/Home";
import JobDetail from "../pages/JobDetail";
import Messages from "../pages/Messages";
import Notification from "../pages/Notification";
import SalaryGuidePage from "../pages/SalaryGuide";
import ViewJobs from "../pages/ViewJobs";
import ViewJobsNew from "../pages/ViewJobsNew";
import CandidateDashboard from "../pages/employers/Candidates/CandidateDashboard";
import Candidates from "../pages/employers/Candidates/Candidates";
import Collapsepageview from "../pages/employers/Collapsepageview";
import JobBasics from "../pages/employers/CreateJobs/JobBasics";
import JobDescription from "../pages/employers/CreateJobs/JobDescription";
import ReviewJobPost from "../pages/employers/CreateJobs/ReviewJobPost";
import SalaryDetails from "../pages/employers/CreateJobs/SalaryDetails";
import CreateJobDetails from "../pages/employers/CreateJobs/jobDetails";
import InterviewPage from "../pages/employers/Interviews/InterviewPage";
import WithoutSigninEmployerspage from "../pages/employers/WithoutSigninEmployerspage";
import EmployerJobPostListedPageSidebarMenu from "../pages/employers/employersjobpostdashboard/EmployerJobPostListedPageSidebarMenu";
import JobapplyHomePage from "../pages/jobapplypages/JobapplyHomePage";
import GoogleLogin from "../pages/login/GoogleLogin";
import NewLogin from "../pages/login/NewLogin";
import NewLoginNext from "../pages/login/NewLoginNext";
import OtpVerification from "../pages/login/OtpVerification";
import SignWithOtp from "../pages/login/SignWithOtp";
import SignWithPassword from "../pages/login/SignWithPassword";
import Signupoption from "../pages/login/SignupOptions";
import TwoFactorVerification from "../pages/login/TwoFactorVerification";
import CreateAccount from "../pages/login/createAccount";
import UserType from "../pages/login/userTypeSelection";
import BuildAnResumeHomePage from "../pages/profile/BuildAnResumeHomePage";
import AddressForm from "../pages/profile/BuildanResumePages/AddressForm";
import BuildanResumeHomePage from "../pages/profile/BuildanResumePages/BuildanResumeOverviewPage";
import EducationDetailsForm from "../pages/profile/BuildanResumePages/EducationDetailsForm";
import PhoneNumberForm from "../pages/profile/BuildanResumePages/PhoneNumberForm";
import ReviewEducation from "../pages/profile/BuildanResumePages/ReviewEducation";
import EmployerProfile from "../pages/profile/Employer";
import Helpcenter from "../pages/profile/Helpcenter";
import JobSeekerProfile from "../pages/profile/JobSeeker/jobSeekerProfile";
import MyReviews from "../pages/profile/MyReviews";
import Myjobs from "../pages/profile/Myjobs";
import Myprofile from "../pages/profile/Myprofile";
import NavbarSettingsSidebar from "../pages/settings/navbarSettingsSidebar";

const authProtectedRoutes = [
   {
    path: "/collapsepageview",
    component: <Collapsepageview name="Collapse" />,
  },
  {
    path: "/employers/jobpostdashbord",
    component: <EmployerJobPostListedPageSidebarMenu />,
  },
  {
    path: "/employer/employerProfile",
    component: <EmployerProfile />

  },
  { path: "auth/home", component: <Home /> },
  { path: "/auth/verify/2FA", component: <TwoFactorVerification /> },
  { path: "/employers/candidates/:id", component: <Candidates /> },
  { path: "/employers/interviews", component: <InterviewPage /> },
  { path: "/sessionCheck", component: <SessionCheck /> },
  { path: "/jobSeeker/profile", component: <JobSeekerProfile /> },
  { path: "/employer/createJobBasics", component: <JobBasics /> },
  { path: "/employer/createJobDetails/:job_id", component: <CreateJobDetails /> },
  { path: "/employer/payDetails/:job_id", component: <SalaryDetails /> },
  {path:"/employer/jobDescription/:job_id",component:<JobDescription />},
  {path:"/employer/jobPostReview/:job_id",component:<ReviewJobPost />},
  {path:"/employer/candidates",component:<CandidateDashboard />},

];
const publicRoutes = [
  { path: "/", component: <Signupoption /> },
  { path: "/GoogleLogin", component: <GoogleLogin /> },
  // { path: "/auth/otp/signin", component: <Login /> },
  { path: "/auth/signup", component: <Signupoption /> },
  { path: "signup", component: <NewLogin /> },
  { path: "/auth/otp/signIn", component: <SignWithOtp /> },
  { path: "/auth/signIn", component: <SignWithPassword /> },
  { path: "/auth", component: <NewLoginNext /> },
  { path: "/auth/userType", component: <UserType /> },
  { path: "/auth/createAccount", component: <CreateAccount /> },
  { path: "/forgetPassword", component: <ForgetPassword /> },
  { path: '/forgetpassword/otp', component: <Codepsw /> },
  { path: '/mobile/otp', component: <Mobileotp /> },
  { path: '/editpassword', component: <Editpswd /> },
  { path: '/tryanotherway', component: <Mobilereg /> },
  { path: "/home", component: <Home /> },
  { path: "/about", component: <About /> },
  { path: "/contact", component: <Contact /> },
  { path: "/Feedback", component: <Feedback /> },
  { path: '/It', component: <ITJob /> },
  { path: '/nonIt', component: <NonIt /> },
  { path: '/jobseeker', component: <Jobseeker /> },
  { path: "/jobs", component: <ViewJobsNew /> },
  { path: "/jobs/:jobTitle/:joblocation/:isSearchFromHome", component: <ViewJobsNew /> },
  { path: "/job/detail/:jobId", component: <JobDetail /> },
  { path: "/job/:jobId/:isNavigateFromHome", component: <ViewJobs /> },
  { path: "/job/:jobTitle/:location/:isSearchFromHome", component: <ViewJobs /> },
  { path: "/sg", component: <SalaryGuidePage /> },
  { path: "/careerGuidepage", component: <careerGuidepage /> },
  { path: "/Accessiblility", component: <FooterAccessibilityHomePage /> },
  { path: "/Notification", component: <Notification /> },
  { path: "/myprofile", component: <Myprofile /> },
  { path: "/myjobs", component: <Myjobs /> },
  { path: "/myreviews", component: <MyReviews /> },
  { path: "/helpcenter", component: <Helpcenter /> },
  { path: "/message", component: <Messages /> },
  { path: "/settings", component: <NavbarSettingsSidebar /> },
  { path: "/employers", component: <WithoutSigninEmployerspage /> },
  { path: "/resume", component: <BuildAnResumeHomePage /> },
  { path: "/applyjob/:jobId", component: <JobapplyHomePage /> },
  { path: "/resume/phapplyjoboneNumber", component: <PhoneNumberForm /> },
  { path: "/resume/addressForm", component: <AddressForm /> },
  { path: "/resume/educationDetails", component: <EducationDetailsForm /> },
  { path: "/resume/educationReview", component: <ReviewEducation /> },
  { path: "/resume/resumeOverview", component: <BuildanResumeHomePage /> },
  { path: "/auth/verifyOtp", component: <OtpVerification /> },


];
export { authProtectedRoutes, publicRoutes };

