import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Jobseekers from "../../helpers/Footerpage/Jobseeker";
const Jobseeker = () => {
  return (
    <div>
      <Navbar />
      <div>
        <Jobseekers />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};
export default Jobseeker;
