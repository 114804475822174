import { useFormik } from 'formik';
import React, { useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Card, Form, FormGroup, Input, InputGroup, InputGroupText, Label } from 'reactstrap';
import * as Yup from 'yup';
import LoginImage from "../../assets/images/on-removebg-preview.svg";
import Navbar from "../../components/Navbar";
import withRouter from "../../components/common/withRouter";
import Footer from "../../components/footerAccessibilityPageFooter";
import { createUsers } from "../../slice/auth/login/thunk";

const CreateAccount = (props) => {

    document.title = "Authentication";

    const navigate = useNavigate()
    const location = useLocation();
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const [userData, setUserData] = useState();
    const [error, setError] = useState('')
    const [userType, setUserType] = useState(location.state && location.state.userType);
    let initialValues = {
        password: '',
    };

    const validationSchema = Yup.object({
        password: Yup.string().required("Please Enter Your Password")
    });
    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            let payload = {
                userType: userType,
                password: values.password
            }
            if (emailOrMobileNumber.email) {
                payload.email = emailOrMobileNumber.email;
            } else if (emailOrMobileNumber.mobileNumber) {
                payload.mobileNumber = emailOrMobileNumber.mobileNumber;
            } else {
                return;
            }
            try {
                const usersData = dispatch(createUsers(payload, props.router.navigate))
                setUserData(usersData.data);
                console.log('userType',userType)
                let path = ''
                if (userType == 'JobSeeker') {
                    path = '/home'
                } else if (userType == 'Employer') {
                    console.log('inside te employer part')
                    path = '/employers/jobpostdashbord'
                } else {
                    console.log('inside te else part')
                    path = '/'
                }
                console.log('path',path)
                navigate(path);
                // Swal.fire('created!', 'login with your email or mobileNumber', 'success');
              
            } catch (error) {
                setError(error.message || 'An error occurred');
            }
        },
    });

    let emailOrMobileNumber = {};
    if (location.state) {
        var { email, mobileNumber } = location.state;
        if (email) {
            emailOrMobileNumber.email = email;
        } else if (mobileNumber) {
            emailOrMobileNumber.mobileNumber = mobileNumber;
        } else {
            return null;
        }
    }

    const handlePasswordChange = (e) => {
        formik.handleChange(e);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleUserType = () => {
        setUserType(userType === 'Employer' ? 'JobSeeker' : 'Employer');
    };

    return (
        <>
            <Navbar />
            <div class="d-flex align-items-center signup-bg">
                <Container className='mt-4'>
                    <Row className='reverse-col d-flex justify-content-between align-items-center'>
                        <Col md={6}>
                            <Card className='signup-card text-dark'>
                                <h4>Create your <span>{userType === 'Employer' ? 'Employer' : ''}</span> account</h4>

                                <p className="mt-3">
                                    Signing up as {email ? email : mobileNumber}<Link to="#" className="text-email-color ms-2" style={{color: "#3A95CD"}}>(Not You?)</Link>
                                </p>
                                <Form onSubmit={formik.handleSubmit} className="mt-3">
                                    <FormGroup>
                                        <Label for="password">Password *</Label>
                                        <InputGroup>
                                            <Input
                                                type={showPassword ? "text" : "password"}
                                                id="password"
                                                name="password"
                                                value={formik.values.password}
                                                onChange={handlePasswordChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <InputGroupText
                                                onClick={togglePasswordVisibility}
                                                style={{ cursor: "pointer" }}
                                            >
                                                {showPassword ? "👁️" : "👁️‍🗨️"}
                                            </InputGroupText>
                                        </InputGroup>
                                        {formik.touched.password && formik.errors.password ? (
                                            <div className="text-danger">{formik.errors.password}</div>
                                        ) : null}
                                    </FormGroup>
                                    <p>
                                        By creating an account or signing in, you understand and agree to Indeed's Terms. You also acknowledge our
                                        Cookie and Privacy policies. You will receive marketing messages from Indeed and may opt out at any time by
                                        following the unsubscribe link in our messages, or as detailed in our terms
                                    </p>
                                    <div className="d-flex justify-content-center mt-3">
                                        <Button className="btn-color-primary py-2 w-100" type="submit">Create Account</Button>
                                    </div>
                                </Form>
                                <div className="d-flex justify-content-center mt-3">
                                    <Link onClick={toggleUserType} className="text-decoration-none">
                                        <strong className="text-email-color text-dark">Wait, I am a {userType == 'Employer' ? 'JobSeeker' : 'Employer'} <FaArrowRight /></strong>
                                    </Link>
                                </div>
                            </Card>
                        </Col>

                        <Col md={5} className='d-none d-md-block'>
                            <Image className="login-img" src={LoginImage} fluid/>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </>
    );
};

export default withRouter(CreateAccount);