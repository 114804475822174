import React, { useEffect, useState } from 'react';
import withRouter from '../../components/common/withRouter';

import { Card, Col, Container, Image, Row } from 'react-bootstrap';
import LoginImage from "../../assets/images/on-removebg-preview.svg";
import Navbar from "../../components/Navbar";
import Footer from "../../components/footerAccessibilityPageFooter";
import { useLocation, useNavigate } from 'react-router-dom';
import PinVerify from '../../components/common/PinVerify';
import { useSelector } from 'react-redux';


function OtpVerification() {


    document.title = "Authentication";

    const { existingUser, error, loading, errorMsg, email } = useSelector(state => ({
  
        existingUser: state.Login.existingUser,
        error: state.Login.error,
        loading: state.Login.loading,
        errorMsg: state.Login.errorMsg,
        email: state.Login.email ? state.Login.email : sessionStorage.getItem('email'),
        mobileNumber: state.Login.mobileNumber ? state.Login.mobileNumber : sessionStorage.getItem('mobileNUmber'),
        userType: state.Login.userType
    }));
    
    const userType = existingUser && existingUser.userType ? existingUser.userType : null


    const navigate = useNavigate();
    const location = useLocation();
    const mobileNumber = location.state?.mobileNumber; 

    console.log('mobileNumber otp verification',mobileNumber)
    // const [signInValue,setSignInvalue] = useState('');

    const signInValue = sessionStorage.getItem('confirmationResult');
    
    const onComplete = () => {
            console.log('onComplete..',userType)
        let path = ''
        if (userType == 'JobSeeker') {
            console.log('inside the jobSeeker')
            path = '/auth/home'
        } else if (userType == 'Employer') {
            const isTwoFaEnable = existingUser && existingUser.user.isTwoFAEnabled
            console.log('existingUser', existingUser)

            console.log('isTwoFaEnable', isTwoFaEnable)
            if (isTwoFaEnable) {
                path = '/auth/verify/2FA'
            }
            else {
                path = '/employers/jobpostdashbord'
            }

        } else {
            path = '/'
        }
        navigate(path);


    }
  return (
    <>
         <Navbar />
            <div class="d-flex align-items-center signup-bg">
                <Container className='mt-4'>
                    <Row className='reverse-col d-flex justify-content-between align-items-center'>
                        <Col md={6} lg={5} >
                            <Card className='signup-card text-dark'>
                                <h4><strong>Sign In With Login Code</strong></h4>
                                {/* <p class="mt-2">We've sent your one-time passcode to <a href="www.gmail.com" className='text-color-dark'>{location.state.email ? location.state.email:""}</a>. This passcode will expire after 5 minutes.</p> */}
                                <p class="mt-2"><strong>Enter 6-digit code*</strong></p>
                                <PinVerify
                                    className="border border-info"
                                    emailorMobileNumber={mobileNumber}
                                    type="oneTimeOtp"
                                    userType={userType}
                                    onComplete={onComplete}
                                    firebaseSignInValue={mobileNumber}
                                />
                            </Card>
                           
                        </Col>
                        
                        <Col md={6} lg={5} className='d-none d-md-block'>
                            <Image className="login-img" src={LoginImage} fluid/>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </>
  
  );
}

 export default withRouter(OtpVerification);
