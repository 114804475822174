import React from 'react';
import { FaArrowRight } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { RiAppleFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';

import { useFormik } from "formik";
import { Spinner } from 'reactstrap';
import * as Yup from "yup";
import withRouter from '../../components/common/withRouter';
import { existingUserCheck } from '../../slice/auth/login/thunk';
import { Card, Col, Container, Row, Image } from 'react-bootstrap';
import '../../styles/login.css'
import Navbar from "../../components/Navbar";
import Footer from "../../components/footerAccessibilityPageFooter";
import LoginImage from "../../assets/images/on-removebg-preview.svg";

const NewLogin = (props) => {

    document.title = "Authentication";

    const { loading } = useSelector(state => ({
        loading: state.Login.loading,
    }));
    const dispatch = useDispatch();
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            emailorMobileNumber: '',
        },
        validationSchema: Yup.object({
            emailorMobileNumber: Yup.string()
                .test(
                    'is-email-or-mobile',
                    'Invalid email or mobile number',
                    (value) =>
                        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || /^[0-9]{10}$/.test(value)
                )
                .required('Please enter a valid email or mobile number'),

        }),
        onSubmit: (values) => {
            const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.emailorMobileNumber);
            const isPhoneNumber = /^[0-9]{10}$/.test(values.emailorMobileNumber);
            let payload = {}
             if (isEmail) {
                payload = { 
                    email: values.emailorMobileNumber,
                };
                sessionStorage.setItem('email',values.emailorMobileNumber)
            } else if (isPhoneNumber) {
                payload = {
                    mobileNumber: values.emailorMobileNumber,
                };
                sessionStorage.setItem('mobileNumber', values.emailorMobileNumber)
            } else {
                console.log('Invalid input');
            }

            const data = dispatch(existingUserCheck(payload, props.router.navigate))
        }
    });

    return (
        <>
            <Navbar />

            <div class="d-flex align-items-center signup-bg">

                <Container className='my-5'>
                    <Row className='reverse-col d-flex justify-content-between align-items-center'>
                        <Col md={5}>
                            <Card className='signup-card text-dark'>
                                <h4 className='fw-bold'>Sign in to Walkup</h4>
                                <p className='fw-bold'>Login by Your Secure Account</p>


                                <form onSubmit={validation.handleSubmit}>
                                    {/* <p className="card-text text-secondary">
                        If using a phone number, it must be registered with a WhatsApp account.
                    </p> */}
                                    <input
                                        type="text"
                                        className={`form-control mt-3 mb-2  py-2 input-height ${validation.touched.emailorMobileNumber && validation.errors.emailorMobileNumber ? 'is-invalid' : ''
                                            }`}
                                        placeholder="@ Username, Email or Phone Number"
                                        {...validation.getFieldProps('emailorMobileNumber')}
                                    />

                                    {validation.touched.emailorMobileNumber && validation.errors.emailorMobileNumber ? (
                                        <div className="invalid-feedback">{validation.errors.emailorMobileNumber}</div>
                                    ) : null}
                                    <p className='text-dark'>Forget Password ? <span className='ms-2'><Link to="/forgetPassword" className='text-decoration-none text-dark fw-bold'> Click here </Link></span></p>
                                    <button type="submit" className="btn btn-color-primary input-height w-100 py-2 mt-3" disabled={loading}>
                                        <strong>
                                            {loading ? (
                                                <Spinner size="sm" className='me-2'> Loading... </Spinner>
                                            ) : (
                                                <>Continue</>
                                            )}
                                        </strong>
                                    </button>
                                    <p className='mt-3 text-dark'>Questions? Email us at <span className='ms-2 text-white'><a href="https://mail.google.com" target='/' className='text-dark'><strong>support@walkup.com</strong></a></span></p>
                                </form>
                            </Card>
                        </Col>
                        <Col md={5} className='d-none d-md-block'>
                            <Image className='login-img' src={LoginImage} fluid />
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </>
    )
}

export default withRouter(NewLogin)