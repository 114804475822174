import { useFormik } from "formik";
import React from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import pswimg from "../../assets/images/psw-flow1.svg";
import Navbar from "../../components/Navbar";
import Footer from "../../components/footerAccessibilityPageFooter";
import "../../styles/Forgetpsw.css";

const Mobilereg = () => {
  const navigate = useNavigate();
  
  const formik = useFormik({
    initialValues: {
      mobilenumber: "",
    },
    validationSchema: Yup.object({
      mobilenumber: Yup.string()
        .required("Mobile number is required")
        .matches(/^[0-9]{10}$/, "Mobile number is not valid"),
    }),
    onSubmit: (values) => {
      navigate('/mobile/otp',{state:{mobilenumber:values.mobilenumber}});
    },
  });

  return (
    <>
      <Navbar />
      <div className="d-flex align-items-center pswd">
        <Container className="mt-5">
          <Row className="pswd-row d-flex justify-content-between align-items-center">
            <Col md={6} lg={5} >
              <Card className="pswd-content">
                <h4 className="fw-bold">Enter Your Registered Mobile Number</h4>
                <p className="p-title">
                  Enter the mobile number associated with your account
                </p>
                <form onSubmit={formik.handleSubmit}>
                  <input
                    type="text"
                    placeholder="Enter your Mobile number"
                    id="mobilenumber"
                    name="mobilenumber"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.mobilenumber}
                    className={`form-control mt-3 mb-2 py-2 input-height ${formik.touched.mobilenumber && formik.errors.mobilenumber ? 'is-invalid' : ''}`}
                  />
                  {formik.touched.mobilenumber && formik.errors.mobilenumber ? (
                    <div className="invalid-feedback">{formik.errors.mobilenumber}</div>
                  ) : null}
                  <button
                    type="submit"
                    className="btn btn-color-primary input-height w-100 py-2 mt-3"
                  >
                    <strong>Continue</strong>
                  </button>
                </form>
              </Card>
            </Col>
            <Col md={5}className="d-none d-md-block" >
              <Image className="pswd-img" src={pswimg} alt="img" fluid />
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Mobilereg;
