import { Image,Button } from "react-bootstrap";
import React from "react";
import "../../assets/css/Learn.css";
import SliderpeopleImage from "../../assets/images/Slider-people.webp";
import ReadImage from "../../assets/images/Read.webp";
import Slider1Image from "../../assets/images/Slider-img1.webp";
import Slider2Image from "../../assets/images/Slider-img2.webp";
import Slider3Image from "../../assets/images/Slider-img3.webp";
import Slider4Image from "../../assets/images/Slider-img4.webp";
import Slider5Image from "../../assets/images/Slider-img5.webp";
import Slider6Image from "../../assets/images/Slider-img6.webp";
import Slider7Image from "../../assets/images/Slider-img7.webp";

export default function Learn(){
    return(<div className="main-learn">
        <div className="learn">
            <div className="learn-content">
                <h2 className="tagh">Learn Everyday And Any New Skills Online With Top Instructor.</h2>
                <p className="tagp tagpara">Choose Your Favorite Course And Start Learning Today.</p>

                <div className="btndiv">
                    <Button className="btns">Explore Courses</Button>
                    <Button className="btns">Become An Instructor</Button>
                </div>

                <div className="bord">
                    <div className="inside-bord1 mt-2">
                        <Image className="imgpeople" src={SliderpeopleImage} />
                        <div className="like">
                            <p className="tagp">More Than <br></br> 1k+ Tutors</p>
                        </div>
                    </div>
                    <div className="inside-bord2 mt-2 ">
                        <Image className="imgread" src={ReadImage} />
                        <div className="certified">
                            <p className="tagp"><span className="nums">239+</span> <br></br>Certified Teachers</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ims">
                <Image className="imgs img1" src={Slider1Image} />
                <Image className="imgs img2" src={Slider2Image} />
                <Image className="imgs img3" src={Slider3Image} />
                <Image className="imgs img4" src={Slider4Image} />
                <Image className="imgs img5" src={Slider5Image} />
                <Image className="imgs img6" src={Slider6Image} />
                <Image className="imgs img7" src={Slider7Image} />
                
            </div>
        
        </div>
    </div>)
}