import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import Image from "react-bootstrap/Image";
import 'react-image-crop/dist/ReactCrop.css';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import userImage from "../../../assets/images/user.webp";
import NavbarComponent from '../../../components/Navbar';
import CustomProfileModal from '../../../components/common/CustomProfileModal';
import { getCityState, getJobSeeker, jobSeekerImageUpdate, jobSeekerProfileUpdate } from '../../../helpers/backendHelper';
import * as url from "../../../helpers/urlHelper";
import "../../../styles/Myprofile.css";

const cities = [
    'New York, NY',
    'Los Angeles, CA',
    'Chicago, IL',
    'Houston, TX',
    'Phoenix, AZ',
    'Philadelphia, PA',
    'San Antonio, TX',
    'San Diego, CA',
    'Dallas, TX',
    'San Jose, CA'
];

const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    mobileNumber: Yup.string().required('Mobile Number is required'),
    cityState: Yup.string().required('City State is required'),
    pinCode: Yup.string().required('Pin Code is required'),
    comment: Yup.string()
});

const JobSeekerProfile = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);
    const [dummyImage, setDummyImage] = useState(userImage);
    const [userData, setUserData] = useState({
        firstName: "",
        email: "",
        mobileNumber: "",
        cityState: "",
        pinCode: "",
        country: "",
        comment: "",
        isProfileUpdate: false,
        jobSeekerProfileImage: userImage,
        jobSeekerCoverImage: userImage
    });
    const [editMode, setEditMode] = useState(false);
    const [activeTab, setActiveTab] = useState('1');
    const [isTwoFAEnabled, setIsTwoFAEnabled] = useState(false);
    const [isSetup2Fa, setIsSetup2Fa] = useState(false);
    const [allCityState, setAllCityState] = useState([]);
    const [value, setValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [editableImage, setEditableImage] = useState();

    useEffect(() => {
        fetchUserData();
    }, [editMode]);

    useEffect(() => {
        fetchCityState();
    }, []);

    const fetchCityState = async () => {
        try {
            const response = await getCityState();
            if (response && response.statusCode === 200) {
                const data = response.cities;
                setAllCityState(data);
            }
        } catch (error) {
            console.error("Error fetching city/state data:", error);
        }
    };

    const updateAvatar = async (imgSrc) => {
        const base64ToFile = (base64String, fileName) => {
            const arr = base64String.split(',');
            const mime = arr[0].match(/:(.*?);/)[1];
            const bstr = atob(arr[1]);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], fileName, { type: mime });
        };

        const formData = new FormData();
        const file = base64ToFile(imgSrc, `${editableImage}.png`);
        formData.append(editableImage, file);

        try {
            await jobSeekerImageUpdate(formData);
            fetchUserData();
        } catch (error) {
            console.error("Error updating avatar:", error);
        }
    };

    const fetchUserData = async () => {
        try {
            const response = await getJobSeeker();
            if (response && response.statusCode === 200) {
                const data = response.jobseeker;
                setUserData(data);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

    const toggleTab = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const handleEdit = () => {
        setEditMode(!editMode);
    };

    const handleChangeTwoFA = () => {
        setIsSetup2Fa(!isSetup2Fa);
    };

    const handleClose2Fa = () => {
        setIsSetup2Fa(false);
    };

    const handleSave = async (values, { setSubmitting }) => {
        try {
            const payload = {
                email: values.email,
                mobileNumber: values.mobileNumber || "null",
                firstName: values.firstName,
                cityState: values.cityState,
                pinCode: values.pinCode,
                country: values.country,
                comment: values.comment
            };
            console.log("payload",payload);
            const updateData = await jobSeekerProfileUpdate(payload);
            console.log("updateData",updateData);
            if (updateData && updateData.statusCode === 200) {
                localStorage.setItem("isProfileUpdated", "true");
                Swal.fire('Updated!', 'Profile updated successfully', 'success');
                navigate('/jobs');
            }
            setEditMode(false);
        } catch (error) {
            console.error("Error updating profile:", error);
        }
        setSubmitting(false);
    };

    const handleCamaraClick = (value) => {
        setEditableImage(value);
        setModalOpen(true);
    };

    const getSuggestions = (inputValue) => {
        return allCityState.filter(city =>
            city.toLowerCase().includes(inputValue.trim().toLowerCase())
        );
    };

    return (
        <>
            <NavbarComponent />
            <Container className='mt-lg-4 mt-3'>
                <Row>
                    {modalOpen && (
                        <CustomProfileModal
                            updateAvatar={updateAvatar}
                            closeModal={() => setModalOpen(false)}
                            showModal={modalOpen}
                            editableImage={editableImage}
                        />
                    )}
                    <Col md={6}>
                        <Card className="bg-white border-0">
                            <div className="rounded-3 mb-4 position-relative bg-white rounded">
                                <Image
                                    src={
                                        userData.jobSeekerCoverImage === "null"
                                            ? dummyImage
                                            : `${url.IMAGE_HOST_URL}${userData.jobSeekerCoverImage}`
                                    }
                                    className="img-fluid rounded-3 cover-image"
                                />
                                <span>
                                    <FontAwesomeIcon
                                        icon={faCamera}
                                        size="lg"
                                        className="position-absolute camera-icon"
                                        onClick={() => handleCamaraClick('coverImage')}
                                    />
                                </span>
                                <Image
                                    src={
                                        userData.employerProfileImage === "null"
                                            ? dummyImage
                                            : `${url.IMAGE_HOST_URL}${userData.jobSeekerProfileImage}`
                                    }
                                    className="img-fluid rounded-3 rounded-circle profile-image-circle"
                                />
                                <FontAwesomeIcon
                                    icon={faCamera}
                                    size="lg"
                                    className="camera-icon2 position-absolute"
                                    onClick={() => handleCamaraClick('profileImage')}
                                />
                            </div>
                            <div className="text-center">
                                <h5 className="mt-5">{userData.name}</h5>
                                <p className="text-muted">{userData.employerCity}, {userData.employerCountry}</p>
                            </div>
                            <div className="p-3">
                                <h5>Info</h5>
                                    {   editMode === false ? <p className="text-justify">
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                                            enim ad minim veniam, quis nostrud exercitation ullamco laboris
                                            nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                                            in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                                            nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                                            sunt in culpa qui officia deserunt mollit anim id est laborum.
                                        </p> : <textarea
                                                id="multiLineInput"
                                                rows="4"
                                                placeholder="Add a summary here....."
                                                className="form-control"
                                                style={{ height: '120px', width: '100%' }}
                                                disabled={!editMode}
                                            /> 
                                    }
                                
                            </div>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card className="border-0 bg-white">
                            <CardHeader>
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={activeTab === '1' ? 'active' : ''}
                                            onClick={() => toggleTab('1')}
                                        >
                                            Profile
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </CardHeader>
                            <CardBody>
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1">
                                        <Formik
                                            initialValues={{
                                                firstName: userData.firstName,
                                                email: userData.email,
                                                mobileNumber: userData.mobileNumber,
                                                cityState: userData.cityState,
                                                pinCode: userData.pinCode,
                                                country: userData.country
                                            }}
                                            validationSchema={validationSchema}
                                            onSubmit={handleSave}
                                            enableReinitialize
                                        >
                                            {formik => (
                                                <Form>
                                                    <Row>
                                                        <Col md={12}>
                                                            <FormGroup>
                                                                <Label for="firstName">First Name</Label>
                                                                <Field
                                                                    type="text"
                                                                    name="firstName"
                                                                    id="firstName"
                                                                    className="form-control"
                                                                    disabled={!editMode}
                                                                />
                                                                <ErrorMessage name="firstName" component="div" className="text-danger" />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12}>
                                                            <h5>Address</h5>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label for="country">Country</Label>
                                                                <Field
                                                                    type="text"
                                                                    name="country"
                                                                    id="country"
                                                                    className="form-control"
                                                                    disabled={!editMode}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label for="cityState">City</Label>

                                                                <select
                                                                    id="cityState"
                                                                    name="cityState"
                                                                    className="form-control"
                                                                    onChange={(e) => formik.setFieldValue('cityState', e.target.value)}
                                                                    value={formik.values.cityState}
                                                                    disabled={!editMode}
                                                                >
                                                                    <option value="">Select a city</option>
                                                                    {allCityState.map((cityObj) => (
                                                                        <option key={cityObj._id} value={`${cityObj.city}, ${cityObj.state}`}>
                                                                            {cityObj.city}, {cityObj.state}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label for="pinCode">Pincode</Label>
                                                                <Field
                                                                    type="text"
                                                                    name="pinCode"
                                                                    id="pinCode"
                                                                    className="form-control"
                                                                    disabled={!editMode}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label for="email">Email</Label>
                                                                <Field
                                                                    type="email"
                                                                    name="email"
                                                                    id="email"
                                                                    className="form-control"
                                                                    disabled
                                                                />
                                                                <ErrorMessage name="email" component="div" className="text-danger" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label for="mobileNumber">Phone</Label>
                                                                <Field
                                                                    type="text"
                                                                    name="mobileNumber"
                                                                    id="mobileNumber"
                                                                    className="form-control"
                                                                    disabled={!editMode}
                                                                />
                                                                <ErrorMessage name="mobileNumber" component="div" className="text-danger" />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Button color="primary" onClick={editMode ? formik.handleSubmit : handleEdit}>
                                                        {editMode ? 'Save' : 'Edit'}
                                                    </Button>
                                                </Form>
                                            )}
                                        </Formik>

                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default JobSeekerProfile;

