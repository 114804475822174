import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  FormControl,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar";
import "../../styles/ThemeColors.css";

const Helpcenter = () => {

  document.title = "Help Center";

  return (
    <div>
      <Navbar />
      <Container>
        <Row className="mt-3 justify-content-center">
          <Col xs={12} md={6}>
            <FormControl
              type="text"
              placeholder="Search job seeker how-to-tips"
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
            />
          </Col>
          <Col xs={12} md={2}>
            <Button variant="primary btn-colors">Search</Button>
          </Col>
        </Row>
        <Row className="mt-5">
          {[...Array(6)].map((_, index) => (
            <Col key={index} xs={12} md={6} className="p-3">
              <Card style={{ height: "300px" }}>
                <Card.Img
                  variant="top"
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSYysgUc3DOpNMWq9l99jv7zXn2Hbm3bKsbg&usqp=CAU"
                  className="rounded float-left"
                  style={{ width: "150px" }}
                />
                <Card.Body>
                  <Card.Title className="mb-3">
                    My SearchJobs Account
                  </Card.Title>
                  {[...Array(7)].map((_, i) => (
                    <Link key={i}>giivjsvkbskbdksd</Link>
                  ))}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Helpcenter;
