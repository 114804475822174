import axios from "axios";
import { api } from "../config";

import Swal from "sweetalert2";

axios.defaults.baseURL = api.API_URL_IP;
axios.defaults.headers.post["Content-Type"] = "application/json";
const token = JSON.parse(localStorage.getItem("authUser"))
  // ? JSON.parse(localStorage.getItem("authUser"))
  // : JSON.parse(localStorage.getItem("token"));

const formData = new FormData();
console.log('token',token)
if (token) axios.defaults.headers.common["Authorization"] = "Bearer " + token;
axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  function (error) {
    if (error.response == "undefined") {
      Swal.fire({
        title: "Error Occurred",
        text: message,
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        showConfirmButton: false,
        cancelButtonText: "Close",
        customClass: {
          cancelButton: "order-1 right-gap",
          confirmButton: "order-2",
          denyButton: "order-3",
        },
      });
    }
    let message;
    console.log("error.response", error);
    switch (error.response.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = error.response.data.message;
        break;
      case 400:
        message = error.response.data.message;
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      default:
        message = error.response.status || error.response;
    }
    Swal.fire({
      title: "Error Occurred",
      text: message,
      icon: "error",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: false,
      cancelButtonText: "Close",
      customClass: {
        cancelButton: "order-1 right-gap",
        confirmButton: "order-2",
        denyButton: "order-3",
      },
    });
    return Promise.reject(message);
  }
);

const setAuthorization = (token) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

class APIClient {
  /**
   * Fetches data from given url
   */

  //  get = (url, params) => {
  //   return axios.get(url, params);
  // };
  get = async (url, params) => {
    let response;

    let paramKeys = [];

    if (params) {
      Object.keys(params).map((key) => {
        paramKeys.push(key + "=" + params[key]);
        return paramKeys;
      });

      const queryString =
        paramKeys && paramKeys.length ? paramKeys.join("&") : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }

    return response;
  };
  /**
   * post given data to url
   */
  create = async (url, data) => {
    const results = await axios.post(url, data);
    return results;
  };
  /**
   * Updates data
   */
  update = (url, data) => {
    return axios.patch(url, data);
  };

  put = (url, data) => {
    return axios.put(url, data);
  };
  /**
   * Delete
   */
  delete = (url, config) => {
    return axios.delete(url, { ...config });
  };

  sendFormDataPost = async (url, formData) => {
    const results = await axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return results;
  };
}

const getLoggedinUser = () => {
  const user = localStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};
export { APIClient, setAuthorization, getLoggedinUser };
