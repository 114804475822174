import { useFormik } from 'formik';
import React from 'react';
import { Card, Col, Container, Image, Row } from 'react-bootstrap';
import { FaArrowRight } from 'react-icons/fa';
import { IoArrowBackSharp } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import LoginImage from "../../assets/images/on-removebg-preview.svg";
import withRouter from '../../components/common/withRouter';
import Footer from "../../components/footerAccessibilityPageFooter";
import Navbar from "../../components/Navbar";
import { loginWithPassword } from '../../slice/auth/login/thunk';
import "../../styles/ThemeColors.css";

const SignWithPassword = (props) => {

  document.title = "Authentication";

  const { existingUser, error, loading, errorMsg, mobileNumber } = useSelector(state => ({
    existingUser: state.Login.existingUser,
    error: state.Login.error,
    loading: state.Login.loading,
}));
const userType = existingUser && existingUser.userType ? existingUser.userType : null

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location =useLocation();
  const email = location.state && location.state.email;

   // const email = localStorage.getItem("email");
  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: Yup.object({
       password: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      let payload = {
        email:email,
        password:values.password,
        userType:userType
      }
      try{
        await dispatch(loginWithPassword(payload,props.router.navigate))
                .then((x) => {
                     if (!x) {
                        Swal.fire({
                            icon: "error",
                            title: "Password Error",
                            text: x.message ? x.message : null
                        });
                    } else {
                        if(userType === 'Employer'){
                          navigate('/employers/jobpostdashbord');
                        }
                        else{
                          navigate('/home');;
                        }
                        console.log('password userType',userType)
                    }
                })
                .catch((e) => {
                    console.log('error', e);
                    Swal.fire({
                        icon: "error",
                        title: "Invalid Password",
                        text: "Please enter the correct password",
                        showCancelButton: true,
                        showConfirmButton: false,
                        cancelButtonText: "Close"
                    }).then((x) => {
                        if (x.isDismissed) {
                            // onClose();
                        }
                    });
                })
                .finally(() => {
                    // setLoading(false);
                });
      }catch(e){
          console.log('e',e)
      }
    },
  });

  return (
    <>
      <Navbar />
      <div className='d-flex align-items-center signup-bg'>
        <Container>
          <div>
            <Link className="text-decoration-none text-colors ms-3">
              <strong className='fs-4'>
                <IoArrowBackSharp /> Back
              </strong>
            </Link>

            <Row className='mt-5 mt-lg-0 d-flex justify-content-between align-items-center'>
            <Col md={5}>
              <Card className='signup-card text-dark'>
                <h4 className='fw-bold'>Sign in with Password</h4>

                <form onSubmit={formik.handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      Password
                    </label>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      className={`form-control mt-3 mb-2  py-2 input-height ${formik.touched.password && formik.errors.password ? 'is-invalid' : ''}`}
                      placeholder="Enter your password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                    />
                    {formik.touched.password && formik.errors.password && (
                      <div className="invalid-feedback">{formik.errors.password}</div>
                    )}
                  </div>

                  <button type="submit" className="btn btn-color-secondary btn-colors">
                    <strong>Sign in</strong> <FaArrowRight />
                  </button>
                </form>
              </Card>
              
            </Col>

            <Col md={5} className='d-none d-md-block'>
              <Image className='login-img' src={LoginImage} fluid />
            </Col>
          </Row>

          </div>
          

          {/* <div className="container d-flex justify-content-center mb-3">
            <div className="card p-3" style={{ width: 550, height: 680 }}>
              
              <div className="container-fluid d-flex justify-content-center">
                <img
                  src="https://thumbs.dreamstime.com/b/cartoon-mailbox-bird-letter-99271614.jpg"
                  className="img-fluid"
                  style={{ width: 200, height: 200 }}
                  alt="Mailbox"
                />
              </div>
                       
            </div>
          </div> */}

        </Container>
      </div>
      
      <Footer />
    </>
  );
};

export default withRouter(SignWithPassword);
