// formValidation.js
import * as Yup from 'yup';

export const nameValidationSchema = 
  Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
  });

  export const phoneNumberValidation = 
  Yup.object().shape({
    mobileNumber: Yup.string().required('mobile number is required'),
   });

   export const addressValidation = 
   Yup.object().shape({
     address: Yup.string().required('streetAddress  is required'),
     cityState: Yup.string().required('cityState  is required'),
     pinCode: Yup.string().required('pincode  is required'),
    });

   export const educationValidation = 
    Yup.object().shape({
      levelOfEducation:Yup.string().required('level of education is required'),
    });

