import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import FeedbackContent from "../../helpers/Footerpage/FeedbackContent";
const Feedback = () => {
  return (
    <div>
      <Navbar />
      <div>
        <FeedbackContent/>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};
export default Feedback;