import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  user: {},
  existingUser:{
   // isExistingUser:false
  },
  error: "", // for error message
  loading: false,
  isUserLogout: false,
  errorMsg: false, // for error
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    apiError(state, action) {
      state.error = action.payload.data;
      state.message = action.payload.data;
      state.loading = true;
      state.isUserLogout = false;
      state.errorMsg = true;
    },
    existingUserSuccess(state, action) {
      state.mobileNumber = action.payload.emailorMobileNumber.mobileNumber;
      state.email = action.payload.emailorMobileNumber.email;
      state.existingUser = action.payload;
      state.loading = false;
      state.errorMsg = false;
    },
    loginSuccess(state, action) {
       state.user = action.payload;
      state.loading = false;
      state.errorMsg = false;
    },
    jobSeekerLoginSuccess(state, action) {
      state.jobSeeker = action.payload;
      state.loading = false;
      state.errorMsg = false;
    },
    logoutUserSuccess(state, action) {
      state.isUserLogout = true;
    },
    reset_login_flag(state) {
      state.error = null;
      state.loading = false;
      state.errorMsg = false;
    },
  },
});

export const {
  apiError,
  existingUserSuccess,
  jobSeekerLoginSuccess,
  loginSuccess,
  logoutUserSuccess,
  reset_login_flag,
} = loginSlice.actions;

export default loginSlice.reducer;
