import { combineReducers } from "redux";
 
 
import LoginReducer from "./auth/login/reducer";


const rootReducer = combineReducers({
    Login: LoginReducer,
});

export default rootReducer;