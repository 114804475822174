import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../../styles/ThemeColors.css";

const MyjobsInterviews = ({ jobs }) => {
  return (
    <Container>
      {jobs.length > 0 ? (
        jobs.map((job) => (
          <Card key={job._id} className="my-3">
            <Card.Body>
              <Card.Title>{job.jobId.jobBasics.jobTitle}</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
              </Card.Subtitle>
              <Card.Text>
                Applied: {new Date(job.applicationDate).toLocaleDateString()}
              </Card.Text>
              <Card.Text>
                status: {job.status}
              </Card.Text>
    
            </Card.Body>
          </Card>
        ))
      ) :
        (<>
          <Row className="justify-content-center">
            <Col className="text-center">
              <img
                src="https://static.vecteezy.com/system/resources/previews/006/659/338/original/calendar-icon-cartoon-style-for-your-design-free-vector.jpg"
                className="rounded mx-auto d-block fluid"
                alt="..."
                style={{ width: "200px" }}
              />
            </Col>
          </Row>
          <Row className="justify-content-center mt-3">
            <Col className="text-center">
              <strong>No interviews yet</strong>
            </Col>
          </Row>
          <Row className="justify-content-center mt-3">
            <Col className="text-center">
              <p className="text-secondary">Scheduled interviews appear here.</p>
            </Col>
          </Row>
          <Row className="justify-content-center mt-3">
            <Col className="text-center">
              <Link className="text-decoration-none text-colors">
                <strong>Not seeing interviews?</strong>
              </Link>
            </Col>
          </Row>
        </>)}
    </Container>
  );
};

export default MyjobsInterviews;
