import React from "react";
import { Container, Row, Col, ListGroup } from "react-bootstrap";

const FrequentlySearchedCareersContent = () => {
  const careers = [
    "Security Guard",
    "Accountant",
    "Data Entry Clerk",
    "Driver",
    "Laboratory Technician",
    "Software Engineer",
    "Nurse",
    "Pharmacist",
    "Teacher",
    "Office Assistant",
    "Graphic Designer",
    "Elementary School Teacher",
    "Computer Operator",
    "Registered Nurse",
    "Physiotherapist",
    "Data Analyst",
    "Back Office Executive",
    "Full Stack Developer",
    "Civil Engineer",
    "Mechanical Engineer",
    "Data Scientist",
    "Radiologist",
    "High School Teacher",
    "Assistant Professor",
    "Bus Driver",
    "Customer Care Specialist",
    "Call Center Representative",
    "Sales Executive",
    "Electrical Engineer",
    "Anesthesiologist",
    "Computer Engineer",
    "Medical Representative",
    "Collector",
    "Human Resources Specialist",
    "Housekeeper",
    "Front End Developer",
    "Python Developer",
    "Digital Marketer",
    "Web Developer",
    "Medical Officer",
    "School Teacher",
    "Electrician",
    "Counselor",
    "Veterinarian",
    "Content Writer",
  ];

  // Calculate the number of items per column
  const itemsPerColumn = Math.ceil(careers.length / 4);

  return (
    <Container fluid className="salarypage-frequently-card-bg p-4 rounded mb-4" >
      <h2>Frequently Searched Careers</h2>
      <Row className="mt-3">
        {[...Array(4)].map((_, columnIndex) => (
          <Col key={columnIndex}>
            <ListGroup>
              {careers
                .slice(
                  columnIndex * itemsPerColumn,
                  (columnIndex + 1) * itemsPerColumn
                )
                .map((career, index) => (
                  <ListGroup.Item
                    key={index}
                    action
                    href={`#${career.replace(/\s+/g, "-")}`}
                    style={{
                      border: "none",
                      cursor: "pointer",
                      color: "black",
                      backgroundColor: "#F3F2F1",
                      transition: "color 0.3s ease",
                    }}
                    className="text-decoration-none"
                    active={false}
                    hover="true"
                    onMouseOver={(e) => (e.target.style.color = " blue")}
                    onMouseOut={(e) => (e.target.style.color = "black")}
                  >
                    {career}
                  </ListGroup.Item>
                ))}
            </ListGroup>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default FrequentlySearchedCareersContent;
