import React, { useState } from "react";
import Navbar from "../../components/Navbar";
import NameForm from "./BuildanResumePages/NameForm";
import PhoneNumberForm from "./BuildanResumePages/PhoneNumberForm"; // Import PhoneNumberForm
import AddressForm from "./BuildanResumePages/AddressForm";
import EducationDetailsForm from "./BuildanResumePages/EducationDetailsForm";
import AddWorkExperiance from "./BuildanResumePages/AddWorkExperiance";
import CertificationLicencePage from "./BuildanResumePages/CertificationLicencePage";
import ReviewEducation from "./BuildanResumePages/ReviewEducation";
import BuildanResumeOverviewPage from "./BuildanResumePages/BuildanResumeOverviewPage";

function BuildAnResumeHomePage() {
  const [step, setStep] = useState(1);
  const [edit, setEdit] = useState(4);

  const handleContinue = () => {
    setStep(step + 1);
  };
  const handleEditClick = () => {
    setEdit(edit - 1);
  };

  return (
    <div>
      <Navbar />
      <div className="container">
        {step === 1 && <NameForm onContinue={handleContinue} />}
        {step === 2 && <PhoneNumberForm onContinue={handleContinue} />}
        {step === 3 && <AddressForm onContinue={handleContinue} />}
        {step === 4 && <EducationDetailsForm onContinue={handleContinue} />}
        {step === 5 && (
          <ReviewEducation
            onEditClick={handleEditClick}
            onContinue={handleContinue}
          />
        )}
        {step === 6 && <AddWorkExperiance onContinue={handleContinue} />}
        {step === 7 && (
          <BuildanResumeOverviewPage onContinue={handleContinue} />
        )}
        {step === 8 && <CertificationLicencePage onContinue={handleContinue} />}

        {/* Edit forms */}
        {edit === 2 && <EducationDetailsForm onContinue={handleContinue} />}
      </div>
    </div>
  );
}

export default BuildAnResumeHomePage;
