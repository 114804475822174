import jwt_decode from "jwt-decode";
export const MODE_TYPE = process.env.REACT_APP_MODE;


export function getTokenValue(token) {
    try {
        var decoded = jwt_decode(token);
         return decoded;
    }
    catch (e) {
        return {}
    }
}

export function isObjectEmpty(obj) {
    return Object.keys(obj).length === 0;
  }
  
export function IsNullOrEmpty(name) {
    return name === "" ||
        name === undefined ||
        name === null;
};

export async function logoutToken() {
    localStorage.removeItem("authUser");
    document.location.href = "/signup";
}

 

export function buildQueryParams(orderFilter) {
     let queryParams = {};
    if (orderFilter && orderFilter!=="All") {
        queryParams = `?status=${orderFilter}`
    }
    return queryParams;
};
