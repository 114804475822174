const setCanvasPreview = (image, canvas, crop, scale = 1) => {
  const ctx = canvas.getContext("2d");

  if (!crop || !ctx || !image) {
    return;
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const pixelRatio = window.devicePixelRatio;

  canvas.width = crop.width * pixelRatio;
  canvas.height = crop.height * pixelRatio;

  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = "high";

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX / scale,
    crop.height * scaleY / scale,
    0,
    0,
    crop.width,
    crop.height
  );
};

export default setCanvasPreview;
