import React from "react";
import Employersnavbar from "../../pages/employers/Employersnavbar";
import Employerscontentpage from "../../pages/employers/Employerscontentpage";
import Footer from "../../components/Footer";

function WithoutSigninEmployerspage() {
  return (
    <div>
      <Employersnavbar />
      <div>
        <Employerscontentpage />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default WithoutSigninEmployerspage;
