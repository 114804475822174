import React, { useState } from "react";
import { Form, Button, Container, Row, Col, InputGroup } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "../../styles/viewJobHelper.css";
import { FaArrowRight, FaFilter, FaTimes } from "react-icons/fa";

const SearchJobs = ({ onSelect }) => {
  const [filters, setFilters] = useState({
    jobTitle: "",
    keyword: "",
    location: "",
    datePosted: "",
    remote: "",
    distance: "",
    pay: "",
    jobType: "",
    skills: "",
    occupation: "",
    educationLevel: "",
    language: "",
    company: "",
    jobLanguage: "",
  });
  const [showFilters, setShowFilters] = useState(false);

  const handleFilterToggle = () => {
    setShowFilters(!showFilters);
  };

  const handleChange = (event, name) => {
    const value = event.target.value;
    const updatedFilters = {
      ...filters,
      [name]: value,
    };
    setFilters(updatedFilters);

    const collection = {
      ...updatedFilters,
    };
    onSelect(collection);
  };

  const handleClearFilter = (name) => {
    const updatedFilters = {
      ...filters,
      [name]: "",
    };
    setFilters(updatedFilters);

    const collection = {
      ...updatedFilters,
    };
    onSelect(collection);
  };

  const handleSearch = () => {
    const collection = {
      ...filters,
    };
    onSelect(collection);
  };

  return (
    <Container className="search-filter-container mt-4">
      <Row className="d-flex justify-content-between align-items-center mb-3 ms-lg-4">
        <Col xs={12} md={10} className="mb-3 mb-md-0 search-box d-md-flex">
          <InputGroup className="custom-input-group">
            <InputGroup.Text>
              <i className="bi bi-search fs-3"></i>
            </InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="Job Title or Company Name"
              size="md"
              className="text-truncate fw-bold"
              onChange={(e) => handleChange(e, "jobTitle")}
            />
          </InputGroup>
          <div className="hrline ms-md-2 mt-2 mb-2 mb-md-0"></div>
          <InputGroup className="custom-input-group mt-md-0">
            <InputGroup.Text>
              <i className="bi bi-geo-alt fs-3"></i>
            </InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="Location"
              size="md"
              className="fw-bold w-sm-100"
              onChange={(e) => handleChange(e, "location")}
            />
          </InputGroup>

          <Button
            variant="primary"
            className="search-button mt-3 mt-md-0 ms-lg-2"
            onClick={handleSearch}
          >
            <FaArrowRight className="mb-1" />
          </Button>
        </Col>

        <Col xs={12} md={2} className="mb-2 mb-md-0">
          <Button className="search-button d-none d-md-block" onClick={handleFilterToggle}>
            <FaFilter />
          </Button>
          <Row className="d-md-flex justify-content-end">
            <Col xs={4} className="d-block d-md-none">
              <Button className="search-button" onClick={handleFilterToggle}>
                <FaFilter />
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      {showFilters && (
        <>
          <Row className="align-items-center mt-3 ms-md-2 ms-sm-4">
            <Col xs={6} md={4} lg={2} className="mb-1 mb-lg-1">
              {filters.datePosted ? (
                <div className="selected-filter badge bg-white border border-1 border-secondary-subtle text-dark d-flex justify-content-between align-items-center px-3 py-2">
                  <span className="fw-bold me-2">{filters.datePosted}</span>
                  <FaTimes
                    onClick={() => handleClearFilter("datePosted")}
                    style={{ cursor: "pointer", fontSize: "1.2rem" }}
                  />
                </div>
              ) : (
                <Form.Select
                  className="custom-select small-dropdown fw-medium"
                  size="sm"
                  onChange={(e) => handleChange(e, "datePosted")}
                >
                  <option>Date Posted</option>
                  <option value="Last 24 hours">Last 24 hours</option>
                  <option value="Last 7 days">Last 7 days</option>
                  <option value="Last 14 days">Last 14 days</option>
                  <option value="Last 30 days">Last 30 days</option>
                </Form.Select>
              )}
            </Col>
            <Col xs={6} md={4} lg={2} className="mb-1 mb-lg-1">
              {filters.remote ? (
                <div className="selected-filter badge bg-white border border-1 border-secondary-subtle text-dark d-flex justify-content-between align-items-center px-3 py-2">
                  <span className="fw-bold me-2">{filters.remote}</span>
                  <FaTimes
                    onClick={() => handleClearFilter("remote")}
                    style={{ cursor: "pointer", fontSize: "1.2rem" }}
                  />
                </div>
              ) : (
                <Form.Select
                  className="custom-select small-dropdown fw-medium"
                  size="sm"
                  onChange={(e) => handleChange(e, "remote")}
                >
                  <option>Remote</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Select>
              )}
            </Col>
            <Col xs={6} md={4} lg={2} className="mb-1 mb-lg-1">
              {filters.distance ? (
                <div className="selected-filter badge bg-white border border-1 border-secondary-subtle text-dark d-flex justify-content-between align-items-center px-3 py-2">
                  <span className="fw-bold me-2">{filters.distance}</span>
                  <FaTimes
                    onClick={() => handleClearFilter("distance")}
                    style={{ cursor: "pointer", fontSize: "1.2rem" }}
                  />
                </div>
              ) : (
                <Form.Select
                  className="custom-select small-dropdown fw-medium"
                  size="sm"
                  onChange={(e) => handleChange(e, "distance")}
                >
                  <option>Within 25 Km</option>
                  <option value="Within 10 Km">Within 10 Km</option>
                  <option value="Within 50 Km">Within 50 Km</option>
                  <option value="Within 100 Km">Within 100 Km</option>
                </Form.Select>
              )}
            </Col>
            <Col xs={6} md={4} lg={2} className="mb-1 mb-lg-1">
              {filters.pay ? (
                <div className="selected-filter badge bg-white border border-1 border-secondary-subtle text-dark d-flex justify-content-between align-items-center px-3 py-2">
                  <span className="fw-bold me-2">{filters.pay}</span>
                  <FaTimes
                    onClick={() => handleClearFilter("pay")}
                    style={{ cursor: "pointer", fontSize: "1.2rem" }}
                  />
                </div>
              ) : (
                <Form.Select
                  className="custom-select small-dropdown fw-medium"
                  size="sm"
                  onChange={(e) => handleChange(e, "pay")}
                >
                  <option>Pay</option>
                  <option value="$10/hr">$10/hr</option>
                  <option value="$20/hr">$20/hr</option>
                  <option value="$30/hr">$30/hr</option>
                </Form.Select>
              )}
            </Col>
            <Col xs={6} md={4} lg={2} className="mb-1 mb-lg-1">
              {filters.jobType ? (
                <div className="selected-filter badge bg-white border border-1 border-secondary-subtle text-dark d-flex justify-content-between align-items-center px-3 py-2">
                  <span className="fw-bold me-2">{filters.jobType}</span>
                  <FaTimes
                    onClick={() => handleClearFilter("jobType")}
                    style={{ cursor: "pointer", fontSize: "1.2rem" }}
                  />
                </div>
              ) : (
                <Form.Select
                  className="custom-select small-dropdown fw-medium"
                  size="sm"
                  onChange={(e) => handleChange(e, "jobType")}
                >
                  <option>Job Type</option>
                  <option value="Full-time">Full-time</option>
                  <option value="Part-time">Part-time</option>
                  <option value="Contract">Contract</option>
                </Form.Select>
              )}
            </Col>
            <Col xs={6} md={4} lg={2} className="mb-1 mb-lg-1">
              {filters.skills ? (
                <div className="selected-filter badge bg-white border border-1 border-secondary-subtle text-dark d-flex justify-content-between align-items-center px-3 py-2">
                  <span className="fw-bold me-2">{filters.skills}</span>
                  <FaTimes
                    onClick={() => handleClearFilter("skills")}
                    style={{ cursor: "pointer", fontSize: "1.2rem" }}
                  />
                </div>
              ) : (
                <Form.Select
                  className="custom-select small-dropdown fw-medium"
                  size="sm"
                  onChange={(e) => handleChange(e, "skills")}
                >
                  <option>Skills</option>
                  <option value="JavaScript">JavaScript</option>
                  <option value="Python">Python</option>
                  <option value="Java">Java</option>
                </Form.Select>
              )}
            </Col>
          </Row>
          <Row className="align-items-center mt-3 ms-md-2 ms-sm-4">
            <Col xs={6} md={4} lg={2} className="mb-1 mb-lg-1">
              {filters.occupation ? (
                <div className="selected-filter badge bg-white border border-1 border-secondary-subtle text-dark d-flex justify-content-between align-items-center px-3 py-2">
                  <span className="fw-bold me-2">{filters.occupation}</span>
                  <FaTimes
                    onClick={() => handleClearFilter("occupation")}
                    style={{ cursor: "pointer", fontSize: "1.2rem" }}
                  />
                </div>
              ) : (
                <Form.Select
                  className="custom-select small-dropdown fw-medium"
                  size="sm"
                  onChange={(e) => handleChange(e, "occupation")}
                >
                  <option>Occupation</option>
                  <option value="Developer">Developer</option>
                  <option value="Designer">Designer</option>
                  <option value="Manager">Manager</option>
                </Form.Select>
              )}
            </Col>
            <Col xs={6} md={4} lg={2} className="mb-1 mb-lg-1">
              {filters.educationLevel ? (
                <div className="selected-filter badge bg-white border border-1 border-secondary-subtle text-dark d-flex justify-content-between align-items-center px-3 py-2">
                  <span className="fw-bold me-2">{filters.educationLevel}</span>
                  <FaTimes
                    onClick={() => handleClearFilter("educationLevel")}
                    style={{ cursor: "pointer", fontSize: "1.2rem" }}
                  />
                </div>
              ) : (
                <Form.Select
                  className="custom-select small-dropdown fw-medium"
                  size="sm"
                  onChange={(e) => handleChange(e, "educationLevel")}
                >
                  <option>Education Level</option>
                  <option value="High School">High School</option>
                  <option value="Bachelor's">Bachelor's</option>
                  <option value="Master's">Master's</option>
                </Form.Select>
              )}
            </Col>
            <Col xs={6} md={4} lg={2} className="mb-1 mb-lg-1">
              {filters.language ? (
                <div className="selected-filter badge bg-white border border-1 border-secondary-subtle text-dark d-flex justify-content-between align-items-center px-3 py-2">
                  <span className="fw-bold me-2">{filters.language}</span>
                  <FaTimes
                    onClick={() => handleClearFilter("language")}
                    style={{ cursor: "pointer", fontSize: "1.2rem" }}
                  />
                </div>
              ) : (
                <Form.Select
                  className="custom-select small-dropdown fw-medium"
                  size="sm"
                  onChange={(e) => handleChange(e, "language")}
                >
                  <option>Language</option>
                  <option value="English">English</option>
                  <option value="Spanish">Spanish</option>
                  <option value="French">French</option>
                </Form.Select>
              )}
            </Col>
            <Col xs={6} md={4} lg={2} className="mb-1 mb-lg-1">
              {filters.company ? (
                <div className="selected-filter badge bg-white border border-1 border-secondary-subtle text-dark d-flex justify-content-between align-items-center px-3 py-2">
                  <span className="fw-bold me-2">{filters.company}</span>
                  <FaTimes
                    onClick={() => handleClearFilter("company")}
                    style={{ cursor: "pointer", fontSize: "1.2rem" }}
                  />
                </div>
              ) : (
                <Form.Select
                  className="custom-select small-dropdown fw-medium"
                  size="sm"
                  onChange={(e) => handleChange(e, "company")}
                >
                  <option>Company</option>
                  <option value="Google">Google</option>
                  <option value="Microsoft">Microsoft</option>
                  <option value="Apple">Apple</option>
                </Form.Select>
              )}
            </Col>
            <Col xs={6} md={4} lg={2} className="mb-1 mb-lg-1">
              {filters.jobLanguage ? (
                <div className="selected-filter badge bg-white border border-1 border-secondary-subtle text-dark d-flex justify-content-between align-items-center px-3 py-2">
                  <span className="fw-bold me-2">{filters.jobLanguage}</span>
                  <FaTimes
                    onClick={() => handleClearFilter("jobLanguage")}
                    style={{ cursor: "pointer", fontSize: "1.2rem" }}
                  />
                </div>
              ) : (
                <Form.Select
                  className="custom-select small-dropdown fw-medium"
                  size="sm"
                  onChange={(e) => handleChange(e, "jobLanguage")}
                >
                  <option>Job Language</option>
                  <option value="English">English</option>
                  <option value="Spanish">Spanish</option>
                  <option value="French">French</option>
                </Form.Select>
              )}
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default SearchJobs;
