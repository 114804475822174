import * as z from 'zod';

export const jobBasicsValidation = z.object({
    jobTitle: z.string().nonempty('Job title is required'),
    jobType: z.string().nonempty('Job type is required'),
    city: z.string().optional(),
    area: z.string().optional(),
    pinCode: z.string().optional(),
    streetAddress: z.string().optional(),
    specificCityField: z.string().optional(),
    isSpecificCityRequired: z.enum(['yes', 'no']).optional()
}).superRefine((data, ctx) => {
    if (data.jobType === 'onSite') {
        if (!data.city) {
            ctx.addIssue({ path: ['city'], message: 'City is required for on-site job type' });
        }
        if (!data.area) {
            ctx.addIssue({ path: ['area'], message: 'Area is required for on-site job type' });
        }
        if (!data.pinCode) {
            ctx.addIssue({ path: ['pinCode'], message: 'Pin Code is required for on-site job type' });
        }
        if (!data.streetAddress) {
            ctx.addIssue({ path: ['streetAddress'], message: 'Street Address is required for on-site job type' });
        }
    } else if (data.jobType === 'remote') {
        if (data.isSpecificCityRequired === 'yes' && !data.specificCityField) {
            ctx.addIssue({ path: ['specificCityField'], message: 'Specific City Field is required if "Yes" is selected for remote job type' });
        }
    }
});


export const jobDetailsSchema = z.object({
    jobType: z.array(z.string()).min(1, 'At least one Job Type is required'),
    schedule: z.array(z.string()).min(1, 'At least one Schedule option is required'),
    plannedDate: z.union([z.literal('Yes'), z.literal('No')]),
    date: z.string().optional(),
    peopleNeeded: z.string().min(1, 'Number of people is required'),
    recruitmentTimeline: z.string().min(1, 'Recruitment timeline is required'),
  }).superRefine((data, ctx) => {
    if (data.plannedDate === 'Yes' && !data.date) {
      ctx.addIssue({
        path: ['date'],
        message: 'Please provide a planned start date.',
      });
    }
  });
