import React, { useState } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import "../styles/footerRelatedPageNavbar.css";

function CareerGuidePageNavbar() {
  const [showDropdown1, setShowDropdown1] = useState(false);
  const [showDropdown2, setShowDropdown2] = useState(false);

  const handleMouseEnterDropdown1 = () => {
    setShowDropdown1(true);
  };

  const handleMouseLeaveDropdown1 = () => {
    setShowDropdown1(false);
  };

  const handleMouseEnterDropdown2 = () => {
    setShowDropdown2(true);
  };

  const handleMouseLeaveDropdown2 = () => {
    setShowDropdown2(false);
  };

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Navbar.Brand href="#home">ESG Home</Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <NavDropdown
            title="Commitments"
            id="collasible-nav-dropdown-2"
            show={showDropdown2}
            onMouseEnter={handleMouseEnterDropdown2}
            onMouseLeave={handleMouseLeaveDropdown2}
          >
            <NavDropdown.Item href="#action/3.1">
              Shorten job search
            </NavDropdown.Item>
            <NavDropdown.Item href="#action/3.2">
              Help 30M job seekers facing barriers
            </NavDropdown.Item>{" "}
            <NavDropdown.Item href="#action/3.1">
              Increase Indeed's workforce representation
            </NavDropdown.Item>
            <NavDropdown.Item href="#action/3.1">
              Achieve net zero emissions
            </NavDropdown.Item>
          </NavDropdown>

          <NavDropdown
            title="Initiatives"
            id="collasible-nav-dropdown-1"
            show={showDropdown1}
            onMouseEnter={handleMouseEnterDropdown1}
            onMouseLeave={handleMouseLeaveDropdown1}
          >
            <NavDropdown.Item href="#action/3.1">
              Work wellbeing{" "}
            </NavDropdown.Item>
            <NavDropdown.Item href="#action/3.2">
              Supplier diversity
            </NavDropdown.Item>
            <NavDropdown.Item href="#action/3.3">Fair Chance</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.3">
              Skill Connect
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link href="#features" className="nav-link-hover">
            DEIB+
          </Nav.Link>
          <Nav.Link href="#pricing" className="nav-link-hover">
            Social Impact
          </Nav.Link>
          <Nav.Link href="#pricing" className="nav-link-hover">
            Environmental Sustainability
          </Nav.Link>
          <Nav.Link href="#pricing" className="nav-link-hover">
            Responsible AI
          </Nav.Link>

          <Nav.Link href="#pricing" className="nav-link-hover">
            Accessibility
          </Nav.Link>
          <Nav.Link href="#pricing" className="nav-link-hover">
            Human Rights
          </Nav.Link>
          <Nav.Link href="#pricing" className="nav-link-hover">
            News
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default CareerGuidePageNavbar;
