// formikValidation.js
import { useFormik } from 'formik';
import {
  Form
} from "react-bootstrap";
const useFormikValidation = (initialValues, validationSchema, onSubmit) => {
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  const renderFormField = (fieldName, label, placeholder, type = 'text', options) => (
    
    <div className="mb-3">
       <Form.Group controlId={fieldName}>
        <Form.Label>{label}</Form.Label>
         {type === 'select' ? (
           <Form.Select
           {...formik.getFieldProps(fieldName)}
           value={formik.values[fieldName]}   
           onChange={(e) => formik.setFieldValue(fieldName, e.target.value)}  
         >
            <option value="">{placeholder}</option>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option}
              </option>
            ))}
          </Form.Select>
           ) : type === 'checkbox' ? (
          <Form.Check
            type={type}
            label={label}
            {...formik.getFieldProps(fieldName)}  
          />
        ) : (
          <Form.Control
            type={type}
            placeholder={placeholder}
            {...formik.getFieldProps(fieldName)} 
          />
        )}
        {formik.touched[fieldName] && formik.errors[fieldName] && (
          <div className="text-danger">{formik.errors[fieldName]}</div>
        )}
      </Form.Group>
    </div>
  );
  return { formik, renderFormField };
};


export default useFormikValidation;
