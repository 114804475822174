import React from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  ProgressBar,
} from "react-bootstrap";
import BuildInResumePageFooter from "../../../components/BuildInResumePageFooter";
import { addressValidation } from "../../../helpers/Validation/ResumeValidation";
import { useNavigate } from "react-router-dom";
import useFormikValidation from "../../../helpers/FormikValidation";
import Swal from "sweetalert2";
import { jobSeekerResumeUpdate } from "../../../helpers/backendHelper";
import "../../../styles/ThemeColors.css";

function AddressForm({ onContinue }) {
  const navigate = useNavigate();

  const { formik, renderFormField } = useFormikValidation(
    {
      mobileNumber: "",
    },
    addressValidation,
    (values) => {
      if (formik.isValid) {
        jobSeekerResumeUpdate({ mobileNumber: values.mobileNumber })
          .then((x) => {
            navigate("/resume/addressForm");
          })
          .catch((e) => {
            console.log("error", e);
            Swal.fire({
              icon: "error",
              title: "Error",
              text: e,
              showCancelButton: true,
              showConfirmButton: false,
              cancelButtonText: "Close",
            }).then((x) => {
              if (x.isDismissed) {
                // onClose();
              }
            });
          })
          .finally(() => {
            // setLoading(false);
          });
      }
    }
  );
  const handleContinue = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    // You can add validation logic here before continuing
    onContinue();
  };
  return (
    <Container className="mt-5">
      {/* Center the form using Bootstrap grid */}
      <Row className="justify-content-center">
        <Col xs={12} sm={12} md={12} lg={6}>
          {/* Card-like styling */}
          <div className="card p-4">
            {/* Back Arrow and Save & Exit Link */}
            <Row>
              <Col xs="auto">
                <a href="/" className="btn btn-link text-colors">
                  <i className="bi bi-arrow-left"></i> Back
                </a>
              </Col>
              <Col className="text-end ">
                <a href="/" className="btn btn-link text-colors">
                  Save & Exit
                </a>
              </Col>
            </Row>

            {/* Progress Bar */}
            <Row className="mt-3">
              <Col>
                <ProgressBar animated now={40} label="40%" />
              </Col>
            </Row>

            {/* Title */}
            <Row className="mt-4">
              <Col>
                <h2>Where are you located?</h2>
                <p>This helps match you with nearby jobs</p>
              </Col>
            </Row>

            {/* Form Inputs */}
            <Row className="mt-4">
              <Col>
                <Form>
                  <Form.Group controlId="firstName">
                    <Form.Label>Street address</Form.Label>
                    <Form.Control type="text" placeholder="" />
                  </Form.Group>

                  <Form.Group controlId="firstName">
                    <Form.Label>City, State*</Form.Label>
                    <Form.Control type="text" placeholder="" />
                  </Form.Group>

                  <Form.Group controlId="lastName">
                    <Form.Label>Pincode</Form.Label>
                    <Form.Control type="text" placeholder="" />
                  </Form.Group>

                  {/* Continue Button */}
                  <Button variant="primary" type="submit" className="mt-3 btn-colors">
                    Continue
                  </Button>
                </Form>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <div>
        <BuildInResumePageFooter />
      </div>
    </Container>
  );
}

export default AddressForm;
