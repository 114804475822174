import jwt_decode from "jwt-decode";
import { setAuthorization } from "../../../helpers/apiHelper";
import {
  createEmployer,
  createJobSeeker,
  employerLoginWithPassword,
  employerProfileUpdate,
  employerVerifyOtp,
  existingUserOrNot,
  getEmployer,
  getJobSeeker,
  jobSeekerLoginApi,
  jobSeekerVerifyOtp
} from "../../../helpers/backendHelper";
import { apiError, existingUserSuccess, loginSuccess, logoutUserSuccess, reset_login_flag } from './reducer';


export const existingUserCheck = (user, history) => async (dispatch) => {
  try {
    let response;
    user.email ? localStorage.setItem("email", user.email) : localStorage.setItem("mobileNumber", user.mobileNumber);

    response = await existingUserOrNot(user);
    var data = response;
     let emailorMobileNumber = {
      email: user.email ? user.email : null,
      mobileNumber: user.mobileNumber ? user.mobileNumber : null
    }
    data.emailorMobileNumber = emailorMobileNumber

    console.log('data existing user',data)
    if (data) {
      await dispatch(existingUserSuccess(data));
      history('/auth')
    }
    else {
      dispatch(apiError({ error: "error" }));
    }
  } catch (error) {
    if (error === "Request failed with status code 400") {

    } else {
      dispatch(apiError(error));
    }
  }
};
export const loginWithPassword = (payload, history) => async (dispatch) => {
  try {

    let response = {}
    
     if(payload.userType === "JobSeeker"){
        response = await jobSeekerLoginApi({
        email: payload.email,
        password: payload.password,
      });
      
   }
   else if(payload.userType==="Employer"){
      response = await employerLoginWithPassword({
        email:payload.email,
        password:payload.password
      })

   }
    if(response && response.statusCode === 200){
      const decodedToken = await jwt_decode(response.token);
      setAuthorization(response.token)
      localStorage.setItem("authUser", JSON.stringify(response.token));
      localStorage.setItem("user", JSON.stringify(decodedToken));
     await dispatch(loginSuccess(decodedToken));
    return true;
    }
 
  } catch (error) {
    dispatch({ type: 'LOGIN_FAILURE', payload: error.message });
  }
};
export const getUser= () => async (dispatch) =>{
  const employer = await getEmployer();
  const jobSeeker = await getJobSeeker();

  if(employer && employer.statusCode === 200){
     dispatch(loginSuccess(employer.employer));
  }
  else if(jobSeeker && jobSeeker.statusCode === 200) {
     
       const  payload =  jobSeeker.jobseeker
      dispatch(loginSuccess(payload))
  }


}
export const createUsers = (user, history) => async (dispatch) => {
  try {
    let response;
    const inputdata = user.email ? { email: user.email } : { mobileNumber: user.mobileNumber };
    if (user.userType === 'JobSeeker') {
      response = await createJobSeeker({
        ...inputdata,
        password: user.password,
      });
    }
    else if (user.userType === 'Employer') {
      response = await createEmployer({
        ...inputdata,
        password: user.password,
      });
    }
    var data = response;
    if (data) {

      const decodedToken = await jwt_decode(data.token);
      // const emailOrMobileNumber = decodedToken.email ? decodedToken.email : decodedToken.mobileNumber


      localStorage.setItem("authUser", JSON.stringify(data.token));
      localStorage.setItem("user", JSON.stringify(decodedToken));
      setAuthorization(data.token)

      dispatch(loginSuccess(decodedToken));
    }
    else {
      //dispatch(loginSuccess(data));
      dispatch(apiError(data));
    }
  } catch (error) {
    if (error === "Request failed with status code 400") {

    } else {

      dispatch(apiError(error));
    }


  }
};

export const forgetPasswordWithOtp = (user, history) => async (dispatch) => {
  try {
    let response;
    const inputdata = user.email ? { email: user.email } : { mobileNumber: user.mobileNumber };
     response = await jobSeekerVerifyOtp({
      ...inputdata,
      otp: user.otp,
    });
   
    var data = response;
     if (data) {
       const decodedToken = await jwt_decode(data.token);
      // const emailOrMobileNumber = decodedToken.email ? decodedToken.email : decodedToken.mobileNumber
      localStorage.setItem("authUser", JSON.stringify(data.token));

      localStorage.setItem("user", JSON.stringify(decodedToken));
       dispatch(loginSuccess(decodedToken));
      // history('/editpassword');
      return true;
    
    }
    else {
      //dispatch(loginSuccess(data));
      dispatch(apiError(data));
    }
  } catch (error) {
    if (error === "Request failed with status code 400") {

    } else {
      dispatch(apiError(error));
    }
  }
};

export const jobSeekerLoginWithOtp = (user, history) => async (dispatch) => {
  try {
    let response;
    const inputdata = user.email ? { email: user.email } : { mobileNumber: user.mobileNumber };
     response = await jobSeekerVerifyOtp({
      ...inputdata,
      otp: user.otp,
    });
    var data = response;
    if (data) {
       const decodedToken = await jwt_decode(data.token);
      // const emailOrMobileNumber = decodedToken.email ? decodedToken.email : decodedToken.mobileNumber
      localStorage.setItem("authUser", JSON.stringify(data.token));
      localStorage.setItem("user", JSON.stringify(decodedToken));
      setAuthorization(data.token)
      dispatch(loginSuccess(decodedToken));
      return true;
    }
    else {
      //dispatch(loginSuccess(data));
      dispatch(apiError(data));
    }
  } catch (error) {
    if (error === "Request failed with status code 400") {

    } else {
      dispatch(apiError(error));
    }
  }
};

export const profileUpdateForEmployer = (user, history) => async (dispatch) => {
  try {

     let response;

    response = await employerProfileUpdate(user)
 
    return response
  }
  catch (e) {

  }
}



export const empolyerLoginWithOtp = (user, history) => async (dispatch) => {
  try {
    let response;
    const inputdata = user.email ? { email: user.email } : { mobileNumber: user.mobileNumber };
     response = await employerVerifyOtp({
      ...inputdata,
      otp: user.otp,
    });

    var data = response;
     if (data) {

      if(data.statusCode === 404){
        return data
      }
       const decodedToken = await jwt_decode(data.token);
      //  const emailOrMobileNumber = decodedToken.email ? decodedToken.email : decodedToken.mobileNumber
      localStorage.setItem("authUser", JSON.stringify(data.token));
      dispatch(loginSuccess(decodedToken));
      return data;
    }
    else {
      //dispatch(loginSuccess(data));
      dispatch(apiError(data));
    }
  } catch (error) {
    if (error === "Request failed with status code 400") {

    } else {
      dispatch(apiError(error));
    }
  }
};




export const logoutUser = () => async (dispatch) => {
  try {
    localStorage.removeItem("authUser");
    dispatch(logoutUserSuccess(true));
  } catch (error) {
    dispatch(apiError(error));
  }
};



export const resetLoginFlag = () => async (dispatch) => {
  try {
    const response = dispatch(reset_login_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error));
  }
};