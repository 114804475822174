import { Image,Button } from "react-bootstrap";
import React from "react";
import "../../assets/css/Animation.css";
import ManijImage from "../../assets/images/manij.svg";
import TickImage from "../../assets/images/tick.webp";
import PeopleImage from "../../assets/images/People.webp";

export default function Animation(){
    return(<div className="main-animat">
        <div className="animat">
            <Image className="animatimg" src={ManijImage} />

            <div className="philosophy">
                <h5 className="txt1">Our Philosophy</h5>
                <h2 className="txt2">Inspiring Today's Students To Succeed In</h2>
                <h2 className="txt3">Tomorrow's World.</h2>
                <p className="animaptag text-black">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
                
                <div className="cont">
                    <Image className="icon1" src={TickImage} />
                    <div className="icontr">
                        <p className="icotext">Up-to-Date Course Content.</p>
                        <p className="icotext2 text-black">Our platform also features a collaborative learning environment.</p>
                    </div>
                </div>

                <div className="cont">
                    <Image className="icon1" src={PeopleImage} />
                    <div className="icontr">
                        <p className="icotext">Biggest Student Community</p>
                        <p className="icotext2 text-black">Our courses are designed by industry experts and delivered through interactive online</p>
                    </div>
                </div>

                <Button className="btnlearn">Learn More</Button>

            </div>
        
        </div>
    </div>)
}