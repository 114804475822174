import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import ContactContent from "../../helpers/Footerpage/ContactContent";
const Contact = () => {
  return (
    <div>
        <Navbar />
      <div>
        <ContactContent/>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};
export default Contact;