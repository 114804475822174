import React from "react";
import img from "../../assets/images/banner_old.jpg";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import "../../assets/css/It.css";

const NonItJobCard = () => {
  return (
    <Container fluid>
      <Row xs={1} md={1} lg={1} className="p-5 row-1">
        <Col className="text-center p-4">
          <h2 className="fs-1">
            Over, <span className="text-light">5,000 Jobs</span> are Waiting for
            you
          </h2>
          <p className="fs-5">
            Work With the best companies,hire the experienced professionals
          </p>
        </Col>
        <Col className="cd-card">
          <Card className="mt-4 shadow border-0 w-90 m-auto">
            <Card.Body className="d-flex">
              <InputGroup>
                <Form.Select aria-label="jobTitle">
                  <option value="">Select jobTitle</option>
                </Form.Select>

                <Form.Select aria-label="Location">
                  <option value="">Select location</option>
                </Form.Select>
              </InputGroup>
              <Button
                className="form-control w-25 home-btn btn-color-primary"
                type="button"
              >
                Search
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row xs={1} md={2} lg={4} className="m-3">
        {Array.from({ length: 8 }).map((_, idx) => (
          <Col key={idx}>
            <Card className="">
              <Card.Img variant="top" src={img} />
              <Card.Body>
                <Card.Title>Card title</Card.Title>
                <Card.Text>
                  This is a longer card with supporting text below as a natural
                  lead-in to additional content. This content is a little bit
                  longer.
                </Card.Text>
                <Button variant="primary">ApplyJob</Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default NonItJobCard;
