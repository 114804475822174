import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import EmployerNavBar from "../../../components/Employer/EmployerNavbar";
import EmployerSidebar from "../../../components/EmployerSidebar";
import Swal from "sweetalert2";
import { employerPostJob, getJobsByJobId } from "../../../helpers/backendHelper";
import { useNavigate, useParams } from "react-router-dom";
import '../../../assets/css/employer/reviewJobPost.css';
import EditModal from "../../../components/common/EditModal";
import "bootstrap-icons/font/bootstrap-icons.css";  // Import Bootstrap Icons
import { isDraft } from "@reduxjs/toolkit";

const ReviewJobPost = () => {
    const [sidebarExpanded, setSidebarExpanded] = useState(false);
    const [jobData, setJobData] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editField, setEditField] = useState("");
    const [editValue, setEditValue] = useState([]);

    const navigate = useNavigate();
    const params = useParams();
    const job_id = params.job_id;

    useEffect(() => {
        fetchJobs();
    }, []);

    const fetchJobs = async () => {
        try {
            const response = await getJobsByJobId(job_id);
            if (response && response.statusCode === 200) {
                if (response.jobs.length > 0) {
                    setJobData(response.jobs[0]);
                }
            }
        } catch (e) {
            console.log("error", e);
        }
    };

    const handleConfirmClick = async () => {
        const data = {
            jobId: job_id,
            isDraft: false,
            status: 'opened',
            salary: {
                range: {
                    minimum: "50000",
                    maximum: "70000"
                }
            },
        };
        try {
            const response = await employerPostJob(data);
            if (response && response.statusCode === 200) {
                navigate('/employers/jobpostdashbord');
            }
        } catch (e) {
            console.log("error", e);
            Swal.fire({
                icon: "error",
                title: "Failed to Post Job",
                text: "Error occurred",
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonText: "Close",
            });
        }
    };
    const handleEditClick = (field, value) => {
        setEditField(field);
        if (field === "Schedule" || field === "Job Type") {
            setEditValue(value);
        } else {
            setEditValue(value);
        }
        setShowEditModal(true);
    };

    const handleSaveEdit = async () => {
        const field = editField.toLowerCase();
        let payload = {}
        if (field === 'job title') {
            payload = {
                jobId: job_id,
                jobBasics: {
                    jobTitle: editValue
                },
                isDraft: true
            }
        }
        else if (field === 'job description') {
            payload = {
                jobId: job_id,
                jobDescription: editValue,
                isDraft: true
            }
        }
        else if (field === 'schedule') {
            payload = {
                jobId: job_id,
                jobDetails: {
                    schedule: editValue
                },
                isDraft: true
            }
        }
        else if (field === 'job type') {
            payload = {
                jobId: job_id,
                jobDetails: {
                    jobType: editValue
                },
                isDraft: true
            }
        }
        const updatedJobData = {
            jobId: job_id,
            value: editValue,
            field: editField.toLowerCase()
        };
        try {
            const response = await employerPostJob(payload);
            if (response && response.statusCode === 200) {
                setJobData((prevData) => ({
                    ...prevData,
                    jobBasics: {
                        ...prevData.jobBasics,
                        [editField === "Job Title" ? "jobTitle" : "companyName"]:
                            editField === "Job Title" ? editValue :
                                prevData.jobBasics.companyName,
                    },
                    jobDetails: {
                        ...prevData.jobDetails,
                        [editField === "Job Type" ? "jobType" :
                            editField === "Schedule" ? "schedule" :
                                editField === "Job Description" ? "description" :
                                    prevData.jobDetails.description]:
                            editField === "Job Type" ? editValue :
                                editField === "Schedule" ? editValue :
                                    editValue, // Handle job description update
                    }
                }));
                Swal.fire({
                    icon: "success",
                    title: "Field Updated",
                    text: `${editField} has been successfully updated.`,
                    showConfirmButton: true,
                    confirmButtonText: "Close",
                });
            } else {
                throw new Error("Update failed");
            }
        } catch (error) {
            console.error("Update failed:", error);
            Swal.fire({
                icon: "error",
                title: "Failed to Update",
                text: `Failed to update ${editField}. Please try again.`,
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonText: "Close",
            });
        }
        setShowEditModal(false);
    };
    return (
        <>
            <div id="wrapper">
                <EmployerSidebar sidebarExpanded={sidebarExpanded} />
                <div id="navbar-wrapper">
                    <EmployerNavBar />
                </div>
                <Container className="job-post-page d-flex justify-content-center align-items-center">
                    <div className="section">
                        <h2 className="text-center">Job Review</h2>
                        {jobData ? (
                            <>
                                <Row className="mb-4">
                                    <Col>
                                        <Form.Label>Job Title</Form.Label>
                                        <div className="d-flex justify-content-between">
                                            <span>{jobData.jobBasics.jobTitle}</span>
                                            {/* <Button variant="link" onClick={() => handleEditClick("Job Title", jobData.jobBasics.jobTitle)}>
                                                <i className="bi bi-pencil-square"></i>
                                            </Button> */}
                                            <Button variant="link" className="custom-edit-button" onClick={() => handleEditClick("Job Title", jobData.jobBasics.jobTitle)}>
                                                <i className="bi bi-pencil edit-icon"></i>
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="mb-4">
                                    <Col>
                                        <Form.Label>Company Name</Form.Label>
                                        <div className="d-flex justify-content-between">
                                            <span>{jobData.companyName}</span>

                                            {/* <Button variant="link" className="custom-edit-button" onClick={() => handleEditClick("Company Name", jobData.companyName)}>
                                                <i className="bi bi-pencil edit-icon"></i>
                                            </Button> */}

                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col>
                                        <Form.Label>Job Location</Form.Label>
                                        <div className="d-flex justify-content-between">
                                            <span>{jobData.jobBasics.jobLocation.city}, {jobData.jobBasics.jobLocation.area}, {jobData.jobBasics.jobLocation.pincode}</span>
                                            {/* <Button variant="link" className="custom-edit-button"  onClick={() => handleEditClick("Job Location", `${jobData.jobBasics.jobLocation.city}, ${jobData.jobBasics.jobLocation.area}, ${jobData.jobBasics.jobLocation.pincode}`)}>
                                                <i className="bi bi-pencil edit-icon"></i>
                                            </Button> */}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col>
                                        <Form.Label>Job Type</Form.Label>
                                        <div className="d-flex justify-content-between">
                                            <span>{jobData.jobDetails.jobType.join(", ")}</span>
                                            <Button variant="link" className="custom-edit-button" onClick={() => handleEditClick("Job Type", jobData.jobDetails.jobType)}>
                                                <i className="bi bi-pencil edit-icon"></i>

                                            </Button>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="mb-4">
                                    <Col>
                                        <Form.Label>Schedule</Form.Label>
                                        <div className="d-flex justify-content-between">
                                            <span>{jobData.jobDetails.schedule.join(", ")}</span>
                                            <Button variant="link" className="custom-edit-button" onClick={() => handleEditClick("Schedule", jobData.jobDetails.schedule)}>
                                                <i className="bi bi-pencil edit-icon"></i>
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="mb-4">
                                    <Col>
                                        <Form.Label>Job Description</Form.Label>
                                        <div className="d-flex justify-content-between">
                                            <div dangerouslySetInnerHTML={{ __html: jobData.jobDescription }} />
                                            <Button variant="link" className="custom-edit-button" onClick={() => handleEditClick("Job Description", jobData.jobDescription)}>
                                                <i className="bi bi-pencil edit-icon"></i>
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>

                                <div className="d-flex justify-content-between mt-4">
                                    <Button variant="primary" onClick={handleConfirmClick}>Confirm</Button>
                                </div>
                            </>
                        ) : (
                            <p>Loading job details...</p>
                        )}
                    </div>
                </Container>
            </div>

            <EditModal
                show={showEditModal}
                handleClose={() => setShowEditModal(false)}
                handleSave={handleSaveEdit}
                field={editField}
                value={editValue}
                setValue={setEditValue}
            />
        </>
    );
};

export default ReviewJobPost;
