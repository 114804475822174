import React from 'react'
import Navbar from '../components/Navbar'
import { Card, CardBody, Col, Row } from 'react-bootstrap'
import "../styles/ThemeColors.css";

const Notification = () => {
    return (
        <div>
            <div>
                <Navbar />
            </div>
            <div class="container  mt-lg-4 mt-3">
                <Row>
                    <Col xs={12} md={12} lg={12}>
                    <Card className='border-0 p-3'>
                    <CardBody>
                    <div class='d-flex justify-content-center mt-5'>
                        <img src="https://as1.ftcdn.net/v2/jpg/04/60/11/54/1000_F_460115406_NEGWaYsf30bAcoLOsy8Xs0b90xOk6AJo.jpg" class="rounded mx-auto d-block" alt="..." style={{ width: 300 }} />
                    </div>
                    <h5 class="card-title"><strong>Nothing right now. check back later</strong></h5>
                    <p class="card-text">This is where we'll notify you about your job applications and other usefull information to help you with your job search.</p>
                    <a href="#" class="btn btn-colors">Find jobs</a>
                    </CardBody>
                </Card>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Notification