import { useFormik } from "formik";
import React from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import OTPInput from "react-otp-input";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import pswimg from "../../assets/images/psw-flow2.svg";
import Navbar from "../../components/Navbar";
import Footer from "../../components/footerAccessibilityPageFooter";
import { forgetPasswordWithOtp } from "../../slice/auth/login/thunk";
import "../../styles/Forgetpsw.css";

const EmailOtp = (props) => {
  const location = useLocation();
  const { email } = location.state || {};
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string()
        .required("OTP is required")
        .length(6, "OTP must be 6 digits"),
    }),
    onSubmit: async (values) => {
      const payload = { otp: values.otp, email };

      const isSuccess = await dispatch(
        forgetPasswordWithOtp(payload, navigate)
      );
      if (isSuccess) {
        Swal.fire("Verified!", "OTP verified successfully", "success");
        navigate("/editpassword");
      } else {
        Swal.fire({
          icon: "error",
          title: "Invalid PIN",
          text: "Please enter the correct PIN",
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: "Close",
        });
      }
      console.log("OTP submitted:", values.otp);
    },
  });

  return (
    <>
      <Navbar />  
      <div className="d-flex align-items-center pswd">
        <Container className="mt-5">
          <Row className="pswd-row d-flex justify-content-between align-items-center">
            <Col md={6} lg={5}>
              <Card className="pswd-content">
                <h4 className="fw-bold">Enter Your Code</h4>
                <p className="p-title">
                  We sent the code to your email address:<a href="#">{email}</a>
                </p>
                <form onSubmit={formik.handleSubmit}>
                  <Col md={12} className="otp-position">
                    <div className="my-3 otp-position-content">
                      <OTPInput
                        value={formik.values.otp}
                        name="otp"
                        inputType="password"
                        numInputs={6}
                        onChange={(otp) => formik.setFieldValue("otp", otp)}
                        onBlur={formik.handleBlur}
                        renderInput={(props) => (
                          <input
                            {...props}
                            className="otp-input"
                            style={{
                              borderColor:
                                formik.touched.otp && formik.errors.otp
                                  ? "red"
                                  : "#3A95CD",
                            }}
                          />
                        )}
                      />
                      {formik.touched.otp && formik.errors.otp ? (
                        <div className="invalid-feedback">
                          {formik.errors.otp}
                        </div>
                      ) : null}
                    </div>
                  </Col>
                  <p>Didn't receive the email? <button className="btn btn-link p-0 text-decoration-underline fw-bolder" type="button">
                    Click to Resend
                  </button></p>
                  <button
                    type="submit"
                    className="btn btn-color-primary input-height py-2 mt-3 otp-button"
                  >
                    <strong>Continue</strong>
                  </button>
                </form>
              </Card>
            </Col>
            <Col md={5} className="d-none d-md-block">
              <Image className="pswd-img" src={pswimg} alt="img" fluid />
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default EmailOtp;
