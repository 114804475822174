import Swal from "sweetalert2";


export const interviewConfrimSwalHelper = async(message) => {
           return   await Swal.fire({
                title: 'Are you sure?',
                text: `Are you sure you want to ${message} this candidate?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: `Yes, ${message} it!`
            });
        }
export const interviewSwalSuccessHelper = async (message) =>{
    
   return await Swal.fire({
        title: 'Success',
        text: `candidate ${message} successfully!`,
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
    })

}                              
   