import React from 'react';
import { Button, Card, Col, Container, Image, Row } from 'react-bootstrap';
import withRouter from '../../components/common/withRouter';
import Footer from "../../components/footerAccessibilityPageFooter";
import Navbar from "../../components/Navbar";
import '../../styles/login.css';
// import Footer from '../../components/footerAccessibilityPageFooter';
import { MdAlternateEmail } from "react-icons/md";
import { Link } from 'react-router-dom';
import LoginImage from "../../assets/images/on-removebg-preview.svg";
import { googleSignInHelper } from '../../helpers/Login/LoginHelper';
 
const SignupOption = () => {

    document.title = "Authentication";
 
    const handleGoogleLogin = () =>{
        googleSignInHelper();
    }
    return (
        <>
            <Navbar />
            <div class="d-flex align-items-center signup-bg">
 
                <Container className='my-5'>
                    <Row className='d-flex justify-content-between align-items-center'>
                        <Col md={6} lg={5} >
                            <Card className='signup-card text-dark'>
                                <h4 className='fw-bold'>Sign in to Walkup</h4>
                                <p className='fw-bold'>Login by Your Secure Account</p>
 
                                <div className='w-100 mt-4 d-flex flex-column align-items-center'>
                                    <Button as={Link} to='/signup' className='w-100 mt-2 p-2 signup-buttons text-muted bg-transparent border border-1 border-secondary d-flex justify-content-start'>
                                        <MdAlternateEmail className='me-4 fs-4' />
                                        Sign in with Email or Mobile Number
                                    </Button>
                                    {/* <Button as={Link} to='/GoogleLogin' className='w-100 mt-3 p-2 signup-buttons text-muted bg-transparent border border-1 border-secondary d-flex justify-content-start'>
                                        <Image width="25" height="25" src="https://img.icons8.com/color/48/google-logo.png" alt="google-logo" className='me-4' />
                                        Continue with Google
                                    </Button> */}
 
                                    <Button
                                        // as={Link}
                                        // to='/GoogleLogin'
                                        onClick={handleGoogleLogin}
                                        className='w-100 mt-3 p-2 signup-buttons text-muted bg-transparent border border-1 border-secondary d-flex justify-content-start'>
                                        <Image width="25" height="25" src="https://img.icons8.com/color/48/google-logo.png" alt="google-logo" className='me-4' />
                                        Continue with Google
                                    </Button>
                                    <Button as={Link} className='w-100 mt-3 p-2 signup-buttons text-muted bg-transparent border border-1 border-secondary d-flex justify-content-start'>
                                        <img width="25" height="25" src="https://img.icons8.com/color/48/000000/facebook-new.png" alt="facebook-new" className='me-4' />
                                        Continue with Facebook
                                    </Button>
                                </div>
                            </Card>
                        </Col>
 
                        <Col md={5} className='d-none d-md-block'>
                            <Image className='login-img' src={LoginImage} fluid />
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </>
    )
}
 
export default withRouter(SignupOption);