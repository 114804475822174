import React from "react";
import { FaArrowRight } from "react-icons/fa";
import AccessibilityNavbar from "../components/footerAccessibilityPageNavbar";
import NavbarComponent from "../components/Navbar";
import { Container, Card, Row, Col, Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Acessiblityclientimg from "../assets/footer-homepage-related-images/Acessibility-client-img-1.jpeg";
import secondAcessiblityclientimg from "../assets/footer-homepage-related-images/Acessibility-client-img-2.avif";
import AccessibilityPageFooter from "../components/footerAccessibilityPageFooter";
function FooterAccessibilityHomePage() {
  return (
    <div
      className="main"
      style={{
        backgroundColor: "#01224D",
        color: "#ffffff",
        minHeight: "100vh",
      }}
    >
      <div>
        <NavbarComponent />
      </div>
      <div>
        <AccessibilityNavbar />
      </div>
      <Container className="mt-4">
        <Row className="justify-content-center align-items-center">
          <Col>
            <h2>Diversity, Equity, Inclusion & Belonging (DEIB+)</h2>
            <p>
              The future of work is equitable and inclusive. Indeed is working
              to close the opportunity gap by reducing bias and removing
              barriers for hundreds of millions of job seekers worldwide.
            </p>
            <a
              href="your_link_here"
              style={{
                color: "#ffffff",
                textDecoration: "none",
                transition: "color 0.3s ease", // Smooth transition on color change
              }}
              onMouseOver={(e) => (e.target.style.color = "#007BFF")}
              onMouseOut={(e) => (e.target.style.color = "#ffffff")}
              className="text-white"
            >
              View our 2023 DEIB+ Report <FaArrowRight />
            </a>
          </Col>
          <Col>
            <Image
              src={Acessiblityclientimg}
              alt="DEIB+ Image"
              fluid
              style={{ width: "auto", height: "650px" }}
            />
          </Col>
        </Row>
      </Container>
      <div
        className="mt-5"
        style={{
          backgroundColor: "#1D465C",
          color: "#ffffff ",
          minHeight: "100vh",
        }}
      >
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col className="mt-5">
              <h2>Diversity, Equity, Inclusion & Belonging at Indeed</h2>
              <p className="mt-5">
                Our journey started like most companies, with employee-led
                groups focused on creating a culture of inclusion and belonging
                for people from backgrounds that were not widely reflected
                across the company. This led to the Inclusion team being
                established and Inclusion Resource Groups being formalized.
                However, we realized the need to focus on more aspects in
                addition to inclusion in order to have a meaningful impact.
              </p>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={12} md={12}>
              <Card>
                <Card.Img
                  src={secondAcessiblityclientimg}
                  alt="Acessiblityclient"
                  fluid
                  style={{ height: "500px" }}
                />
              </Card>
            </Col>
          </Row>
          <Row className="justify-content-center align-items-center">
            <Col className="mt-5">
              <h2>Connecting people to better work to create better lives</h2>
              <p className="mt-5">
                We believe the world can work better. With the power of our
                platform and our people, and in partnership with organizations
                that share our mission, we’re creating an environment where
                current and future generations can thrive.
              </p>
            </Col>
          </Row>
        </Container>
        <Container>
          <h2>ESG is core to our business</h2>
          <p className="mt-3">These four principles guide everything we do.</p>
          <Row>
            <Col md={3} className="mb-4">
              <Card className="h-100">
                <Card.Img
                  variant="top"
                  src="https://d341ezm4iqaae0.cloudfront.net/assets/2021/10/16235635/SizeXL-1.png"
                  fluid
                  style={{ height: "200px" }}
                />
                <Card.Body>
                  <Card.Text>Make job search faster and simpler.</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3} className="mb-4">
              <Card className="h-100">
                <Card.Img
                  variant="top"
                  src="https://d341ezm4iqaae0.cloudfront.net/jobseeker/wp-content/uploads/2022/11/01001850/Image-from-iOS-1-1024x532-1.png"
                  fluid
                  style={{ height: "200px" }}
                />
                <Card.Body>
                  <Card.Text>Remove bias and barriers to employment</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3} className="mb-4">
              <Card className="h-100">
                <Card.Img
                  variant="top"
                  src="https://d341ezm4iqaae0.cloudfront.net/assets/2021/10/16235725/SizeXL-3.png"
                  fluid
                  style={{ height: "200px" }}
                />
                <Card.Body>
                  <Card.Text>
                    Build sustainable equity for our people through our policies
                    and practices
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3} className="mb-4">
              <Card className="h-100">
                <Card.Img
                  variant="top"
                  src="https://d341ezm4iqaae0.cloudfront.net/assets/2021/10/17000010/SizeXL-6.png"
                  fluid
                  style={{ height: "200px" }}
                />
                <Card.Body>
                  <Card.Text>Reduce our environmental footprint</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        <div>
          <AccessibilityPageFooter />
        </div>
      </div>
    </div>
  );
}

export default FooterAccessibilityHomePage;
