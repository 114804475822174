import React from "react";
import { Modal, Button, Form } from "react-bootstrap";

// Schedule and Job Type options
const scheduleOptions = [
    "Fixed shift", "Day shift", "Monday to Friday", "Morning shift", "Evening shift",
    "Night shift", "US shift", "UK shift", "Overnight shift", "Rotational shift",
    "Weekend availability", "Other"
];

const jobTypeOptions = [
    "Full-time", "Part-time", "Contract", "Temporary", "Internship", "Volunteer", "Freelance"
];

const EditModal = ({ show, handleClose, handleSave, field, value, setValue }) => {

    console.log('inside the edit model')
    const handleToggle = (option) => {
        if (value.includes(option)) {
            console.log('value editmodel',value)
            setValue(value.filter(v => v !== option));
        } else {
            setValue([...value, option]);
        }
    };

     const isMultiSelect = field === "Schedule" || field === "Job Type";

    const options = field === "Schedule" ? scheduleOptions : jobTypeOptions;

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit {field}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isMultiSelect ? (
                    <div className="d-flex flex-wrap">
                        {options.map(option => (
                            <Button
                                key={option}
                                variant={value.includes(option) ? "primary" : "outline-primary"}
                                onClick={() => handleToggle(option)}
                                className="m-1"
                            >
                                {option}
                            </Button>
                        ))}
                    </div>
                ) : (
                     <Form.Control
                        type="text"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                    />
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditModal;
