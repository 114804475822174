import React from "react";
import { Navbar, Nav, Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

const NavbarComponent = () => {
  return (
    <Navbar
      expand="lg"
      className="navbar navbar-expand-lg navbar-light"
      style={{ background: "rgb(22, 76, 134)" }}
    >
      <Container>
        <Navbar.Brand as={Link} to="/home" className="text-white">
          <h2 style={{ color: "white", margin: "0" }}>Search</h2>
          <span style={{ color: "yellow", fontSize: "1.5rem" }}>Jobs</span>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            <Nav.Link as={Link} to="#" className="nav-link text-white">
              Post a job
            </Nav.Link>
            <Nav.Link as={Link} to="#" className="nav-link text-white">
              Find CVs
            </Nav.Link>
            <Nav.Link as={Link} to="#" className="nav-link text-white">
              Products
            </Nav.Link>
            <Nav.Link as={Link} to="#" className="nav-link text-white">
              Resources
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>

        <Navbar.Collapse className="justify-content-end">
          <Nav>
            <Button
              as={Link}
              to="/helpcenter"
              variant="link"
              className="text-white text-decoration-none"
            >
              <span className="me-2">Help Center</span>
              <FontAwesomeIcon icon={faQuestionCircle} size="lg" />
            </Button>

            <span className="mt-2 ms-2 me-2 text-white">|</span>

            <Button as={Link} to="/signup" variant="outline-light">
              Sign In
            </Button>

            <span className="mt-2 ms-2 me-2 text-white">|</span>

            <Button
              as={Link}
              to="/employers"
              variant="link"
              className="text-white text-decoration-none"
            >
              Employers
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;
