import { Image } from "react-bootstrap";
import "../../assets/css/Card.css";
import BussinessImage from "../../assets/images/Business.webp";
import ComputerImage from "../../assets/images/computer.webp";
import DatascienceImage from "../../assets/images/datascience.webp";
import HealthImage from "../../assets/images/health.webp";
import React from "react";

export default function Card(){
    return(<div className="maincd">
        <h5 className="headtext">Categories</h5>
        <div className="cd">
            <div className="incd">
                <div className="overimg">
                    <Image className="images" src={BussinessImage} />
                </div>
                <h5 className="heading">Business</h5>
                <p className="ptag">Lorem ipsum dolor sit amet,</p>
                <p className="ptag">consectetur adipiscing elit, sed..</p>
            </div>

            <div className="incd">
                <div className="overimg">
                    <Image className="images" src={ComputerImage}/>
                </div>
                <h5 className="heading">Computer Science</h5>
                <p className="ptag">Lorem ipsum dolor sit amet,</p>
                <p className="ptag">consectetur adipiscing elit, sed..</p>
            </div>

            <div className="incd">
                <div className="overimg">
                    <Image className="images" src={DatascienceImage}/>
                </div>
                <h5 className="heading">Data Science</h5>
                <p className="ptag">Lorem ipsum dolor sit amet,</p>
                <p className="ptag">consectetur adipiscing elit, sed..</p>
            </div>

            <div className="incd">
                <div className="overimg">
                    <Image className="images" src={HealthImage}/>
                </div>
                <h5 className="heading">Health And Fitness</h5>
                <p className="ptag">Lorem ipsum dolor sit amet,</p>
                <p className="ptag">consectetur adipiscing elit, sed..</p>
            </div>
        </div>
    </div>
    
    )
}