
import React from "react";
import { Link, useLocation } from 'react-router-dom';

import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import LoginImage from "../../assets/images/on-removebg-preview.svg";
import Navbar from "../../components/Navbar";
import withRouter from '../../components/common/withRouter';
import Footer from "../../components/footerAccessibilityPageFooter";

const UserType = (props) => {

    document.title = "Authentication";

    const location = useLocation();
    const email = location.state && location.state.email;
    const mobileNumber = location.state && location.state.mobileNumber
    const getStateObject = (userType) => {
        return email
            ? { userType: userType, email }
            : mobileNumber
                ? { userType: userType, mobileNumber }
                : null;
    };
    return (
        <>
            <Navbar />
            <div class="d-flex align-items-center signup-bg">
                <Container className='mt-4'>
                    <Row className='d-flex justify-content-between align-items-center'>
                        <Col md={5}>
                            <Card className='signup-card text-dark'>
                                <h5>Ready for the next step?</h5>
                                <p> Create an account for tools to help you</p>
                                <br />
                                <Button className="btn-color-primary py-3">
                                    <strong>
                                        <Link to="/auth/createAccount" className="text-white text-decoration-none fs-5" state={getStateObject('JobSeeker')}> Jobseeker </Link>
                                    </strong>
                                </Button>
                                <Button className="btn-color-primary py-3 mt-3">
                                    <strong>
                                        <Link to="/auth/createAccount" className="text-white text-decoration-none fs-5" state={getStateObject('Employer')}> Employer</Link>
                                    </strong>
                                </Button>
                            </Card>
                        </Col>

                        <Col md={5} className='d-none d-md-block'>
                            <Image className="login-img" src={LoginImage} fluid />
                        </Col>

                    </Row>
                </Container>
            </div>
            <Footer />
        </>
    )
}
export default withRouter(UserType)