import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

function BuildInResumePageFooter() {
  return (
    <footer className="bg-white text-dark mt-5">
      <Container>
        <Row className="py-2">
          <Col md={12} className="text-center">
            © {new Date().getFullYear()} Your Company Name -{" "}
            <Link
              to="/home"
              className="text-dark mx-3"
              style={{ textDecoration: "none" }}
            >
              Accessibility at Your Company Name.
            </Link>
            <Link
              to="/home"
              className="text-dark mx-3"
              style={{ textDecoration: "none" }}
            >
              Privacy Centre
            </Link>
            <Link
              to="/home"
              className="text-dark mx-3"
              style={{ textDecoration: "none" }}
            >
              Cookies
            </Link>
            <Link
              to="/home"
              className="text-dark mx-3"
              style={{ textDecoration: "none" }}
            >
              Privacy
            </Link>
            <Link
              to="/home"
              className="text-dark mx-3"
              style={{ textDecoration: "none" }}
            >
              Terms
            </Link>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default BuildInResumePageFooter;
