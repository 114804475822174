import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Navbar from "../../components/Navbar";
import Footer from "../../components/footerAccessibilityPageFooter";
import pswimg from "../../assets/images/psw-flow1.svg";
import { Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from "react-bootstrap";
import "../../styles/Forgetpsw.css";

import {Forget_Passwords}from '../../helpers/backendHelper';

const ForgetPassword = () => {
 
  const navigate = useNavigate();


  const formik = useFormik({
    initialValues: {
      email: '',  
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('please enter a valid email'),
    }),
    onSubmit: async (values)  => {
      try {
        const response = await Forget_Passwords({ email: values.email });
        navigate('/forgetpassword/otp', { state: { email: values.email } });
        console.log("Email sent to: " + values.email);
        // alert("Otp sent successfully");
      } catch (error) {
        console.error("Failed to send email", error);
        alert('Failed to send email. Please try again later.');
      }
    },
  });

  return (
    <>
      <Navbar />
      <div className="d-flex align-items-center pswd">
        <Container className="mt-5">
          <Row className="pswd-row d-flex justify-content-between align-items-center">
            <Col md={6} lg={5}>
              <Card className="pswd-content">
                <h4 className="fw-bold">Forget Password?</h4>
                <p className="p-title">
                  Enter the email address associated with your account
                </p>
                <form onSubmit={formik.handleSubmit}>
                  <input
                    type="email"
                    name="email"
                    placeholder="@Enter your Email address"
                    className={`form-control mt-3 mb-2 py-2 input-height ${formik.touched.email && formik.errors.email ? 'is-invalid' : ''}`}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="invalid-feedback">{formik.errors.email}</div>
                  ) : null}
                  <Link to='/tryanotherway' className="text-dark">
                    <p className="text-decoration-underline fw-bolder">
                      Try another way!
                    </p>
                  </Link>
                    <button type="submit" className="btn btn-color-primary input-height w-100 py-2 mt-3" >
                            <strong>Continue</strong>
                        </button>
                </form>
              </Card>
            </Col>
            <Col md={5} className="d-none d-md-block">
              <Image className="pswd-img" src={pswimg} alt="img" fluid />
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default ForgetPassword;

