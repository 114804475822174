import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";

const TopCompanyCard = ({ company }) => {
  const [isCardHovered, setIsCardHovered] = useState(false);

  const handleCardHover = () => {
    setIsCardHovered(true);
  };

  const handleCardHoverOut = () => {
    setIsCardHovered(false);
  };

  return (
    <Link to={`/jobs`} style={{ textDecoration: "none" }}>
      <Card
        onMouseOver={handleCardHover}
        onMouseOut={handleCardHoverOut}
        border={isCardHovered ? "primary" : "dark"}
        style={{
          transition: "background-color 0.3s",
          backgroundColor: isCardHovered ? "#FEFEFE" : "white",
          boxShadow: "0 4px 8px rgba(1, 1, 2, 0.1)",
        }}
      >
        <Card.Body>
          <Card.Title>{company.name}</Card.Title>
          <Card.Text>
            Average Salary: {company.salary}{" "}
            <AiOutlineRight
              className={`chevronIcon ${
                isCardHovered
                  ? "text-primary rounded-circle  border border-primary p-1"
                  : ""
              }`}
            />
            <br />
            <Card.Link href="#">Job Openings</Card.Link>
          </Card.Text>
        </Card.Body>
      </Card>
    </Link>
  );
};

export default TopCompanyCard;
