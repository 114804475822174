import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const InterviewScheduler = ({ showModal, handleScheduleInterview, handleCloseModal }) => {

  
  const [interviewType, setInterviewType] = useState('online');
  const [location, setLocation] = useState('');
  const [interviewDateTime, setInterviewDateTime] = useState(new Date());
  const [interviewDuration, setInterviewDuration] = useState('');



  const formatLocalDateTime = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const handleInterviewClick = () => {
    const interviewData = {
      interviewType,
      location,
      interviewDateTime: formatLocalDateTime(interviewDateTime),
      interviewDuration
    };
    handleScheduleInterview(interviewData);
    handleCloseModal();
  };

  const handleDateChange = (date) => {
    setInterviewDateTime(date);
  };

  return (
    <>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Schedule Interview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="interviewType">
              <Form.Label>Interview Type</Form.Label>
              <Form.Control
                as="select"
                value={interviewType}
                onChange={(e) => setInterviewType(e.target.value)}
              >
                <option value="online">Online</option>
                <option value="offline">Offline</option>
              </Form.Control>
            </Form.Group>

            {interviewType !== 'online' && (
              <Form.Group controlId="location">
                <Form.Label>Location</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Location"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                />
              </Form.Group>
            )}

            <Form.Group controlId="interviewDateTime">
              <Form.Label>Interview Date and Time</Form.Label>
              <div className="d-flex align-items-center">
                <DatePicker
                  selected={interviewDateTime}
                  onChange={handleDateChange}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15} // Adjust the interval if needed
                  dateFormat="yyyy-MM-dd HH:mm"
                  className="form-control"
                />
              </div>
            </Form.Group>

            <Form.Group controlId="interviewDuration">
              <Form.Label>Interview Duration (in minutes)</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Duration"
                value={interviewDuration}
                onChange={(e) => setInterviewDuration(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className='btn-color-primary' onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" className='btn-color-primary' onClick={handleInterviewClick}>
            Schedule Interview
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InterviewScheduler;
