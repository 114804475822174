import { Field, Formik, Form as FormikForm } from 'formik';
import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Pagination from 'react-bootstrap/Pagination';
import { BsBookmarkDash, BsBookmarkDashFill } from "react-icons/bs";
import { CiFilter, CiGrid41 } from "react-icons/ci";
import { FaStar } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { RxHamburgerMenu } from "react-icons/rx";
import { Link, useParams } from 'react-router-dom';
import Select from 'react-select';
import * as Yup from 'yup';
import "../assets/css/ViewJobs.css";
import Navbar from "../components/Navbar";
import { deleteSavedJobs, getAllJobs, getCityState, getJobSuggestions, savedJobs, searchAndFilter } from '../helpers/backendHelper';
import ViewJobsFilters from './ViewJobsFilters';

const ViewJobsNew = () => {

    document.title = "ViewJobs";

    const { params } = useParams();

    console.log("params",params);
    const {jobsTitle, jobslocation, isSearchFromHome } = useParams();

    const [isSearchedHome,setIsSearchedHome] = useState(isSearchFromHome);

    const [defaultCheck, setDefaultCheck] = useState('');
    const [isToggled, setIsToggled] = useState(false);
    const [isToggledMark, setIsToggledMark] = useState([]);
    const [show, setShow] = useState(false);
    const [jobs,setJobs] = useState([]);
    const [jobSuggestions, setJobSuggestions] = useState([]);
    const [allCityState, setAllCityState] = useState([]);
    const [searchfilter,setSearchfilter] = useState([]);
    const [mainFilterData,setMainFilterData] = useState({});

    const [jobTitle, setJobTitle] = useState(null);
    const [menuIsOpenTitle, setMenuIsOpenTitle] = useState(false);
    const [searchInputTitle, setSearchInputTitle] = useState("");
    const [joblocation, setJobLocation] = useState(null);
    const [menuIsOpenLocation, setMenuIsOpenLocation] = useState(false);
    const [searchInputLocation, setSearchInputLocation] = useState("");
    const [filteredOptions, setFilteredOptions] = useState([]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleDefaultChange = (event) => {
        console.log("Selected value:", event.target.value);
        setDefaultCheck(event.target.value); // Updates the selected value in state
    };

    const handleToggle = () => {
        setIsToggled(!isToggled);
    };
    
    const fetchSearchFromHomeData = async () =>{
        const payload = {
            jobTitle : jobsTitle,
            location : jobslocation,
            page : activePage
        }
        fetchSearchFilter(payload);
    }
    useEffect(() => {
        const isSearchHome = isSearchFromHome === 'true' || isSearchFromHome === true;
        if (isSearchHome) {
             fetchSearchFromHomeData();
            setIsSearchedHome(false);        
 
       }

    }, [isSearchedHome]);


    const fetchSearchFilter = async (data) => {
        try {
            const storeSearchFilter = await searchAndFilter(data);
            if(storeSearchFilter.statusCode === 200) {
                console.log("storeSearchFilter",storeSearchFilter);
                setJobs(storeSearchFilter.jobs);
            }
        } catch (error) {
            console.log("error of fetchSearchFilter",error);
        }
    }


    const validationschema1 = Yup.object({
        jobTitle : Yup.string(),
        location : Yup.string()
    });

    const handleSubmit = (values, {setSubmitting}) => {
        console.log("values",values);
        setSubmitting(false);
        const payload={
            jobTitle : values.jobTitle,
            location : values.location,
            page : 1
        };
        fetchSearchFilter(payload);
    };
    
    const fetchjobs = async () => {
        try {
            const response = await getAllJobs();
            if(response && response.statusCode === 200){
                console.log("jobs detail",jobs);
                setJobs(response.jobs);
                console.log("jobs.length",jobs.length);
            }
        }
        catch(error) {
            console.log("error",error);
        }
    }

    const postjobs = async (jobId) => {
        try{
            await savedJobs(jobId);
            console.log(`job ${jobId} saved successfully`);
        }
        catch(error) {
            console.log(`failed to save job ${jobId}`,error);
        }
    }

    const deletejobs = async (jobId) => {
        try {
            await deleteSavedJobs(jobId);
            console.log(`job ${jobId} deleted successfully`);
        }
        catch (error) {
            console.log(`failed to delete job ${jobId}`,error);
        }
    }

    const handleFilterData = (data) => {
        setMainFilterData(data);
    }


    //  save & unsave bookmark

    // const handleToggleMark = (jobId) => {
    //     setIsToggledMark((prevSavedJobs) =>
    //       prevSavedJobs.includes(jobId)
    //         ? prevSavedJobs.filter((id) => id !== jobId)
    //         : [...prevSavedJobs, jobId]
    //     );

    //     isToggledMark.includes(jobId) ? deletejobs() : postjobs() ;
    // };

    const handleToggleMark = (jobId) => {
        console.log("jobId",jobId);
        setIsToggledMark((prevSavedJobs) => {
            const isJobSaved = prevSavedJobs.includes(jobId);
    
            // Trigger POST or DELETE based on whether the job is already saved
            if (isJobSaved) {
                deletejobs(jobId); // Unsaving the job
            } else {
                postjobs(jobId); // Saving the job
            }
    
            // Update the state
            return isJobSaved
                ? prevSavedJobs.filter((id) => id !== jobId) // Remove from saved jobs
                : [...prevSavedJobs, jobId]; // Add to saved jobs
        });
    };
    
    const fetchCityState = async () => {
        try {
        const response = await getCityState();
        if (response && response.statusCode === 200) {
            const data = response.cities;
            setAllCityState(data);
        }
        } catch (error) {
        console.error("Error fetching city/state data:", error);
        }
    };

    const fetchJobSuggestion = async () => {
        try {
        const response = await getJobSuggestions();
        if (response && response.statusCode === 200) {
            const data = response.jobTitle.map(job => `${job.name}`);
            setJobSuggestions(data);
        }
        } catch (error) {
        console.error("Error fetching job data:", error);
        }
    }

    const handleSelectChange = (selectedOption) => {
        setJobTitle(selectedOption ? selectedOption.value : "");
        setMenuIsOpenTitle(false);
    };

    const handleInputChange = (inputValue) => {
        setSearchInputTitle(inputValue);
        setMenuIsOpenTitle(inputValue.length > 2);
    };

    const CustomSelectTitle = ({ field, form, options, placeholder }) => {
        return (
            <Select
                className='w-100'
                name={field.name}
                value={options.find(option => option.value === field.value)}
                onChange={(option) => form.setFieldValue(field.name, option ? option.value : '')}
                options={options}
                isSearchable
                isClearable
                placeholder={placeholder}
                components={{ DropdownIndicator: null }}
            />
        );
    };

    const optionsTitle = jobSuggestions.map((job) => ({
        value: job?.toLowerCase(),
        label: job,
    }));

    const handleSelectChangeLocation = (selectedOption) => {
        setJobLocation(selectedOption ? selectedOption.value : "");
        setMenuIsOpenLocation(false);
    };

    const handleInputChangeLocation = (inputValue) => {
        setSearchInputLocation(inputValue);
        setMenuIsOpenLocation(inputValue.length > 2);
    };

    const optionsLocation = allCityState.map((location) => ({
        value:location ? location?.city?.toLowerCase():{},
        label: `${location.city}, ${location.state}`
    } ));

    const CustomSelectLocation = ({ field, form, options, placeholder ,handleInputChange }) => {
        return (
            <Select
                className='w-100'
                name={field.name}
                value={options.find(option => option.value === field.value)}
                onChange={(option) => form.setFieldValue(field.name, option ? option.value : '')}
                options={options}
                isSearchable
                isClearable
                placeholder={placeholder}
                onInputChange={handleInputChange}
                components={{ DropdownIndicator: null }}
            />
        );
    };
    
    useEffect(() => {
        fetchjobs();
        fetchCityState();
        fetchJobSuggestion();
    },[]);

    
    // pagination

    // const [data, setData] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const itemsPerPage = 8;

    // Fetch data from the API when the component mounts

    // useEffect(()=>{
    //     fetchjobs();
    // },[]);

    // Calculate the total number of pages
    const totalPages = Math.ceil(jobs.length / itemsPerPage);

    // Get the jobs for the current page
    const indexOfLastJob = activePage * itemsPerPage;
    const indexOfFirstJob = indexOfLastJob - itemsPerPage;
    const currentJobs = jobs.slice(indexOfFirstJob, indexOfLastJob);

    // Handle page change
    const handlePageChange = (pageNumber) => setActivePage(pageNumber);

    // Render pagination numbers dynamically
    const renderPageNumbers = () => {
        const pages = [];

        // Display pagination for less than 5 pages without ellipsis
        if (totalPages <= 5) {
        for (let number = 1; number <= totalPages; number++) {
            pages.push(
            <Pagination.Item key={number} active={number === activePage} onClick={() => handlePageChange(number)}>
                {number}
            </Pagination.Item>
            );
        }
        } else {
            // Display ellipsis and limited page numbers if more than 5 pages
            if (activePage > 2) {
                pages.push(<Pagination.Item key={1} onClick={() => handlePageChange(1)}>{1}</Pagination.Item>);
                if (activePage > 3) pages.push(<Pagination.Ellipsis key="start-ellipsis" />);
            }

            for (let number = Math.max(1, activePage - 1); number <= Math.min(totalPages, activePage + 1); number++) {
                pages.push(
                <Pagination.Item key={number} active={number === activePage} onClick={() => handlePageChange(number)}>
                    {number}
                </Pagination.Item>
                );
            }

            if (activePage < totalPages - 2) {
                pages.push(<Pagination.Ellipsis key="end-ellipsis" />);
                pages.push(
                <Pagination.Item key={totalPages} onClick={() => handlePageChange(totalPages)}>
                    {totalPages}
                </Pagination.Item>
                );
            }
        }

        return pages;
    };


    return (
        <Container fluid className="p-0 bg-white viewjobs">
            <Navbar />

            <div className="job-listing p-1 pt-5 p-sm-5 ">
                <h2 className="fs-1 fw-bolder text-center">Job Listing</h2>
                <p className="mt-4 fs-5 text-center">We delivered blazing fast & striking work solution</p>

                <Container className='mt-4'>
                    <Row className="justify-content-center my-4 my-sm-0">
                        <Col xs={11} sm={10} md={12} lg={9} xl={10}>
                            <Card style={{ maxWidth:"1400px", borderRadius: '10px', backgroundColor: '#fff', borderColor:"" }}>
                                <Formik
                                    initialValues={{
                                        jobTitle : "",
                                        location : "",
                                    }}
                                    validationSchema={validationschema1}
                                    onSubmit={(values,actions) => handleSubmit(values, actions)}
                                >
                                    {({isSubmitting}) => (
                                        <FormikForm>
                                            <InputGroup className="ps-md-4 d-flex flex-column flex-md-row align-items-stretch">
                                                <div className="w-100 d-flex flex-column justify-content-between align-items-start me-md-3 py-2 px-3 px-md-0" style={{ flex: '1' }}>
                                                    <Form.Label className='category-title'>What are you looking for?</Form.Label>

                                                    <Field
                                                        name="jobTitle" // The name attribute for the field
                                                        component={CustomSelectTitle}
                                                        options={optionsTitle}
                                                        placeholder="Search jobTitle"
                                                        // handleInputChange={handleInputChange}
                                                    />
                                                </div>

                                                <div className="w-100 d-flex flex-column justify-content-between align-items-start border-md-start mt-3 mt-md-0 py-0 px-3 pb-3 py-md-2" style={{ flex: '1', paddingLeft: '10px', borderLeft: '1px solid #e8e8e8' }}>
                                                    <Form.Label className='ms-1 text-start category-title'>Location</Form.Label>

                                                    <Field
                                                        name="location" // The name attribute for the field
                                                        component={CustomSelectLocation}
                                                        options={optionsLocation}
                                                        placeholder="Select location"
                                                    />
                                                </div>

                                                <Button
                                                    className='joblist-btn'
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                >
                                                    SEARCH
                                                </Button>
                                            </InputGroup>
                                        </FormikForm>
                                    )}
                                </Formik>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className='p-0'>
                    <p className='fs-3 text-center filter-text'>Filter By</p>
                    <ViewJobsFilters />
                </Offcanvas.Body>
            </Offcanvas>

            <Container className='mt-5'>
                <Row>
                    <Col lg={4} xl={3} className='d-none d-lg-block'>
                        <p className='fs-3'>Filter By</p>
                        <ViewJobsFilters sentDataToParent={handleFilterData} searchApi={fetchSearchFilter} />
                    </Col>

                    <Col className='d-block d-lg-none'>
                        <Button className='w-100 p-2 filter-btn bg-transparent d-flex justify-content-center align-items-center text-dark my-4' onClick={handleShow}><p style={{fontWeight: "500",fontSize:"19px"}}><CiFilter className='me-2 fw-bolder' />Filter</p></Button>
                    </Col>

                    <Col lg={8} xl={9}>
                        <div className='d-flex justify-content-between mb-3'>
                            <p className='jobsfound-text text-secondary mt-2 me-2'><span className='text-dark jobsfound-text me-2 '></span>jobs found</p>
                            <div className='d-flex align-items-center'>
                                <h5 className='me-2 mt-2 jobsfound-text'>Sort: </h5>
                                <InputGroup className='px-3 me-2 bg-muted border border-muted rounded-pill'>
                                    <Form.Control
                                        className='forms-control'
                                        as="select"
                                        size="sm"
                                        value={defaultCheck}
                                        onChange={handleDefaultChange}
                                        style={{width: "90px"}}
                                    >
                                        <option value="Default">Default</option>
                                        <option value="Latest">Latest</option>
                                        <option value="Oldest">Oldest</option>
                                    </Form.Control>
                                    <InputGroup.Text style={{ fontSize: '15px', padding: '0px', border: 'none', boxShadow: 'none', backgroundColor: 'white', cursor: 'pointer' }}>
                                        <IoIosArrowDown />
                                    </InputGroup.Text>
                                </InputGroup>
                                <div className='grid-toggle' onClick={handleToggle}>
                                    {isToggled ? <RxHamburgerMenu className='m-auto' /> : <CiGrid41 className='m-auto' />}
                                </div>
                            </div>
                        </div>
                        
                        <div>
                            {
                                isToggled ? 
                                    <div>
                                        <Row>
                                            {currentJobs.map((grid)=>
                                                <Col sm={6} key={grid._id}>
                                                    <Card className="job-card2 mb-3">
                                                        <Card.Body>
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <div className="job-card-star">
                                                                    <FaStar className="star-icon" />
                                                                </div>
                                                                <div className="job-card-avatar2 text-center">
                                                                    <Link className="avatar-icon" to='/'>A</Link>
                                                                </div>
                                                                <div className='grid-toggle2' onClick={()=>handleToggleMark(grid._id)}>
                                                                    {isToggledMark.includes(grid._id) ? <BsBookmarkDashFill className='m-auto' /> : <BsBookmarkDash className='m-auto' />}
                                                                </div>
                                                            </div>

                                                            <div className="mt-3">
                                                                <Badge bg="light" text="success" className="full-time-badge mt-4 mb-1">{grid.jobDetails.jobType}</Badge>
                                                                <h4 className="mt-2 mb-2 text-fulltime2 job-title2 cursor-pointer">{grid.jobBasics.jobTitle}</h4>
                                                                <p className="salary-range2 mt-1 mb-3 mt-xl-3 mb-md-5">
                                                                {grid.salary?.range?.minimum}-{grid.salary?.range?.maximum} <span>/ yearly</span>
                                                                </p>
                                                            </div>
                                                            
                                                            <div className="d-flex justify-content-between align-items-center mt-3">
                                                            <p className="text-muted location">{grid.jobBasics.jobLocation.city}</p>
                                                            <Link to={`/job/detail/${grid._id}`} className='details-button'>DETAILS</Link>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            )}
                                        </Row> 

                                    </div> 


                                    :   <div>

                                        {currentJobs.map((job)=>
                                            <div className="job-card1" key={job._id}>
                                                <Row className='w-100 m-0'>
                                                    <Col xs={12} md={5} lg={4} xl={5}  className='d-flex align-items-center'>
                                                        <div className="job-card-star">
                                                            <FaStar className="star-icon" />
                                                        </div>
                                                        
                                                        <div className="job-card-left m-sm-1 m-2">
                                                            <div className="job-card-avatar">
                                                                <Link className="avatar-circle" to='/'>A</Link>
                                                            </div>
                                                            <div className="job-card-info text-start">
                                                                <Link className='text-fulltime' to='/'>{job.jobDetails.jobType}</Link>
                                                                <Link className='job-title text-fulltime2' to=''>{job.jobBasics.jobTitle}</Link>
                                                            </div>
                                                        </div>
                                                    </Col>

                                                    <Col sm={7} md={4} lg={4} xl={4} className='d-flex align-items-center'>
                                                        <div className="m-sm-1 m-2">
                                                            <p className="job-location text-muted text-start cursor-pointer">{job.jobBasics.jobLocation.city}</p>
                                                            <p className="job-salary text-start">{job.salary?.range?.minimum}-{job.salary?.range?.maximum}<span className='job-experience'> / yearly</span></p>
                                                        </div>
                                                    </Col>

                                                    <Col sm={5} md={3} lg={4} xl={3} className='detail-btn-content d-flex align-items-center justify-content-end'>
                                                        <div className="d-flex align-items-center m-sm-1 m-2">
                                                            <div className='grid-toggle2 me-3' onClick={()=>handleToggleMark(job._id)}>
                                                                {isToggledMark.includes(job._id) ? <BsBookmarkDashFill className='m-auto' /> : <BsBookmarkDash className='m-auto' />}
                                                            </div>
                                                            <Link to={`/job/detail/${job._id}`} className='details-button'>DETAILS</Link>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                
                                            </div>
                                        )}
                                    </div>        
                            }

                            <Pagination className="justify-content-center mt-5">
                                <Pagination.First onClick={() => handlePageChange(1)} disabled={activePage === 1} />
                                <Pagination.Prev onClick={() => handlePageChange(activePage - 1)} disabled={activePage === 1} />
                                {renderPageNumbers()}
                                <Pagination.Next onClick={() => handlePageChange(activePage + 1)} disabled={activePage === totalPages} />
                                <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={activePage === totalPages} />
                            </Pagination>


                        </div>

                    </Col>

                </Row>
            </Container>

        </Container>
    )
}

export default ViewJobsNew;