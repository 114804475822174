import React, { useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  ProgressBar,
  Row,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import BuildInResumePageFooter from "../../../components/BuildInResumePageFooter";
import useFormikValidation from "../../../helpers/FormikValidation";
import { phoneNumberValidation } from "../../../helpers/Validation/ResumeValidation";
import { jobSeekerResumeUpdate } from "../../../helpers/backendHelper";
import "../../../styles/ThemeColors.css";

function PhoneNumberForm({ onContinue }) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showNumber, setShowNumber] = useState(false);
  const [countryCode, setCountryCode] = useState("+1");

  const navigate = useNavigate();

  const { formik, renderFormField } = useFormikValidation(
    {
      mobileNumber: "",
    },
    phoneNumberValidation,
    (values) => {
      if (formik.isValid) {
        jobSeekerResumeUpdate({ mobileNumber: values.mobileNumber })
          .then((x) => {
            navigate("/resume/addressForm");
          })
          .catch((e) => {
            console.log("error", e);
            Swal.fire({
              icon: "error",
              title: "Error",
              text: e,
              showCancelButton: true,
              showConfirmButton: false,
              cancelButtonText: "Close",
            }).then((x) => {
              if (x.isDismissed) {
                // onClose();
              }
            });
          })
          .finally(() => {
            // setLoading(false);
          });
      }
    }
  );

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleCheckboxChange = () => {
    setShowNumber(!showNumber);
  };

  const handleCountryCodeChange = (e) => {
    setCountryCode(e.target.value);
  };

  const handleContinue = (e) => {
    e.preventDefault();

    onContinue();
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col xs={12} sm={12} md={12} lg={6}>
          <div className="card p-4">
            {/* Back Arrow and Save & Exit Link */}
            <Row className="mt-3">
              <Col xs="auto">
                <a href="/" className="btn btn-link text-colors">
                  <i className="bi bi-arrow-left"></i> Back
                </a>
              </Col>
              <Col className="text-end">
                <a href="/" className="btn btn-link text-colors">
                  Save & Exit
                </a>
              </Col>
            </Row>

            {/* Progress Bar */}
            <Row className="mt-3">
              <Col>
                <ProgressBar animated now={30} label="30%" />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <h2>Would you like to add a phone number to your resume?</h2>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <p>Only provided to employers you apply or respond to</p>
              </Col>
            </Row>
            <Form onSubmit={formik.handleSubmit}>
              {/* Country Code Dropdown */}
              <Form.Group controlId="countryCode">
                <Form.Label>Country Code</Form.Label>
                <Form.Control
                  as="select"
                  value={countryCode}
                  onChange={handleCountryCodeChange}
                >
                  <option value="+1">+1 (USA)</option>
                  <option value="+44">+44 (UK)</option>
                  {/* Add more country codes as needed */}
                </Form.Control>
              </Form.Group>

              {/* Phone Number Input */}
              <Form.Group controlId="phoneNumber">
                <Form.Label>Phone Number</Form.Label>

                {renderFormField(
                  "mobileNumber",
                  "Mobile Number",
                  "Enter your phonNumber"
                )}
              </Form.Group>

              {/* Checkbox for "Show me number on Indeed" */}
              <Form.Group controlId="showNumberCheckbox" className="mt-3">
                <Form.Check
                  type="checkbox"
                  label="Show me number on Indeed"
                  checked={showNumber}
                  onChange={handleCheckboxChange}
                />
              </Form.Group>

              <Row className="mt-3 d-flex align-items-center">
                <Col>
                  <p>
                    By submitting the form with this box ticked, you confirm
                    that you are the primary user and subscriber to the
                    telephone number provided, and you agree to receive calls
                    (including using artificial or pre-recorded voice), texts
                    and WhatsApp messages from Indeed and employers who use
                    Indeed on the telephone number provided above.
                  </p>
                </Col>
              </Row>

              {/* Continue Button */}
              <Button
                variant="primary"
                type="submit"
                className="mt-3 btn-colors"
                // onClick={handleContinue}
              >
                Continue
              </Button>
            </Form>

            {/* Display Phone Number and Checkbox status */}
            <Row className="mt-3">
              <Col>
                {phoneNumber && (
                  <Alert variant="info">
                    Country Code: {countryCode}
                    <br />
                    Phone Number: {phoneNumber}
                    <br />
                    Show Number on Indeed: {showNumber ? "Yes" : "No"}
                  </Alert>
                )}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <div>
        <BuildInResumePageFooter />
      </div>
    </Container>
  );
}

export default PhoneNumberForm;
