import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const TopPayingCompanies = ({ companies }) => {
  const [companyLogos, setCompanyLogos] = useState({});
  const [isCardHovered, setIsCardHovered] = useState(false);

  const handleCardHover = () => {
    setIsCardHovered(true);
  };

  const handleCardHoverOut = () => {
    setIsCardHovered(false);
  };

  useEffect(() => {
    const importLogos = async () => {
      const logos = {};
      for (const company of companies) {
        const logoModule = await import(
          `../assets/companylogos/${company.logo}`
        );
        logos[company.logo] = logoModule.default;
      }
      setCompanyLogos(logos);
    };

    importLogos();
  }, [companies]);

  return (
    <Container className="mt-4">
      <h2>Browse Top-Paying Companies By Industry</h2>
      <Row xs={1} md={3} className="mt-3">
        {companies.map((company, index) => (
          <Col key={index} className="mb-3">
            <Card
              onMouseEnter={handleCardHover}
              onMouseLeave={handleCardHoverOut}
              border={isCardHovered ? "secondary" : "dark"} className="border-0 p-2"
            >
              <Link to={`/jobs`} className="text-decoration-none">
                <Card.Img
                  variant="top"
                  src={companyLogos[company.logo]}
                  alt={company.name} className="salary-guide-card-image border"/>
                <Card.Body>
                  <Card.Title className="mb-2">{company.name}</Card.Title>
                  <Card.Link
                    as={Link}
                    to="/jobs"
                    className="card-link"
                    style={{ color: "inherit" }}
                  >
                    Job Openings
                  </Card.Link>
                  <Card.Link href="#" className="card-link">
                    Reviews
                  </Card.Link>
                  <Card.Link href="#" className="card-link">
                    Salaries
                  </Card.Link>
                </Card.Body>
              </Link>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default TopPayingCompanies;
