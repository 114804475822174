// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCWGgURALM75_kv9QmlnWFue82LxKNps_I",
  authDomain: "walkupp-web-aca01.firebaseapp.com",
  projectId: "walkupp-web-aca01",
  storageBucket: "walkupp-web-aca01.appspot.com",
  messagingSenderId: "681168975733",
  appId: "1:681168975733:web:5b67b484dafb946467053c"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);