import React from "react";
import image from "../../assets/images/jobseekerimg.png";
import "../../assets/css/Jobseeker.css";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row
} from "react-bootstrap";
const Jobseeker = () => {
  return (
    <div>
      <div className="Main-content">
      <div className="img-content">
        <img src={image} alt="image" />
      </div>
      <div className="sub-content">
        <h2>APPLY YOUR</h2>
        <span>RESUME NOW</span>
        <p>
          Lorem ipsum dolor sit amet,consectetur adipscing elit,sed do eiusmod
          tempor
        </p>
      </div>
    </div>
    <Container  className="mt-3" >
      <Row xs={1} md={1} lg={1} className="p-5">
        <Col className="text-center p-4">
            <h2 className="fs-1">Over, <span className="text-info">5,000 Jobs</span> are Waiting for you</h2>
            <p className="fs-5">Work With the best companies,hire the experienced professionals</p>
        </Col>
        <Col className="w-90 ">
        <Card className="mt-4 shadow border-0 w-90 m-auto">
                  <Card.Body className="d-flex ">
                    <InputGroup>
                      <Form.Select
                        aria-label="jobTitle"
                        
                      >
                        <option value="">Select jobTitle</option>
                       
                      </Form.Select>

                      <Form.Select
                        aria-label="Location"
                       
                      >
                        <option value="">Select location</option>
                       
                      </Form.Select>
                    </InputGroup>
                    <Button
                      className="form-control w-25 home-btn btn-color-primary"
                      type="button"
                     
                    >
                      Search
                    </Button>

                  </Card.Body>
                </Card>
        </Col>
      </Row>
    </Container>
    </div>
    
  );
};
export default Jobseeker;
