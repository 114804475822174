import { zodResolver } from '@hookform/resolvers/zod';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import '../../../assets/css/employer/jobDetails.css';
import EmployerNavBar from '../../../components/Employer/EmployerNavbar';
import EmployerSidebar from '../../../components/EmployerSidebar';
import { employerPostJob } from '../../../helpers/backendHelper';
import { jobDetailsSchema } from '../../../helpers/Validation/jobPostValidation';
import { isDraft } from '@reduxjs/toolkit';


const CreateJobDetails = () => {

  const params = useParams();
  const job_id = params.job_id
  console.log('job_id',job_id)
  const navigate = useNavigate();

  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const { register, handleSubmit, watch, formState: { errors }, setValue, control } = useForm({
    resolver: zodResolver(jobDetailsSchema),
    defaultValues: {
      jobType: [],
      schedule: [],
      plannedDate: 'No',
      date: '',
      peopleNeeded: '',
      recruitmentTimeline: 'More than 4 weeks',
    },
  });

  const onSubmit = (data) => {
    const payload = {
      jobId:job_id,
      isDraft:true,
      jobDetails:{
        jobType: data.jobType,
        schedule: data.schedule,
        numberOfPositions: data.peopleNeeded,
        recruitmentTimeline: data.recruitmentTimeline,
        plannedDate: data.plannedDate,
        ...(data.plannedDate === 'Yes' && { date: data.date }),
      }

    };
    console.log('payload for jobdetails',payload)
    employerPostJob(payload)
    .then((x) => {
        if (x && x.statusCode === 200) {
             navigate(`/employer/payDetails/${job_id}`);
        }
    })
    .catch((e) => {
        console.log("error", e);
        Swal.fire({
            icon: "error",
            title: "Failed to Job Post",
            text: "Error occurred",
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: "Close",
        });
    });
  };

   const watchPlannedDate = watch('plannedDate');
  const watchJobType = watch('jobType');
  const watchSchedule = watch('schedule');

   const toggleJobType = (value) => {
    const currentSelection = watch('jobType') || [];
    const updatedSelection = currentSelection.includes(value)
      ? currentSelection.filter((item) => item !== value)
      : [...currentSelection, value];

    setValue('jobType', updatedSelection, { shouldValidate: true, shouldDirty: true });
  };

   const toggleSchedule = (value) => {
    const currentSelection = watch('schedule') || [];
    const updatedSelection = currentSelection.includes(value)
      ? currentSelection.filter((item) => item !== value)
      : [...currentSelection, value];

    setValue('schedule', updatedSelection, { shouldValidate: true, shouldDirty: true });
  };

  const closeSidebar = (event) => {
    if (!event.target.closest("#sidebar-wrapper") && sidebarExpanded) {
      setSidebarExpanded(false);
      const wrapper = document.getElementById("wrapper");
      wrapper.classList.remove("toggled");
    }
  };

  return (

    <>
      <div id="wrapper" onClick={closeSidebar}>
        <EmployerSidebar sidebarExpanded={sidebarExpanded} />
        <div id="navbar-wrapper">
          <EmployerNavBar />
        </div>
        <div className="container mt-5 add-job-container">
          <h2 className="text-center">Add Job Details</h2>
          <Form onSubmit={handleSubmit(onSubmit)}>

            {/* Job Type Section */}
            <Form.Group controlId="jobType" className="mb-4">
              <Form.Label>Job Type *</Form.Label>
              <div className="job-type-buttons">
                {['Full-time', 'Permanent', 'Fresher', 'Contractual / Temporary', 'Freelance', 'Internship', 'Part-time', 'Volunteer', 'Other'].map((type) => (
                  <Button
                    key={type}
                    variant={watchJobType.includes(type) ? 'primary' : 'outline-primary'}
                    className="m-1"
                    onClick={() => toggleJobType(type)}
                  >
                    {type}
                  </Button>
                ))}
              </div>
              {errors.jobType && <p className="text-danger">{errors.jobType.message}</p>}
            </Form.Group>

            {/* Schedule Section */}
            <Form.Group controlId="schedule" className="mb-4">
              <Form.Label>Schedule *</Form.Label>
              <div className="schedule-buttons">
                {['Fixed shift', 'Day shift', 'Monday to Friday', 'Morning shift', 'Evening shift', 'Night shift', 'US shift', 'UK shift', 'Overnight shift', 'Rotational shift', 'Weekend availability', 'Other'].map((shift) => (
                  <Button
                    key={shift}
                    variant={watchSchedule.includes(shift) ? 'primary' : 'outline-primary'}
                    className="m-1"
                    onClick={() => toggleSchedule(shift)}
                  >
                    {shift}
                  </Button>
                ))}
              </div>
              {errors.schedule && <p className="text-danger">{errors.schedule.message}</p>}
            </Form.Group>

            {/* Planned Date Section */}
            <Form.Group controlId="plannedDate" className="mb-4">
              <Form.Label>Is there a planned start date for this job?</Form.Label>
              <Controller
                name="plannedDate"
                control={control}
                render={({ field }) => (
                  <div className="radio-group">
                    <label className="radio-label">
                      <input
                        type="radio"
                        value="Yes"
                        checked={field.value === 'Yes'}
                        onChange={() => field.onChange('Yes')}
                        className="custom-radio"
                      />{' '}
                      Yes
                    </label>
                    <label className="radio-label">
                      <input
                        type="radio"
                        value="No"
                        checked={field.value === 'No'}
                        onChange={() => field.onChange('No')}
                        className="custom-radio"
                      />{' '}
                      No
                    </label>
                  </div>
                )}
              />
              {errors.plannedDate && <p className="text-danger">{errors.plannedDate.message}</p>}
            </Form.Group>

            {/* Conditional Date Input */}
            {watchPlannedDate === 'Yes' && (
              <Form.Group controlId="date" className="mb-4">
                <Form.Label>Planned Start Date</Form.Label>
                <Form.Control
                  type="date"
                  {...register('date')}
                />
                {errors.date && <p className="text-danger">{errors.date.message}</p>}
              </Form.Group>
            )}

            {/* Number of People Needed */}
            <Form.Group controlId="peopleNeeded" className="mb-4">
              <Form.Label>Number of people you wish to hire for this job *</Form.Label>
              <Form.Control
                as="select"
                {...register('peopleNeeded')}
                className="custom-input"
              >
                <option>Select an option</option>
                {[1, 2, 3, 4, 5, 10, 20, 50, 100].map((number) => (
                  <option key={number} value={number}>
                    {number}
                  </option>
                ))}
              </Form.Control>
              {errors.peopleNeeded && <p className="text-danger">{errors.peopleNeeded.message}</p>}
            </Form.Group>

            {/* Recruitment Timeline */}
            <Form.Group controlId="recruitmentTimeline" className="mb-4">
              <Form.Label>Recruitment timeline for this job *</Form.Label>
              <Form.Control
                as="select"
                {...register('recruitmentTimeline')}
                className="custom-input"
              >
                <option value="Less than 2 weeks">Less than 2 weeks</option>
                <option value="2 to 4 weeks">2 to 4 weeks</option>
                <option value="More than 4 weeks">More than 4 weeks</option>
              </Form.Control>
              {errors.recruitmentTimeline && <p className="text-danger">{errors.recruitmentTimeline.message}</p>}
            </Form.Group>
            <div className="d-flex justify-content-center mt-4">
              <button className="submit-btn" type="submit">Continue</button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default CreateJobDetails;
