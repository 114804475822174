import "../../assets/css/Card.css";
import React from "react";
import Animation from "../vwtheme/Animation";
import Card from "../vwtheme/Card";
import Learn from "../vwtheme/Learn";
import Studygirl from "../vwtheme/Studygirl";
import { Col, Container, Image, Row } from "react-bootstrap";
import job from '../../assets/images/job.png'

export default function HomeDesign(){
    return(
    <div className="hd">
        <Learn/>
        <Card/>
        <Animation/>
        <Container>
        <Row className="mt-2">
          <Col lg={8} className="d-flex align-items-center">
            <h2 className="fw-bold">
              Life can only be understood<br />
              backward, but it must be lived <br />
              forwards
            </h2>
          </Col>
          <Col lg={4}>
            <Image src={job} />
          </Col>
        </Row>
      </Container>
        <Studygirl/>
    </div>
    )
}