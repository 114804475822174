import { faCheck, faDownload, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import '../assets/css/jobCardDetails.css';
import getImage from '../assets/images/user.webp';
import { IMAGE_HOST_URL } from '../helpers/urlHelper';

const UserProfileCard = (props) => {
  const { data, OnStatusChange, tab } = props;

  const downloadFile = (fileUrl) => {
    fetch(fileUrl)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'resume.pdf');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  return (
    <div>
      {data.map((candidate) => (
        <div key={candidate.id} className="profileCard">
          <div className="profileCard-header">
            <img src={`${candidate.jobSeekerProfileImage ? IMAGE_HOST_URL + candidate.jobSeekerProfileImage : getImage}`} alt="user" className="profileCard-image" />
            <div className="profileCard-info">
              <h2>{candidate.name}</h2>
              <p>{candidate.jobTitle}</p>
              <p>{candidate.email}</p>
            </div>
          </div>
          <div className="profileCard-footer">
            <button className="resume-btn" onClick={() => downloadFile(`http://localhost:3030/${candidate.resumePath}`)}>
              Resume <FontAwesomeIcon icon={faDownload} />
            </button>
            {tab === "activeTab" && (
              <div className="action-buttons">
                <button
                  className="select-btn"
                  onClick={() => OnStatusChange('SetupInterView', candidate)}
                >
                  <FontAwesomeIcon icon={faCheck} />
                </button>
                <button
                  className="reject-btn"
                  onClick={() => OnStatusChange('Reject', candidate)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
            )}
            {tab === "all" && (
              <div className="action-buttons">
                {candidate.appliedStatus === 'rejected' ? (
                  <p className="rejected-status">closed</p>
                ) : candidate.appliedStatus === 'interview' ? (
                  // <button onClick={scheduleInterview}>Schedule Interview</button>
                  <p className="schedule-status">Scheduled Interview</p>
                ) : (
                  <p>{candidate.appliedStatus}</p>
                )}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default UserProfileCard;
