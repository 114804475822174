import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import NonItJobCard from "../../helpers/Footerpage/NonItJobCard";
const NonIt = () => {
  return (
    <div>
      <Navbar />
      <div>
        <NonItJobCard/>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};
export default NonIt;
