import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Nav,
  Row,
  Tab,
  Image
} from "react-bootstrap";
import DatePicker from 'react-datepicker';
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import EmployerSidebar from "../../../components/EmployerSidebar";
import CommonTable from "../../../components/common/CommonTable";
import FilterSortOrder from "../../../helpers/Employers/Candidates/CandidatesFilters";
import { logoutToken } from "../../../helpers/utility";
import Nodata from "../../../assets/images/no-data.webp";

import Swal from 'sweetalert2';

import 'react-datepicker/dist/react-datepicker.css';
import { employerProfileUpdate, getJobApplicationDetailsByJobId, scheduleInterView, updateApplicationByEmployer, verify2FA } from "../../../helpers/backendHelper";
import EmployerNavBar from "../../../components/Employer/EmployerNavbar";
import UserProfileCard from "../../../components/jobCardDetails";
import "../../../styles/EmployerJobPostListedPageSidebar.css";

const Candidates = () => {

  const { user } = useSelector(state => ({
    user: state.Login.user,
  }));
  const params = useParams();
  const navigate = useNavigate()

  const [applicationDetails, setApplicationDetails] = useState();
  const [CandidateName, setCandidateName] = useState();
  const [candidateDetails, setCandidateDetails] = useState([]);
  const [candidate, setCandidate] = useState([]);
  const [applicationStatus, setApplicationStatus] = useState('all');

  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const [key, setKey] = useState('active');
  const [filter, setFilter] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [order, setOrder] = useState("Descending");
  const [showModal, setShowModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedCandidateId, setSelectedCandidateId] = useState("");

  const [interviewType, setInterviewType] = useState('online');
  const [location, setLocation] = useState('');
  const [interviewDateTime, setInterviewDateTime] = useState('');
  const [interviewDuration, setInterviewDuration] = useState('');
  const [startTime, setStartTime] = useState('');
  const [amOrPm, setAmOrPm] = useState('am');

  const jobId = params.id

  const fetchApplicationDetails = () => {
    const appicationInputData = {
      jobId: jobId,
      status: {
        status: applicationStatus
      }
    }
     getJobApplicationDetailsByJobId(appicationInputData)
      .then(data => {
        setApplicationDetails(data)
         if (data.statusCode === 200 && data.candidates) {
          const candidatedata = data.candidates.map(candidate => ({
            id: candidate.jobSeeker._id,
            name: candidate.jobSeeker.firstName,
            jobSeekerProfileImage: candidate.jobSeeker.jobSeekerProfileImage,
            email: candidate.jobSeeker.email,
            jobTitle: data.jobs.jobBasics.jobTitle,
            applicationId: candidate.applicationId,
            appliedStatus: candidate.appliedStatus,

          }));
          setCandidateDetails(candidatedata);
        }
      })
      .catch(error => {
        console.error("Error fetching jobs:", error);
      });
  };

  useEffect(() => {
    fetchApplicationDetails();
  }, [applicationStatus]);




  const activeTabColumn = ['Name', 'Email', 'JobTitle', 'Action'];
  const rejectTabColumn = ['Name', 'Email', 'JobTitle']
  const data = [
    { id: 1, name: 'John Doe', job: 'React' },
    { id: 2, name: 'Jane Smith', job: 'Node' },
    { id: 3, name: 'Bob Johnson', job: 'flutter' },
  ];

  const filterOptions = [
    { value: "1", label: "Option 1" },
    { value: "2", label: "Option 2" },
    { value: "3", label: "Option 3" }
  ];

  const sortByOptions = [
    { value: "1", label: "Option 1" },
    { value: "2", label: "Option 2" },
    { value: "3", label: "Option 3" }
  ];

  const closeSidebar = (event) => {
    if (!event.target.closest("#sidebar-wrapper") && sidebarExpanded) {
      setSidebarExpanded(false);
      const wrapper = document.getElementById("wrapper");
      wrapper.classList.remove("toggled");
    }
  };

  const handleProfileClick = async () => {
    navigate(`/employer/employerProfile`);
  }

  const OnStatusChange = async (status, row) => {
    setCandidate(row)
    if (status === 'SetupInterView') {
      setShowModal(true);
    } else {
      const confirmed = await Swal.fire({
        title: 'Are you sure?',
        text: 'Are you sure you want to reject this application?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, reject it!'
      });

      if (confirmed.isConfirmed) {
        try {
          const data = {
            inputData: {
              appliedStatus: 'close',
              status: 'close',
            },
            id: row.applicationId,
          };
          setApplicationStatus('close')
          const applicationUpdate = await updateApplicationByEmployer(data);
          if (applicationUpdate.statusCode === 200) {
            Swal.fire({
              title: 'Success',
              text: 'apllication close successfully!',
              icon: 'success',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'OK'
            })
            fetchApplicationDetails();
          }
        } catch (error) {
          console.error('Error closing job:', error);
        }
      }
    }
  }

  const handleScheduleInterview = (candidateDetails) => {
     const datas = {
      jobSeeker_id: candidate.id,
      interviewType: interviewType,
      location: location,
      interview_date_time: interviewDateTime,
      interview_duration: interviewDuration,
      startTime: startTime,
      amOrPm: amOrPm
    }

    scheduleInterView(datas).then(async (x) => {
      if (x && x.statusCode === 200) {
        Swal.fire('success!', 'interviewSchedule successfully', 'success');
      }
      else {
        Swal.fire('failed!', x.message, 'failed');
      }
      setInterviewType("");
      setLocation("");
      setInterviewDateTime("");
      setInterviewDuration("");
      setStartTime("");
      setAmOrPm("");
      setShowModal(false);

      const data = {
        inputData: {
          appliedStatus: 'interview',
          status: 'scheduled',
        },
        id: candidate.applicationId,
      };
      setApplicationStatus('scheduled')

       const applicationUpdate = await updateApplicationByEmployer(data);
      fetchApplicationDetails();
    }).catch((e) => {
      Swal.fire({
        icon: "error",
        title: "Schedule Error",
        text: "try again!",
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: "Close"
      })
    })
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedStatus("");
    setSelectedCandidateId("");
  };
  const handleDateChange = (date) => {
    setInterviewDateTime(date);
  };

  const handleNavClick = (status) => {
    setApplicationStatus(status)
  }

  const statusOption = ['SetupInterView', 'Reject']
  const scheduledInterviewOption = ['Hired', 'Reject']
  return (<>
    <div id="wrapper" onClick={closeSidebar}>
      <EmployerSidebar sidebarExpanded={sidebarExpanded} />
      <div id="navbar-wrapper">
        <EmployerNavBar />
      </div>

      <section id="content-wrapper">
       


        <Container>
          <Row className="justify-content-between">
            <Col sm={3} xs={6}>
              <h3>Candidates</h3>
            </Col>
            <Col sm={3} xs={6} className="text-end">
              <Link to="/empformone">
                <Button variant="primary">Post Jobs</Button>
              </Link>
            </Col>

          </Row>
          <Tab.Container id="left-tabs-example" defaultActiveKey="all">
            <Row className="mt-3">
              <Col sm={6}>
                <Nav variant="pills" className="flex-row">
                  <Nav.Item>
                    <Nav.Link eventKey="all" onClick={() => handleNavClick('all')}>
                      All
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link eventKey="active" onClick={() => handleNavClick('active')}>
                      Active
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="close" onClick={() => handleNavClick('close')}>
                      Close
                    </Nav.Link>
                  </Nav.Item>

                </Nav>
              </Col>
            </Row>

            <Row className=" mt-3 p-3 bg-white rounded">
              <Col sm={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="active">

                    {/* <CommonTable columns={activeTabColumn}
                      data={candidateDetails}
                      statusOption={statusOption}
                      OnStatusChange={OnStatusChange}
                      tab={'activeTab'}
                      type="candidates"
                    /> */}
                    {candidateDetails.length > 0 ?
                      <UserProfileCard data={candidateDetails}
                        statusOption={statusOption}
                        OnStatusChange={OnStatusChange}
                        tab={'activeTab'}
                        type="candidates"
                      /> : (<Image className="nodata-img" src={Nodata}/>)
                    }
                    <Modal show={showModal} onHide={handleCloseModal}>
                      <Modal.Header closeButton>
                        <Modal.Title>Schedule Interview</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form>
                          <Form.Group controlId="interviewType">
                            <Form.Label>Interview Type</Form.Label>
                            <Form.Control as="select" value={interviewType} onChange={(e) => setInterviewType(e.target.value)}>
                              <option value="online">Online</option>
                              <option value="offline">Offline</option>
                            </Form.Control>
                          </Form.Group>
                          {interviewType !== 'online' && (
                            <Form.Group controlId="location">
                              <Form.Label>Location</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Location"
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                              />
                            </Form.Group>
                          )}
                          <Form.Group controlId="interviewDateTime">
                            <Form.Label>Interview Date</Form.Label>
                            <div className="d-flex align-items-center">
                              <DatePicker
                                selected={interviewDateTime}
                                onChange={handleDateChange}
                                dateFormat="yyyy-MM-dd"
                                className="form-control"
                              />
                            </div>
                          </Form.Group>
                          <Form.Group controlId="interviewDuration">
                            <Form.Label>Interview Duration (in minutes)</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Enter Duration"
                              value={interviewDuration}
                              onChange={(e) => setInterviewDuration(e.target.value)}
                            />
                          </Form.Group>
                          <Form.Group controlId="startTime">
                            <Form.Label>Start Time</Form.Label>
                            <Form.Control
                              type="time"
                              value={startTime}
                              onChange={(e) => setStartTime(e.target.value)}
                            />
                          </Form.Group>


                          <Form.Group controlId="amOrPm">
                            <Form.Label>AM / PM</Form.Label>
                            <Form.Control
                              as="select"
                              value={amOrPm}
                              onChange={(e) => setAmOrPm(e.target.value)}
                            >
                              <option value="am">AM</option>
                              <option value="pm">PM</option>
                            </Form.Control>
                          </Form.Group>
                        </Form>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                          Close
                        </Button>
                        <Button variant="primary" onClick={handleScheduleInterview}>
                          Schedule Interview
                        </Button>
                      </Modal.Footer>
                    </Modal>

                  </Tab.Pane>

                  <Tab.Pane eventKey="close">
                    {/* <CommonTable columns={rejectTabColumn}
                      data={candidateDetails}
                      statusOption={statusOption}
                      OnStatusChange={OnStatusChange}
                      tab={'rejectTab'}
                      type="candidates"

                    /> */}
                    { candidateDetails.length > 0 ?
                      <UserProfileCard data={candidateDetails}
                        statusOption={statusOption}
                        OnStatusChange={OnStatusChange}
                        tab={'rejectTab'}
                        type="candidates"
                      /> : (<Image className="nodata-img" src={Nodata}/>)
                    }
                  </Tab.Pane>

                  <Tab.Pane eventKey="all">
                    <Row>
                      { candidateDetails.length > 0 ?
                        <UserProfileCard data={candidateDetails}
                          statusOption={statusOption}
                          OnStatusChange={OnStatusChange}
                          tab={'all'}
                          type="candidates"
                        /> : (<Image className="nodata-img" src={Nodata}/>)
                      }
                    </Row>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </section>
    </div>
  </>)
}
export default Candidates;