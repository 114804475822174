import React from "react";
import Navbar from "../components/Navbar";
import AboutContent from "../helpers/AboutContent";
import Footer from "../components/Footer";
const About = () => {
  return (
    <div>
      <Navbar />
      <div>
        <AboutContent />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default About;
