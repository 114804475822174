import React, { useState } from "react";
import { Nav, Navbar, Container, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faEnvelope,
  faMobile,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import HeaderNavbar from "../../components/Navbar";
import AccountSettings from "../settings/Accountsettings";
import Devicemanagement from "../settings/Devicemanagement";
import Emailsettings from "../settings/Emailsettings";
import Privacysettings from "../settings/Privacysettings";

function NavbarSettingsSidebar() {
  const [activeLink, setActiveLink] = useState(null);

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  const renderSection = () => {
    switch (activeLink) {
      case "account":
        return <AccountSettings />;
      case "email":
        return <Emailsettings />;
      case "device":
        return <Devicemanagement />;
      case "privacy":
        return <Privacysettings />;
      default:
        return <AccountSettings />;
    }
  };

  return (
    <div>
      <HeaderNavbar />
      <Container fluid>
        <Row>
          <Col
            xs={12}
            md={2}
            style={{
              backgroundColor: "#f8f9fa",
              minHeight: "100vh",
            }}
          >
            <Navbar>
              <Nav className="flex-column">
                <Nav.Link
                  style={{
                    backgroundColor:
                      activeLink === "account" ? "#ffffff" : "#f8f9fa",
                    color: activeLink === "account" ? "#495057" : undefined,
                  }}
                  onClick={() => handleLinkClick("account")}
                >
                  <FontAwesomeIcon icon={faUser} className="me-2" />
                  Account Settings
                </Nav.Link>
                <Nav.Link
                  style={{
                    backgroundColor:
                      activeLink === "email" ? "#ffffff" : "#f8f9fa",
                    color: activeLink === "email" ? "#495057" : undefined,
                  }}
                  onClick={() => handleLinkClick("email")}
                >
                  <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                  Email Settings
                </Nav.Link>
                <Nav.Link
                  style={{
                    backgroundColor:
                      activeLink === "device" ? "#ffffff" : "#f8f9fa",
                    color: activeLink === "device" ? "#495057" : undefined,
                  }}
                  onClick={() => handleLinkClick("device")}
                >
                  <FontAwesomeIcon icon={faMobile} className="me-2" />
                  Device Management
                </Nav.Link>
                <Nav.Link
                  style={{
                    backgroundColor:
                      activeLink === "privacy" ? "#ffffff" : "#f8f9fa",
                    color: activeLink === "privacy" ? "#495057" : undefined,
                  }}
                  onClick={() => handleLinkClick("privacy")}
                >
                  <FontAwesomeIcon icon={faLock} className="me-2" />
                  Privacy Settings
                </Nav.Link>
              </Nav>
            </Navbar>
          </Col>
          {/* Content */}
          <Col xs={12} md={10}>
            {/* <Navbar bg="light" expand="lg" className="d-md-none">
              <Navbar.Toggle onClick={toggleSidebar} aria-controls="sidebar" />
            </Navbar> */}
            <Container className="justify-content-center">
              {renderSection()}
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default NavbarSettingsSidebar;
