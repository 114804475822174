
import { date } from "yup";
import { APIClient } from "./apiHelper";
import * as url from "./urlHelper";
import { data } from "jquery";


const api = new APIClient();


export const getLoggedInUser = () => {

  const user = localStorage.getItem("user");
  console.log('user getLoggedInUser',user)
  // const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

export const existingUserOrNot = data => api.create(url.EXISTINGUSER_CHECK, data);
export const createJobSeeker = data => api.create(url.JOBSEEKER_REG, data);
export const createEmployer = data => api.create(url.EMPLOYER_REG, data);
export const getJobSeeker = data => api.get(url.GET_JOB_SEEKERS);
export const jobSeekerLoginApi = data => api.create(url.JOBSEEKER_LOGIN_WITH_PASSWORD, data);
export const viewJobsByJobSeeker = data => api.create(url.VIEW_JOB_BY_JOBSEEKER,data)

export const Forget_Passwords=data=>api.create(url.FORGOT_PASSWORD,data);
export const ResetPassword=data=>api.create(url.RESET_PASSWORD,data);

export const jobSeekerVerifyOtp = data => api.create(url.JOBSEEKER_LOGIN_OTP, data);
export const jobSeekerResumeUpdate = data => api.create(url.JOBSEEKER_RESUME_UPDATE, data);
export const getAllJobs = data => api.get(url.GET_ALL_JOBS);
 
 export const searchJobs = data => api.get(url.SEARCH_JOBS, data);
export const savedJobs = data => api.create(url.SAVED_JOBS,data);
export const deleteSavedJobs = data => api.delete(url.SAVED_JOBS,data);
export const searchAndFilter = data => api.create(url.SEARCH_FILTER,data);

export const getJobSeekerResumeDetails = data => api.get(url.GET_JOBSEEKER_RESUME_DETAILS)
export const getJobsByJobId = data => {
  const urlWithParams = `${url.GET_JOB_BY_ID}/${data}`;
  return api.get(urlWithParams);
};

export const jobSeekerSendOtp = data => api.create(url.JOBSEEKER_SEND_OTP, data)
export const jobSeekerProfileUpdate = data => api.create(url.JOBSEEKER_PROFILE_UPDATE, data)

export const getCityState = data => api.get(url.GET_CITY_STATE)

export const getJobSuggestions = data => api.get(url.GET_JOB_SUGGESTIONS)
//empolyer  

    export const employerSendOtp = data => api.create(url.EMPLOYER_SEND_OTP, data)
    export const employerVerifyOtp = data => api.create(url.EMPLOYER_VERIFY_OTP, data)
    export const employerProfileUpdate = data => api.create(url.EMPLOYER_UPDATE_PROFILE, data)
    export const employerLoginWithPassword = data => api.create(url.EMPLOYER_LOGIN_WITH_PASSWORD,data)

    //job
    export const employerPostJob = data => api.create(url.POSTJOB_FOR_EMPLOYER, data)

    //jobUpdatebyemployer
    export const updateJobByEmployer = data => api.create(url.UPDATE_JOBS_BY_EMPLOYER + `/${data.id}`,data.inputData)

    //jobapplicationupdateby employer
    export const updateApplicationByEmployer = data =>api.create(url.UPDATE_APPLICATION_BY_EMPLOYER+`/${data.id}`,data.inputData);
    
    export const updateApplicationByStatusByEmployer = data =>api.create(url.UPDATE_APPLICATION_STATUS_BY_EMPLOYER+`/${data.id}`,data.payload);

    
    
    //getJobsbyEmployer
    export const getJobsByEmployer = data => api.get(url.GET_JOBS_BY_EMPLOYER,data)
    export const getEmployer = data => api.get(url.GET_EMPLOYER)

    //2fa
    export const setup2FA = data => api.update(url.TWO_FA_SETUP, data)
    export const verify2FA = data => api.update(url.VERIFY_TWO_FA, data)

    //jobApplication
    export const getJobApplicationDetailsByJobId = data => api.get(`${url.GET_JOB_AAPLICATION_BY_JOBID}/${data.jobId}`,data.status);
    export const getJobApplicationByEmployerId = data => api.get(`${url.GET_JOB_APPLICATION_BY_EMPLOYERID}`);
 
    //interview 
    export const scheduleInterView = data => api.create(url.INTERVIEW_SCHEDULE_BY_EMPLOYER, data);
    export const getInterviewScheduleByStatus = data => api.get(`${url.GET_INTERVIEW_BY_STATUS}?status=${data.status}`);
    export const updateInterviewStatus = data => api.create(url.UPDATE_INTERVIEW_STATUS,data.inputData)
//formdata                                                        `${url.JOBSEEKER_LOGIN_OTP}?otp=${data.otp}`;
export const applyJobsByJobSeeker = formData => api.sendFormDataPost(url.APPLY_JOBS_BY_EMPLOYER, formData)

export const employerImageUpdate= formData =>api.sendFormDataPost(url.EMPLOYER_UPDATE_PROFILE,formData)

export const jobSeekerImageUpdate = formData => api.sendFormDataPost(url.JOBSEEKER_PROFILE_UPDATE,formData)