import "../../assets/css/Studygirl.css";
import React from "react";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import WomenImage from "../../assets/images/women.webp";
import CertifiedImage from "../../assets/images/certified.webp";
import WinnerImage from "../../assets/images/winner.webp";
import CourseImage from "../../assets/images/course.webp";
import LearnerImage from "../../assets/images/learner.webp";
import TrestImage from "../../assets/images/Trest.webp";
import TelecallImage from "../../assets/images/telecall.webp";

export default function Studygirl(){
    return(<div className="main-contents">
        <div className="contents">
            <Image className="girl" src={WomenImage} />

            <div className="all-details">
                <div className="details">
                    
                    <div className="indetail">
                        <Link className="btn w-100 link-indetail">
                            <Image className="icons" src={CertifiedImage} />
                            <h2 className="num">2</h2>
                            <p className="words">Certified students</p>
                        </Link>
                    </div>
                
                
                    <div className="indetail">
                        <Link className="btn w-100 link-indetail">
                            <Image className="icons" src={WinnerImage} />
                            <h2 className="num">17</h2>
                            <p className="words">Winning Award</p>
                        </Link>
                    </div>
                    
                
                    <div className="indetail">
                        <Link className="btn w-100 link-indetail">
                            <Image className="icons" src={CourseImage} />
                            <h2 className="num">159</h2>
                            <p className="words">Courses & Video</p>
                        </Link>   
                    </div>
                
                    <div className="indetail">
                        <Link className="btn w-100 link-indetail">
                            <Image className="icons" src={LearnerImage} />
                            <h2 className="num">236</h2>
                            <p className="words">Learners & Counting</p>
                        </Link>   
                    </div>
                    
                </div>

                <div className="wordpress">
                    <h5 className="word1">Why Choose Us</h5>
                    <h2 className="word2">The Amazing Gutenberg Optimised</h2>
                    <h2 className="word3">WordPress LMS</h2>
                    <p className="word4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
                    
                    <div className="cont">
                        <Image className="ico" src={TrestImage} />
                        <div className="icontr">
                            <p className="icotext">Trusted By Thousands</p>
                            <p className="icotext2">Online learning has become increasingly popular in recent years, offering a flexible and convenient way for learning.</p>
                        </div>
                    </div>

                    <div className="cont">
                        <Image className="ico" src={TelecallImage} />
                        <div className="icontr">
                            <p className="icotext">Unlimited Resources With Strong Support</p>
                            <p className="icotext2">Our platform also features a collaborative learning environment, where you can connect with fellow learners.</p>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    
    )
}